import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LandingPageData from '../../components/Main/LandingPage/data/LandingPageData';
import { getUserVisibilities, getActiveInfoMessages, getNaturalPersonInformationValidation, getEstoxAdminLink, getInisLink, getCompanyWebLink } from '../../components/Main/LandingPage/http/HttpClientHelper';
import { useOutletContext } from "react-router-dom";
import GeneralAssemblyRegistration from '../../components/Main/LandingPage/GeneralAssembly/GeneralAssemblyRegistration.tsx';
import Mandate from '../../components/Main/LandingPage/Mandate/Mandate';
import Estox from '../../components/Main/LandingPage/Estox/Estox';
import LandingPageModal from '../../components/Main/LandingPage/LandingPageModal/LandingPageModal.tsx';
import { useTranslation } from 'react-i18next';
import { getExcelForAccountancyUrl } from '../../components/Main/LandingPage/Mandate/http/HttpClientHelper';
import { getExamUrl } from '../../components/Main/LandingPage/Estox/http/HttpClientHelper';
import showErrorToast from '../../components/Toast/Toast';
import LoaderSpinnerOverlay from '../../components/loader/LoaderSpinnerOverlay';
import GeneralAssemblyDocumentation from '../../components/Main/LandingPage/GeneralAssembly/GeneralAssemblyDocumentation.tsx';
import { useNavigate } from "react-router-dom"; 
const LandingPage = () => {
    const [userVisibilities, setUserVisibilities] = useState(null);
    const [infoMessages, setActiveInfoMessages] = useState(null);
    const { userDetail } = useOutletContext();
    const [showGeneralAssemblyRegistration, setShowGeneralAssemblyRegistration] = useState(false);
    const [showGeneralAssemblyDocumentation, setShowGeneralAssemblyDocumentation] = useState(false);
    const [showNaturalPersonConfirmationModal, setShowNaturalPersonConfirmationModal] = useState(false);
    const [showMandateModal, setShowMandateModal] = useState(false);
    const [showEstoxModal, setShowEstoxModal] = useState(false);
    const [naturalPersonInformationValidationDataState, setNaturalPersonInformationValidationDataState] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase();
    const navigate = useNavigate();
       useEffect(() => {
        if (userDetail?.id && userVisibilities) {
            const fetchValidationData = async () => {
                if (userVisibilities.isVisibleEloket && !userDetail.isImpersonated) {
                    await getNaturalPersonInformationValidation(userDetail.id).then(information => {
                        setNaturalPersonInformationValidationDataState(information);
                        if (information.shouldConfirm || information.validateNationalNumber) {
                            setShowNaturalPersonConfirmationModal(true);
                        }
                    });
                }
            };
            fetchValidationData();
        }
    }, [userDetail, userVisibilities]);

    useEffect(() => {
        const fetchData = async () => {
            localStorage.removeItem('navbarData');
            if (userDetail?.id) {
                setIsLoading(true);
                try {
                    const [visibilitiesData, messagesData] = await Promise.all([
                        getUserVisibilities(userDetail.id),
                        getActiveInfoMessages()
                    ]);
                    setUserVisibilities(visibilitiesData);
                    setActiveInfoMessages(messagesData);
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchData();
    }, [userDetail?.id]);
    

    const fetchEstoxAdminLink = async () => {
        try {
            const link = await getEstoxAdminLink(userDetail.id);
            return link;
        } catch (error) {
            console.error(`Error fetching estox admin link:`, error);
            return '';
        }
    }
    const fetchExcelForAccountancyLink = async () => {
        var hash = await getExcelForAccountancyUrl();
        return hash;
    }
    const fetchInisHash = async (naturalPersonId) => {
        try {
            const link = await getInisLink(naturalPersonId);
            return link.hash;
        } catch (error) {
            console.error(`Error fetching education link`, error);
            return '';
        }
    }

    const fetchCompanyWebLink = async (naturalPersonId) => {
        try {
            const link = await getCompanyWebLink(naturalPersonId);
            return link.data;
        } catch (error) {
            console.error(`Error fetching companyweb link`, error);
            return '';
        }
    }

    const handleCardClick = async (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        let link = item.link;
        let postData = '';
        let showRegistrationIdError = false;
        switch (item.key) {
            case "isVisibleEstox":
                setShowEstoxModal(true);
                break;
            case "isVisibleEstoxAdmin":
                link = await fetchEstoxAdminLink();
                break;
            case "isVisibleGeneralAssemblyRegistration":
                setShowGeneralAssemblyRegistration(true);
                break;
            case "isVisibleGeneralAssemblyDocumentation":
                setShowGeneralAssemblyDocumentation(true)
                break;
            case "isVisibleMandateManagement":
                setShowMandateModal(true);
                break;
            case "isVisibleExcelForAccountancy":
                const hashExcel = await fetchExcelForAccountancyLink(); // Ensure this returns a promise that resolves to the token
                link = process.env.REACT_APP_URL_EXCELFORACCOUNTANCY
                    .replace('{0}', hashExcel)
                    .replace('{1}', i18n.language);
            case "isVisibleInisSystemEducation":
            case "isVisibleInisSystemFacturation":
                const hash = await fetchInisHash(userDetail.id);
                if (item.key === "isVisibleInisSystemEducation") {
                    link = process.env.REACT_APP_URL_INIS.replace('{0}', hash);
                }
                else if (item.key === "isVisibleInisSystemFacturation") {
                    link = process.env.REACT_APP_URL_INIS_SHOWCLIENT.replace('{0}', hash);
                }
                break;
            case "isVisibleCompanyWeb":
                postData = await fetchCompanyWebLink(userDetail.id);
                break;
            case "isVisibleEntranceExam":
            case "isVisibleIntermediateExam":
            case "isVisibleIntermediateExam2":
            case "isVisibleTransferExam":
            case "isVisibleBackpackExam":
            case "isVisibleFinalExam":
            case "isVisibleCertifiedExam":
                var registrationId = await getExamUrl(userDetail.id, item.examType);
                if(!registrationId){
                    showRegistrationIdError = true;
                    showErrorToast(t('errors.examRegistrationNotAvailable'));
                }
                link = `${process.env.REACT_APP_URL_EXAM}${registrationId}`
                break;
            default:
                break;
        }


        if (item.key === "isVisibleCompanyWeb") {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = item.link;
            form.target = '_blank';

            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = 'parm';
            hiddenField.value = postData;

            form.appendChild(hiddenField);
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            if (item.openInNewTab) {
                if(!showRegistrationIdError){
                    window.open(link, "_blank");
                }
            } else if(!item.openInModal) {
                navigate(link);
            }
        }
    };

    return (
   
        <div className="container " id="main">
            {isLoading && <LoaderSpinnerOverlay />}

            {userDetail?.id && (
                <>
                    {infoMessages?.map(item => (
                        <Row className='row-m-1' key={item.id}>
                            <Col md={12}>
                                <div className='alert alert-danger mb-0'>
                                    <strong>{item[`title_${currentLanguage}`]}</strong><br />
                                    <span>{item[`content_${currentLanguage}`]}</span>
                                </div>
                            </Col>
                        </Row>
                    ))}

                    
                    <LandingPageData
                        loading ={isLoading}
                        userVisibilities={userVisibilities}  
                        handleCardClick={handleCardClick} 
                    />
                    
                    <Row className='row-m-1'>
                        <div className='link-group-Landing-page'>
                            <a className='link-tag' target='_blank' href={t('sidebar.urls.home')} rel="noreferrer">ITAA Website</a>
                            <a className='link-tag' target='_blank' href={t('sidebar.urls.privacy')} rel="noreferrer"> | Privacy</a>
                            <a className='link-tag' target='_blank' href={t('sidebar.urls.cookies')} rel="noreferrer"> | Cookies</a>
                        </div>
                    </Row>

                    {showGeneralAssemblyRegistration &&(
                        <GeneralAssemblyRegistration
                            show={showGeneralAssemblyRegistration}
                            handleClose={() => setShowGeneralAssemblyRegistration(false)}
                            userDetail={userDetail}
                            setShowRegistration={setShowGeneralAssemblyRegistration}
                        />
                    )}
                    {showGeneralAssemblyDocumentation &&(
                        <GeneralAssemblyDocumentation
                            show={showGeneralAssemblyDocumentation}
                            handleClose={() => setShowGeneralAssemblyDocumentation(false)}
                        />
                    )}

                    {showMandateModal && (
                        <Mandate show={showMandateModal} userDetail={userDetail} handleClose={() => setShowMandateModal(false)} />
                    )}

                    {showEstoxModal && (
                        <Estox show={showEstoxModal} userDetail={userDetail} handleClose={() => setShowEstoxModal(false)} />
                    )}

                    <div>
                        <LandingPageModal
                            showModal={showNaturalPersonConfirmationModal}
                            handleClose={() => setShowNaturalPersonConfirmationModal(false)}
                            validationDataToModal={naturalPersonInformationValidationDataState}
                            canIgnore={naturalPersonInformationValidationDataState.isFirstValidation || naturalPersonInformationValidationDataState.validateNationalNumber}
                            userDetail={userDetail}
                            setShowModal={setShowNaturalPersonConfirmationModal}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default LandingPage;