import apiClient from "../../../../../api/client/apiClient";

export const getCompanyDetail = async (legalPersonId) => {
    const response = await apiClient.get(
        `/api/companies/${legalPersonId}/itaadetails`
    );
    return response.data;
};

export const getOffice = async (legalPersonId) => {
    const response = await apiClient
        .get(`/api/offices/${legalPersonId}/detail`);
    return response.data;
};