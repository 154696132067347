import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserMissions } from './http/HttpClientHelper';
import logo from '../../../assets/itaa-logo.png';
import { useTranslation } from 'react-i18next';
import showErrorToast from '../../Toast/Toast';
import CertificateDownloadModal from '../CertificateDownloadModal';
import { formatCompanyName } from '../../../utils/formatUtils.tsx';
import { EntityType } from '../../../Enums/EntityType.tsx';
import { CompanyType } from '../../../Enums/CompanyType';
import { OfficialType } from '../../../Enums/OfficialType.tsx';
import { getNaturalPersonInformationValidation, patchNaturalPersonInformationValidation, resetPostponementsNaturalPersonInformationValidation } from '../../Main/LandingPage/http/HttpClientHelper';
import apiClient from '../../../api/client/apiClient';
import { CurrentUserModel } from '../../../models/CurrentUserModel.tsx';


const Sidebar = ({ isSidebarOpen, setSidebarOpen, isMobile, userDetail }) => {
    const currentUserModel = Object.assign(new CurrentUserModel(), userDetail)

    const useDebounce = (func, delay) => {
        const timeoutRef = useRef(null);

        const debouncedFunction = (...args) => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };

        return debouncedFunction;
    };

    const addMissionToNavbar = () => {
        const hasMissionItem = navbarData.some(item => item.text === t('sidebar.missions'));

        if (!hasMissionItem) {
            const newMissionItem = {
                text: t("sidebar.missions"),
                id: "Missions",
                icon: "fa fa-briefcase",
                link: "/eloket/missions",
                open: false,
                active: false,
                click: (item) => handleItemClick(item),
                render: (item) => renderMainMenuItem(item),
            };
            setNavbarData(prevData => [...prevData, newMissionItem]);
        }
    }

    const { t, i18n } = useTranslation();
    const [userMissions, setUserMissions] = useState([]);
    const [showTrackModal, setShowTrackModal] = useState(false);
    const [naturalPersonInformationData, setNaturalPersonInformationData] = useState({})
    const location = useLocation();
    const debouncedAddMissionToNavbar = useDebounce(addMissionToNavbar, 300);

    const userLanguage = i18n.language;

    const initialNavbarData = [
        {
            text: t("sidebar.profile"),
            id: "main1",
            icon: "fa fa-user",
            link: '/eloket/myprofile',
            open: false,
            active: false,
            render: (item) => renderMainMenuItem(item),
            submenu: [
                {
                    text: t("sidebar.data"),
                    id: "sub1-2",
                    icon: "fa fa-user",
                    link: `/eloket/myprofile/basicdata/${userDetail.Id}`,
                    open: false,
                    active: false,
                    render: (item, index) => renderSubMenuItem(item, index),
                    submenu: [
                        {
                            text: t("sidebar.contact"),
                            id: "sub1-2-1",
                            icon: null,
                            link: "/eloket/data",
                            active: false,
                            render: (item) => renderLink(item),
                            triggersMobileClose: true
                        },
                        {
                            text: t("sidebar.personalData"),
                            id: "sub1-2-2",
                            icon: null,
                            link: "/eloket/personalData",
                            active: false,
                            render: (item) => renderLink(item),
                            triggersMobileClose: true
                        },
                        ...((currentUserModel.hasExternalQualification() ||currentUserModel.isExternalType()) ? [
                            {
                                text: t("sidebar.insurance"),
                                id: "sub1-2-3",
                                icon: null,
                                link: "/eloket/insurance",
                                active: false,
                                render: (item) => renderLink(item),
                                triggersMobileClose: true
                            }
                        ]: [])
                    ],
                },
                {
                    text: t("sidebar.itaaData"),
                    id: "sub1-1",
                    icon: "fa fa-user",
                    link: `/eloket/myprofile/itaadata/${userDetail.Id}`,
                    open: false,
                    active: false,
                    render: (item, index) => renderSubMenuItem(item, index),
                    submenu: [
                        {
                            text: t("sidebar.general"),
                            id: "sub1-1-1",
                            icon: null,
                            link: "/eloket/itaadata/general",
                            active: false,
                            render: (item) => renderLink(item),
                            triggersMobileClose: true
                        },
                        ...(currentUserModel.hasNoActiveTracks() ? [] : [
                            {
                                text: t("sidebar.myPublicProfile"),
                            id: "sub1-1-2",
                            icon: null,
                            link: `${process.env.REACT_APP_URL_SEARCH}${i18n.language}-${i18n.language}/ExternalLink/Redirect?Number=${userDetail?.itaaNumber}&ExternalLinkType=0`,
                            openLinkInNewTab: true,
                            active: false,
                            render: (item) => renderLink(item)
                            },
                            ...(currentUserModel.hasOfficialType(OfficialType.ExternalMember, OfficialType.InternalMember) ? [
                                {
                                    text: t("sidebar.myCertificates"),
                                    isCertificateLink: true,
                                    id: "sub1-1-3",
                                    icon: null,
                                    link: null,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                }
                            ] : []),
                            {
                                text: t("sidebar.myOfficeRoles"),
                                id: "sub1-1-4",
                                icon: null,
                                link: "/eloket/officeroles",
                                active: false,
                                render: (item) => renderLink(item),
                                triggersMobileClose: true
                            },
                            {
                                text: t("sidebar.myGroups"),
                                id: "sub1-1-5",
                                icon: null,
                                link: "/eloket/itaadata/groups",
                                active: false,
                                render: (item) => renderLink(item),
                                triggersMobileClose: true
                            },
                            {
                                text: t("sidebar.myNetwork"),
                                id: "sub1-1-6",
                                icon: null,
                                link: "/eloket/networks",
                                active: false,
                                render: (item) => renderLink(item),
                                triggersMobileClose: true
                            }
                        ])
                    ],
                },
            ],
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const naturalPersonInformation = await getNaturalPersonInformationValidation(userDetail.id);
            setNaturalPersonInformationData(naturalPersonInformation);

            const localStorageCompanies = localStorage.getItem('USER_COMPANIES')
            const userCompanies = JSON.parse(localStorageCompanies)?.sort((a, b) => a.companyName.localeCompare(b.companyName));

            const localStorageOffices = localStorage.getItem('USER_OFFICES');
            const userOffices = JSON.parse(localStorageOffices)?.sort((a, b) => a.companyName.localeCompare(b.companyName));

            const missions = await getUserMissions(userDetail.id);
            setUserMissions(missions);

            const newCompaniesItem = [
                {
                    text: t("sidebar.company"),
                    id: "Companies",
                    icon: "fa fa-building",
                    link: "/eloket/companies",
                    open: false,
                    active: false,
                    render: (item) => renderMainMenuItem(item),
                    submenu: userCompanies?.map((company, index) => ({
                        text: formatCompanyName(company.companyName),
                        id: `company-${index}`,
                        icon: "fa fa-building",
                        link: `/eloket/company/basicdata/general/${company.legalPersonId}`,
                        open: false,
                        active: false,
                        render: (item) => renderSubMenuItem(item, index),
                        submenu: [
                            {
                                text: t("sidebar.basicdata"),
                                id: `companies-basic-${index}`,
                                icon: null,
                                link: `/eloket/company/basicdata/general/${company.legalPersonId}`,
                                active: false,
                                click: (item) => handleItemClick(item),
                                render: (item) => renderSubMenuItem(item, index),
                                submenu: [
                                    {
                                        text: t("sidebar.contact"),
                                        id: `companies-basic-${index}-1`,
                                        icon: null,
                                        link: `/eloket/company/basicdata/contact/${company.legalPersonId}`,
                                        active: false,
                                        click: (item) => handleItemClick(item),
                                        render: (item) => renderLink(item),
                                        triggersMobileClose: true
                                    },
                                    company.entityType?.id !== EntityType.EPP ? {
                                        text: t("sidebar.insurance"),
                                        id: `companies-basic-${index}-3`,
                                        icon: null,
                                        link: `/eloket/company/basicdata/insurance/${company.legalPersonId}`,
                                        active: false,
                                        click: (item) => handleItemClick(item),
                                        render: (item) => renderLink(item),
                                        triggersMobileClose: true
                                    } : null
                                ].filter(item => item !== null)
                            },
                            {
                                text: t("sidebar.itaaData"),
                                id: `companies-itaadata-${index}`,
                                icon: null,
                                link: `/eloket/company/itaadata/details/${company.legalPersonId}`,
                                active: false,
                                click: (item) => handleItemClick(item),
                                render: (item) => renderSubMenuItem(item, index),
                                submenu: [
                                    {
                                        text: t("sidebar.general"),
                                        id: `companies-itaadata-${index}-1`,
                                        icon: null,
                                        link: `/eloket/company/itaadata/general/${company.legalPersonId}`,
                                        active: false,
                                        click: (item) => handleItemClick(item),
                                        render: (item) => renderLink(item),
                                        triggersMobileClose: true
                                    },
                                    ...(company.isManagementCompany
                                        ? [
                                            {
                                                text: t("sidebar.mainContact"),
                                                id: `companies-itaadata-${index}-2`,
                                                icon: null,
                                                link: `/eloket/company/itaadata/maincontact/${company.legalPersonId}`,
                                                active: false,
                                                click: (item) => handleItemClick(item),
                                                render: (item) => renderLink(item),
                                                triggersMobileClose: true
                                            },
                                            {
                                                text: t("sidebar.coadministrator"),
                                                id: `companies-itaadata-${index}-3`,
                                                icon: null,
                                                link: `/eloket/company/itaadata/coadministrator/${company.legalPersonId}`,
                                                active: false,
                                                click: (item) => handleItemClick(item),
                                                render: (item) => renderLink(item),
                                                triggersMobileClose: true
                                            },
                                        ]
                                        : []),
                                    {
                                        text: t("sidebar.publicprofile"),
                                        id: `companies-itaadata-${index}-4`,
                                        icon: null,
                                        link: company.entityType?.id === EntityType.EPP ? `${process.env.REACT_APP_URL_SEARCH}${i18n.language}-${i18n.language}/ExternalLink/Redirect?Number=${userDetail?.itaaNumber}&ExternalLinkType=0`
                                            : `${process.env.REACT_APP_URL_SEARCH}${i18n.language}-${i18n.language}/ExternalLink/Redirect?Number=${company?.itaaNumber}&ExternalLinkType=1`,
                                        openLinkInNewTab: true,
                                        active: false,
                                        click: (item) => handleItemClick(item),
                                        render: (item) => renderLink(item)
                                    },
                                    {
                                        text: t("sidebar.directorAndPartner"),
                                        id: `companies-itaadata-${index}-5`,
                                        icon: null,
                                        link: `/eloket/company/itaadata/directorandpartner/${company.legalPersonId}`,
                                        active: false,
                                        click: (item) => handleItemClick(item),
                                        render: (item) => renderLink(item),
                                        triggersMobileClose: true
                                    },
                                    {
                                        text: t("sidebar.network"),
                                        id: `companies-itaadata-${index}-6`,
                                        icon: null,
                                        link: `/eloket/company/itaadata/network/${company.legalPersonId}`,
                                        active: false,
                                        click: (item) => handleItemClick(item),
                                        render: (item) => renderLink(item),
                                        triggersMobileClose: true
                                    },
                                ],
                            },
                        ],
                    })),
                },
            ];

            const newOfficesItem = [
                {
                    text: t("sidebar.office"),
                    id: "Offices",
                    icon: "fa fa-briefcase",
                    link: "/eloket/offices",
                    open: false,
                    active: false,
                    render: (item) => renderMainMenuItem(item),
                    submenu: userOffices.filter(x=> x.companyType?.id !== CompanyType.ManagementCompany).map((office, index) => {
                        return {
                            text: formatCompanyName(office.companyName),
                            id: `offices-${index}-${office.companyName}`,
                            icon: "fa fa-briefcase",
                            link: `/eloket/office/${office.legalPersonId}`,
                            open: false,
                            active: false,
                            render: (item, index) => renderSubMenuItem(item, index),
                            submenu: [
                                {
                                    text: t("sidebar.mainContact"),
                                    id: `offices-${index}-1`,
                                    icon: null,
                                    link: `/eloket/office/maincontact/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                                {
                                    text: t("sidebar.coadministrator"),
                                    id: `offices-${index}-2`,
                                    icon: null,
                                    link: `/eloket/office/coadministrator/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                                {
                                    text: t("sidebar.officeComposition"),
                                    id: `offices-${index}-3`,
                                    icon: null,
                                    link: `/eloket/office/officecomposition/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                                {
                                    text: t("sidebar.antiMoneyLaunderingResponsibles"),
                                    id: `offices-${index}-4`,
                                    icon: null,
                                    link: `/eloket/office/antimoneylaunderingofficers/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                                {
                                    text: t("sidebar.qualityResponsible"),
                                    id: `offices-${index}-5`,
                                    icon: null,
                                    link: `/eloket/office/qualityresponsible/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                                {
                                    text: t("sidebar.accessTools"),
                                    id: `offices-${index}-6`,
                                    icon: null,
                                    link: `/eloket/office/accesstools/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                                {
                                    text: t("sidebar.beExcellent"),
                                    id: `offices-${index}-7`,
                                    icon: null,
                                    link: `/eloket/office/beexcellent/${office.legalPersonId}`,
                                    active: false,
                                    render: (item) => renderLink(item),
                                    triggersMobileClose: true
                                },
                            ],
                        };
                    })
                },
            ];

            if (userCompanies.length !== 0 || userOffices.length !== 0) {
                const combinedNavbarData = [
                    ...navbarData.filter(item => item.id !== 'Companies' && item.id !== 'Offices'),
                    ...newCompaniesItem,
                    ...newOfficesItem
                ];

                const mergedNavbarData = mergeNavbarData(combinedNavbarData, savedNavbarData);
                setNavbarData(mergedNavbarData);

                //Open & Select based on path
                const currentPath = location.pathname;
                const item = findItemByLink(mergedNavbarData, currentPath);
                if (item) {
                    openAndActivateItem(item);
                }
            } else {
                const mergedNavbarData = mergeNavbarData(navbarData.filter(item => item.id !== 'Companies' && item.id !== 'Offices'), savedNavbarData);
                setNavbarData(mergedNavbarData);

                //Open & Select based on path
                const currentPath = location.pathname;
                const item = findItemByLink(mergedNavbarData, currentPath);
                if (item) {
                    openAndActivateItem(item);
                }
            }
        };

        fetchData();
    }, [userDetail]);

    const openAndActivateItem = (clickedItem) => {
        setNavbarData((prevNavbarData) => {
            const resetData = resetAllItems(prevNavbarData);
            const updatedData = updateItemAndParents(resetData, clickedItem);
            return updatedData;
        });
    };

    const handleNaturalPersonConfirmClick = async () => {
        await patchNaturalPersonInformationValidation(userDetail.id, {
            hasIgnored: false,
            hasConfirmed: true,
        });

        var response = await apiClient.get(`/api/Profiles/${userDetail.id}/itaadetails`);
        let userDetails = await response.data;
        const existingUserDetails = JSON.parse(localStorage.getItem('CURRENT_USER'));
        userDetails.isImpersonated = true;
        userDetails.isItaaEmployee = true;
        userDetails.itaaEmployeeId = existingUserDetails.itaaEmployeeId;
        userDetails.isItaaIT = existingUserDetails.isItaaIT;
        userDetails.isItaaSRD = existingUserDetails.isItaaSRD;

        localStorage.setItem("CURRENT_USER", JSON.stringify(userDetails));
        window.location.reload();
    }

    const handleResetPostponementsClick = async () => {
        await resetPostponementsNaturalPersonInformationValidation(userDetail.id);
        window.location.reload();
    }

    const resetAllItems = (items) => {
        if (!Array.isArray(items)) {
            return items;
        }

        return items.map(item => {
            let updatedItem = { ...item, active: false, open: false };
            if (item.submenu && Array.isArray(item.submenu)) {
                updatedItem = {
                    ...updatedItem,
                    submenu: resetAllItems(item.submenu)
                };
            }
            return updatedItem;
        });
    };

    const updateItemAndParents = (items, clickedItem) => {
        if (!Array.isArray(items)) {
            return items;
        }

        const findParentsAndUpdate = (items, targetItem) => {
            return items.map(item => {
                let updatedItem = { ...item, active: false, open: false };

                if (item.id === targetItem.id) {
                    updatedItem = { ...item, active: true, open: true };
                } else if (item.submenu && Array.isArray(item.submenu)) {
                    const updatedSubmenu = findParentsAndUpdate(item.submenu, targetItem);
                    const isTargetInSubmenu = updatedSubmenu.some(subItem => subItem.active || subItem.open);

                    if (isTargetInSubmenu) {
                        updatedItem = { ...item, open: true, active: true, submenu: updatedSubmenu };
                    } else {
                        updatedItem = { ...item, submenu: updatedSubmenu };
                    }
                }

                return updatedItem;
            });
        };

        return findParentsAndUpdate(items, clickedItem);
    };

    const [navbarData, setNavbarData] = useState(initialNavbarData);
    const savedNavbarData = JSON.parse(localStorage.getItem('navbarData')) || [];

    const getFilteredNavbarData = (data) => {
        return data.map(item => ({
            id: item.id,
            open: item.open,
            active: item.active,
            submenu: item.submenu ? getFilteredNavbarData(item.submenu) : []
        }));
    };

    const mergeNavbarData = (originalData, savedData) => {
        return originalData.map(item => {
            const savedItem = savedData.find(si => si.id === item.id) || {};
            return {
                ...item,
                open: savedItem.open || item.open,
                active: savedItem.active || item.active,
                link: item.link || savedItem.link,
                submenu: item.submenu ? mergeNavbarData(item.submenu, savedItem.submenu || []) : []
            };
        });
    };

    useEffect(() => {
        if (savedNavbarData.length === 0) {
            localStorage.setItem('navbarData', JSON.stringify(getFilteredNavbarData(initialNavbarData)));
        } else {
            const mergedData = mergeNavbarData(initialNavbarData, savedNavbarData);
            setNavbarData(mergedData);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('navbarData', JSON.stringify(getFilteredNavbarData(navbarData)));
    }, [navbarData]);

    const findItemByLink = (items, link) => {
        for (let item of items) {
            if (item.submenu) {
                const result = findItemByLink(item.submenu, link);
                if (result) {
                    return result;
                }
            }
            if (item.link && item.link.toLowerCase() === link.toLowerCase()) {
                return item;
            }
        }
        return null;
    };

    const handleItemClick = (clickedItem) => {
        // Handle certificate link click
        if (clickedItem.isCertificateLink) {
            handleCertificateClick();
            return;
        }

        // Handle navigation link click
        if (clickedItem.link) {
            if (clickedItem.openLinkInNewTab) {
                window.open(clickedItem.link, '_blank');
            } else {
                window.location.href = clickedItem.link;
            }
        }

        // Close the sidebar on mobile if needed
        if (isMobile && clickedItem.triggersMobileClose) {
            setSidebarOpen(false);
        }
    };

    const renderMainMenuItem = (item) => {
        const hasSubmenu = item.submenu && item.submenu.length > 0;

        return (
            <>
                <div className={`main-menu-item ${item.active ? "active" : ""}`}>
                    <a
                        data-toggle={hasSubmenu ? "collapse" : undefined}
                        className={hasSubmenu ? "dropdown-toggle" : ""}
                        onClick={() => handleItemClick(item)}
                    >
                        {item.icon && <i className={item.icon}></i>} {item.text}
                    </a>
                </div>
                {hasSubmenu && (
                    <div
                        className={`subMenu ${item.open ? "open" : "collapse"}`}
                        key={item.id + "-main-submenu"}
                    >
                        {item.submenu?.map((subItem, index) => subItem.render(subItem, index))}
                    </div>
                )}
            </>
        );
    };
    const renderSubMenuItem = (item, index) => {
        const hasSubmenu = item?.submenu && item.submenu?.length > 0;
        return (
            <div key={item.id + index} className="">
                <a
                    data-toggle={hasSubmenu ? "collapse" : undefined}
                    className={`dropdown-toggle sub-menu-item ${item.active ? "active" : ""
                        }`}
                    onClick={() => handleItemClick(item)}
                >
                    {item.icon && <i className={item.icon}></i>} {item.text}
                </a>
                {hasSubmenu && (
                    <div
                        className={`subMenu ${item.open ? "open" : "collapse"}`}
                        key={item.id + "-" + index + "-submenu"}
                    >
                        {item.submenu?.map((subSubItem, linkIndex) =>
                            subSubItem.render(subSubItem, linkIndex)
                        )}
                    </div>
                )}
            </div>
        );
    };
    const renderLink = (item) => {
        return (
            <div
                className={`sub-sub-menu-item ${item.active ? "active" : ""}`}
                key={item.id}
            >
                <a
                    onClick={() => handleItemClick(item)}
                >
                    {item.text}
                </a>
            </div>
        );
    };
    useEffect(() => {
        if (Array.isArray(userMissions) && userMissions.length > 0) {
            debouncedAddMissionToNavbar();
        }
    }, [userMissions]);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    }
    const toggleLanguage = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
    };

    const toggleRefLink = (url) => {
        window.open(url, '_blank');
    };

    const toggleInternalLink = (url) => {
        window.location.href = url;
    };

    const handleCertificateClick = async () => {
        try {
            if (userDetail?.tracks && Array.isArray(userDetail.tracks)) {
                setShowTrackModal(true);
            } else {
                showErrorToast(t("errors.certificateNoTracks"));
            }
        }
        catch (error) {
            showErrorToast(t("errors.certificateGeneration"));
        }
    };

    return (
        isSidebarOpen && (
            <>
                <nav id="sidebar" className={isMobile ? "active" : ""}>
                    <div style={{ display: "flex" }}>
                        <div className="logo clickable" onClick={() => toggleInternalLink("/")}>
                            <img src={logo} alt="ITAA Logo" />
                        </div>
                        <button
                            type="button"
                            id="mobileSidebarCollapse"
                            className="btn btn-eloket mobile-only"
                            onClick={toggleSidebar}
                        >
                            <i className="fa fa-align-left"></i>
                            <span>{t("sidebar.close")}</span>
                        </button>
                    </div>

                    <div className="mainMenu" id="sidebar-mainMenu">
                        {navbarData.map((item, index) => (
                            <div key={index}> 
                                {item.render(item)}
                            </div>
                        ))}
                    </div>

                    {(userDetail.isImpersonated && (userDetail.isItaaIT || userDetail.isItaaSRD)) && (
                        <div className="mainMenu">
                            <div className="button-sidebar-container">
                                <button className="btn btn-eloket" onClick={handleNaturalPersonConfirmClick}>
                                    <i className="fa fa-calendar-check-o mr-2"></i> {t('confirmation.title')}
                                </button>
                                <button className="btn btn-eloket" onClick={handleResetPostponementsClick}>
                                    <i className="fa fa-undo mr-2"></i>{" "}
                                    {t("confirmation.resetPostponements")} {naturalPersonInformationData.currentPostponements}/{naturalPersonInformationData.maxPostponements}
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="footersidenav">
                        <div className="language-button-section btn-group d-flex justify-content-center">
                            <button
                                type="button"
                                className={`language-button ${userLanguage === 'nl' ? 'active' : ''}`}
                                onClick={() => toggleLanguage("nl")}
                            >
                                NL
                            </button>
                            <span id="btn-language-sperator">|</span>
                            <button
                                type="button"
                                className={`language-button ${userLanguage === 'fr' ? 'active' : ''}`}
                                onClick={() => toggleLanguage("fr")}
                            >
                                FR
                            </button>
                        </div>

                        <button className="backhome-btn" onClick={() => toggleInternalLink("/")}>
                            <i className="fa fa-home"></i>&nbsp;{t("sidebar.backToHome")}
                        </button>

                        <div className="link-group">
                            <button
                                className="ref-link"
                                onClick={() => toggleRefLink(t('sidebar.urls.home'))}
                            >
                                ITAA website
                            </button>
                            <button
                                className="ref-link"
                                onClick={() =>
                                    toggleRefLink(
                                        t('sidebar.urls.privacy')
                                    )
                                }
                            >
                                Privacy
                            </button>
                            <button
                                className="ref-link "
                                onClick={() =>
                                    toggleRefLink(t('sidebar.urls.cookies'))
                                }
                            >
                                Cookies
                            </button>
                        </div>
                    </div>
                </nav>

                <CertificateDownloadModal
                    showModal={showTrackModal}
                    handleClose={() => setShowTrackModal(false)}
                    tracks={userDetail.tracks}
                    userDetail={userDetail}
                />
            </>
        )
    );
};

export default Sidebar;