import {EnumResponse} from './EnumResponse.tsx'
import {ReferencedType} from './ReferencedType.tsx'
import {CommunicationDetail} from './CommunicationDetail.tsx'
import {EntityType} from '../Enums/EntityType.js'
import {CompanyType} from '../Enums/CompanyType.js'

export class UserOffice {
    public legalPersonId: string;
    public companyName: string;
    public itaaNumber: number;
    public dataVerifiedOn?: Date;
    public emails: CommunicationDetail[] = [];
    public mainContacts: ReferencedType[] = [];
    public coAdministrators: ReferencedType[] = [];
    public entityType?: EnumResponse<typeof EntityType>;
    public companyType?: EnumResponse<typeof CompanyType>;    
}