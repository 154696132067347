export default {
    nl: {
        previous_page: "Vorige pagina",
        title: "OOPS!",
        body: "Pagina niet gevonden.",
    },
    fr: {
        previous_page: "Page précédente",
        title: "OOPS!",
        body: "Page non trouvée.",
    },
};
