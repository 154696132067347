export enum QualificationsEnum {
    Accountant= 175470000,
    CertifiedAccountant= 175470002,
    CertifiedTaxAdvisor= 175470003,
    TaxAccountant= 175470001,
    InternalAccountant= 175470004,
    InternalCertifiedAccountant= 175470006,
    InternalCertifiedTaxAdvisor= 175470007,
    InternalTaxAccountant= 175470005,
    TraineeInternalAccountant= 175470008,
    TraineeInternalCertifiedAccountant= 175470010,
    TraineeInternalCertifiedTaxAdvisor= 175470011,
    TraineeInternalTaxAccountant= 175470009,
    TraineeAccountant= 175470012,
    TraineeCertifiedAccountant= 175470014,
    TraineeCertifiedTaxAdvisor= 175470015,
    TraineeTaxAccountant= 175470013
};
