import axios from 'axios';
import { apiConfiguration } from '../../config/apiConfiguration';
import { apiErrorHandler } from './apiErrorHandler';
import { log } from './../../utils/log'

const apiFileClient = axios.create({
    baseURL: apiConfiguration.BFF_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache'
    },
});

export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
    apiFileClient.interceptors.request.use(async (config) => {
        log.info(`${config.method.toUpperCase()} ${config.url}`, 'blue');
        const accessToken = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    });
}

const isSuccessfulResponse = (status, method) => {
    const validStatusCodes = {
        GET: [200, 204],
        POST: [200, 201, 204],
        PUT: [200, 204],
        PATCH: [200, 204],
        DELETE: [200, 204],
    };

    const allowedStatuses = validStatusCodes[method.toUpperCase()] || [];
    return allowedStatuses.includes(status);
};

apiFileClient.interceptors.response.use(
    (response) => {
        const method = response.config.method.toUpperCase();
        if (!isSuccessfulResponse(response.status, method)) {
            throw new Error(`Unexpected HTTP status: ${response.status}`);
        }
        return response;
    },
    (error) => {
        const showToast = !error.config.headers['X-Skip-Error-Toast'];
        apiErrorHandler(error, showToast);
        return Promise.reject(error);
    }
);
export default apiFileClient;