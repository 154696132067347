import { STORAGE_KEYS } from './storageKeys';
import { log } from '../log';

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    log.error("Error clearing localStorage", error);
  }
};

export const setLocalStorageLoginLogged = (data) => {
  localStorage.setItem(STORAGE_KEYS.AUTH.LOGIN_LOGGED, data);
};

export const getLocalStorageLoginLogged = () => {
  return localStorage.getItem(STORAGE_KEYS.AUTH.LOGIN_LOGGED);
};

export const setLocalStorageCurrentUser = (data) => {
  localStorage.setItem(STORAGE_KEYS.CURRENT_USER, JSON.stringify(data));
};

export const getLocalStorageCurrentUser = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEYS.CURRENT_USER));
};

export const setLocalStorageUserOffices = (data) => {
  localStorage.setItem(STORAGE_KEYS.USER_OFFICES, JSON.stringify(data));
};

export const getLocalStorageUserOffices = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_OFFICES));
};

export const removeLocalStorageUserOffices = () => {
  return localStorage.removeItem(STORAGE_KEYS.USER_OFFICES);
};

export const setLocalStorageUserCompanies = (data) => {
  localStorage.setItem(STORAGE_KEYS.USER_COMPANIES, JSON.stringify(data));
};

export const getLocalStorageUserCompanies = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_COMPANIES));
};

export const removeLocalStorageUserCompanies = () => {
  return localStorage.removeItem(STORAGE_KEYS.USER_COMPANIES);
};
