import apiClient from "../../../../../api/client/apiClient";

export const getUserCompany = async (userId, legalPersonId) => {
    const response = await apiClient.get(`/api/Profiles/${userId}/companies`);
    const filteredData = response.data.filter((company) => company.legalPersonId === legalPersonId);
    return filteredData[0];
};

export const getCompaniesOwnClientele = (companyId) => {
  return apiClient
    .get(`/api/companies/${companyId}/ownclientele`)
    .then((response) => response.data);
};

