export default {
  nl: {
    create: "Nieuw infobericht",
    active: "Actieve infoberichten",
    future: "Toekomstige infoberichten",
    archive: "Verlopen infoberichten",
    title: "Titel",
    title_nl: "Titel (NL)",
    title_fr: "Titel (FR)",
    content: "Bericht",
    content_nl: "Bericht (NL)",
    content_fr: "Bericht (FR)",
    startdate: "Start",
    enddate: "Einde",
    noData: "Geen infoberichten om weer te geven.",
    modify: "Bewerken",
    delete: "Verwijderen",
    editTitle: "Infobericht bewerken",
    close: "Sluiten",
  },
  fr: {
    create: "Nouveau message d'information",
    active: "Messages actifs",
    future: "Messages futurs",
    archive: "Messages archivés",
    title: "Titre",
    title_nl: "Titre (NL)",
    title_fr: "Titre (FR)",
    content: "Message",
    content_nl: "Message (NL)",
    content_fr: "Message (FR)",
    startdate: "Début",
    enddate: "Fin",
    noData: "Aucun message d'information à afficher.",
    modify: "Modifier",
    delete: "Supprimer",
    editTitle: "Modifier le message d'information",
    close: "Fermer",
  },
};
