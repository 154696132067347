import { useTranslation } from "react-i18next";
import {
  formatDateOfBirth,
  formatItaaNumber,
  formatShortDateAsLocalDate,
} from "../../../../utils/formatUtils.tsx";
import NaturalPersonInfoHeader from "../../NaturalPersonInfoHeader/NaturalPersonInfoHeader.tsx";
import { OfficialType } from "../../../../Enums/OfficialType.tsx";
import { CurrentUserModel } from "../../../../models/CurrentUserModel.tsx";
import React from "react";
import { NaturalPersonMembershipCurrentTrack } from "../../../../models/NaturalPersonMembershipCurrentTrackModel.tsx";
const ItaaData: React.FC<{ userDetail: CurrentUserModel }> = ({
  userDetail,
}) => {
  const { t } = useTranslation();

  return (
    <div id="main">
      <NaturalPersonInfoHeader userDetail={userDetail} />
      <div className="form-row row">
        <div className="form-col col-md-12">
          <div className="form-group">
            <span
              dangerouslySetInnerHTML={{ __html: t("itaadata.no_edit_text") }}
            ></span>
          </div>
        </div>
      </div>
      <div className="form-row row">
        <div className="form-col col-xl-6 col-lg-6 col-md-6 col-xs-12">
          <div className="form-group">
            <div className="text-h1 nomargin">
              {t("itaadata.title_general")}
            </div>
            <p className="text-sub">{t("itaadata.salutation")}</p>
            <p>{t(`itaadata.salutations.${userDetail?.gender ?? "-"}`)}</p>
            <p className="text-sub">{t("itaadata.firstName")}</p>
            <p>{userDetail?.firstName ?? "-"}</p>
            <p className="text-sub">{t("itaadata.lastName")}</p>
            <p>{userDetail?.lastName ?? "-"}</p>
            <p className="text-sub">{t("itaadata.gender")}</p>
            <p>{t(`itaadata.genders.${userDetail?.gender ?? "-"}`)}</p>
            <p className="text-sub">{t("itaadata.itaaNumber")}</p>
            <p>{formatItaaNumber(userDetail?.itaaNumber) ?? "-"}</p>
            <p className="text-sub">{t("itaadata.language")}</p>
            <p>{t(`itaadata.languages.${userDetail?.language ?? "-"}`)}</p>
            <p className="text-sub">{t("itaadata.bithDate")}</p>
            <p>{formatDateOfBirth(userDetail?.dateOfBirth)}</p>

            <p className="text-sub">{t("itaadata.birthPlace")}</p>
            <p>{userDetail?.birthPlace ?? "-"}</p>
            <p className="text-sub">{t("itaadata.nationality")}</p>
            <p>
              {userDetail?.nationality
                ? t(
                    `itaadata.nationalities.${userDetail.nationality}`
                  ).toUpperCase()
                : "-"}
            </p>
            <p className="text-sub">{t("itaadata.type")}</p>
            <p>
              {userDetail?.officialTypes && userDetail.officialTypes.length > 0
                ? userDetail.officialTypes
                    .map((type) => t(`trackphasetypes.${type.name}`))
                    .join(" | ")
                : "-"}
            </p>
            <p className="text-sub">
              {t("itaadata.nationalRegistrationNumber")}
            </p>
            <p>{userDetail?.nationalRegistrationNumber ?? "-"}</p>
            <p className="text-sub">{t("itaadata.taxNumber")}</p>
            <p>{userDetail?.vatNumber ?? "-"} </p>
          </div>
        </div>
        <div className="form-col col-xl-6 col-lg-6 col-md-6 col-xs-12">
          {(userDetail?.qualifications?.length > 0 ||
            userDetail?.tracks?.length > 0) && (
            <div className="form-group">
              <div>
                {userDetail.hasOfficialType(OfficialType.CandidateIntern) && (
                  <>
                    <p className="text-h1 nomargin">
                      {t(
                        `qualifications.${
                          userDetail.officialTypes.find(
                            (type) => type.id === OfficialType.CandidateIntern
                          )?.name
                        }`
                      )}
                    </p>
                    <p className="text-sub">{t("itaadata.startDate")}</p>
                    <p>
                      {formatShortDateAsLocalDate(
                        userDetail.tracks.find(
                          (track) =>
                            track.currentPhase?.type?.id ===
                            OfficialType.CandidateIntern
                        )?.currentPhase?.currentSituation?.startDate
                      )}
                    </p>
                  </>
                )}
                {userDetail?.qualifications?.map((qual, qualIndex) => {
                  const relatedTracks = userDetail?.tracks?.filter(
                    (track) => track.currentPhase?.qualificationId === qual.id
                  );

                  return (
                    <div key={`qual-${qualIndex}`} className="mb-4">
                      <p className="text-h1 nomargin">
                        {t(`qualifications.${qual?.qualification.name}`)}
                      </p>

                      {relatedTracks.length > 0 &&
                        relatedTracks.map(
                          (
                            track: NaturalPersonMembershipCurrentTrack,
                            trackIndex: number
                          ) => (
                            <div key={`track-${trackIndex}`} className="mb-4">
                              {track.currentPhase?.type?.id !==
                                OfficialType.InternalIntern &&
                                track.currentPhase?.type?.id !==
                                  OfficialType.ExternalIntern && (
                                  <>
                                    <p className="text-sub">
                                      {t("itaadata.since")}
                                    </p>
                                    <p>
                                      {formatShortDateAsLocalDate(
                                        qual?.qualificationAchivedOn
                                      )}
                                    </p>
                                  </>
                                )}

                              {(track.currentPhase?.type?.id ===
                                OfficialType.InternalIntern ||
                                track.currentPhase?.type?.id ===
                                  OfficialType.ExternalIntern) && (
                                <>
                                  <p className="text-sub">
                                    {t("itaadata.startDate")}
                                  </p>
                                  <p>
                                    {formatShortDateAsLocalDate(
                                      track.currentPhase?.currentSituation
                                        ?.startDate
                                    )}
                                  </p>
                                </>
                              )}

                              {track?.inaugurationDate && (
                                <>
                                  <p className="text-sub">
                                    {t("itaadata.inaugurationDate")}
                                  </p>
                                  <p>
                                    {formatShortDateAsLocalDate(
                                      track.inaugurationDate
                                    )}
                                  </p>
                                </>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItaaData;
