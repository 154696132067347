import apiClient from "../../../../../api/client/apiClient";

export const getMandateLink = (officeId) => {
    const data = {
        uri: process.env.REACT_APP_URL_MANDATES,
        organisationId: officeId,
        roles: "EMPLOYEE"
    };
    return apiClient
        .post(`/api/Users/${officeId}/mandatelink`, data)
        .then((response) => response.data)
        .catch((error) => {
          throw new Error(`HTTP error! ${error}`);
        });
};

export const getMandateCompanies = (naturalPersonId) => {
    return apiClient
          .get(`/api/Users/${naturalPersonId}/mandatecompanies`)
          .then((response) => {
            if (!response.status === 200) {
              throw new Error(`HTTP fout! Status: ${response.status}`);
            }
            return response.data;
          })
          .catch((error) => {
            throw new Error(`HTTP request failed: ${error.message}`);
          });
}

export const getExcelForAccountancyUrl = async () => {
  const response = await apiClient.get(`/api/profiles/excelForAccountancy`);
  return response.data.hash;
}