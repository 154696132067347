import React, { useState, useEffect } from "react";
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { formatDateForDateInput } from "../../../utils/formatUtils.tsx";
import { PersonType } from "../../../Enums/PersonType.tsx";
import { patchProfileInsurance, uploadProfileInsuranceCertificate } from "../../../api/profileApi.tsx";
import { NaturalPersonMembershipInsurance } from "../../../models/MyDetails.tsx";
import { patchCompanyInsurance, uploadCompanyInsuranceCertificate } from "../../../api/companyApi.tsx";
import { LegalPersonMembershipInsurance } from "../../../models/CompanyDetails.tsx";
import { isValidDate } from "../../../utils/dateUtils.tsx";
import ConfirmationModal from "../../popups/confirmationModal.tsx";

const EditInsuranceModal = ({
    personId,
    personType,
    membershipId,
    initialFormData,
    show,
    handleClose
}) => {
    const { t } = useTranslation();
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [hasCollectivePolicy, setHasCollectivePolicy] = useState(false);
    const [insuranceCertificate, setInsuranceCertificate] = useState<File>();
    const [confirmCheckboxChecked, setConfirmCheckboxChecked] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [userTriedToSave, setUserTriedToSave] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        if (show) {
            handleRadioChange(initialFormData.collectivePolicy);
            setFormData(prevFormData => ({
                ...prevFormData,
                ...(personType === PersonType.NaturalPerson ? { naturalPersonId: personId } : { legalPersonId: personId }),
                membershipId
            }));
        }
    }, [show, personId, membershipId, personType]);

    useEffect(() => {
        if (userTriedToSave === true) {
            validateForm();
        }
    }, [formData, hasCollectivePolicy, confirmCheckboxChecked, insuranceCertificate, userTriedToSave]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRadioChange = (value: boolean) => {
        setHasCollectivePolicy(value);
        if (value === false) {
            formData.collectivePolicy = false;
            formData.insurer = initialFormData.insurer || "";
            formData.insurerNumber = initialFormData.insurerNumber || "";
            formData.policyNumber = initialFormData.policyNumber || "";
            formData.accessionNumber = "";
            setConfirmCheckboxChecked(false);
        } else {
            formData.collectivePolicy = true;
            formData.insurer = t('insurance.default_collective_insurer');
            formData.insurerNumber = t('insurance.default_collective_insurerNumber');
            formData.policyNumber = t('insurance.default_collective_policyNumber');
            formData.accessionNumber = initialFormData.accessionNumber || "";
            setConfirmCheckboxChecked(true);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf" && file.size <= 10 * 1024 * 1024) {
            setInsuranceCertificate(file);
        }
    };

    const handleCancelSubmit = () => setIsConfirmModalVisible(false);

    const handleSubmit = () => {
        setUserTriedToSave(true);
        if (validateForm()) {
            setIsConfirmModalVisible(true);
        }
    }

    const save = async () => {
        if (personType === PersonType.NaturalPerson) {
            await patchProfileInsurance(formData as NaturalPersonMembershipInsurance);
            await uploadProfileInsuranceCertificate(insuranceCertificate as File,
                formData.naturalPersonId as string,
                formData.coveragePeriodFrom as Date);
        }
        else if (personType === PersonType.LegalPerson) {
            await patchCompanyInsurance(formData as LegalPersonMembershipInsurance);
            await uploadCompanyInsuranceCertificate(insuranceCertificate as File,
                formData.legalPersonId as string,
                formData.coveragePeriodFrom as Date);
        }
    }

    const handleConfirmCheckboxChange = () => {
        setConfirmCheckboxChecked(!confirmCheckboxChecked);
    }

    const validateForm = () => {
        let newErrors: string[] = [];

        if (confirmCheckboxChecked === false)
            newErrors.push(t('insurance.validateConfirmation'));

        if (hasCollectivePolicy === true) {
            if (formData.accessionNumber === undefined || formData.accessionNumber.length < 1
                || formData.coveragePeriodFrom === undefined
                || formData.coveragePeriodFrom === undefined
                || formData.policyNumber === undefined || formData.policyNumber.length < 1) {
                newErrors.push(t('insurance.validateRequiredFields'));
            }
        }
        else if (hasCollectivePolicy === false) {
            if (formData.insurer === undefined || formData.insurer.length < 1
                || formData.insurerNumber === undefined || formData.insurerNumber.length < 1
                || formData.coveragePeriodFrom === undefined
                || formData.coveragePeriodFrom === undefined
                || formData.policyNumber === undefined || formData.policyNumber.length < 1) {
                newErrors.push(t('insurance.validateRequiredFields'));
            }
            if (formData.insurerNumber?.replaceAll('_', '').length < 10) {
                newErrors.push(t('insurance.validateInsurerNumber'));
            }
        }

        if (formData.coveragePeriodFrom !== undefined && formData.coveragePeriodUntil !== undefined) {
            if (!isValidDate(formData.coveragePeriodFrom)) {
                newErrors.push(t('insurance.invalidStartDate'));
            }
            if (!isValidDate(formData.coveragePeriodUntil)) {
                newErrors.push(t('insurance.invalidEndDate'));
            }
            if (formData.coveragePeriodFrom > formData.coveragePeriodUntil) {
                newErrors.push(t('insurance.validateStartDate'));
            }
            if (formData.coveragePeriodUntil < formData.coveragePeriodFrom) {
                newErrors.push(t('insurance.validateEndDate'));
            }

            let startDate = new Date(formData.coveragePeriodFrom);
            startDate.setFullYear(startDate.getFullYear() + 1);
            if (new Date(formData.coveragePeriodUntil) > startDate) {
                newErrors.push(t('insurance.validateDuration'));
            }
        }

        if (insuranceCertificate === undefined
            || insuranceCertificate.type !== "application/pdf"
            || insuranceCertificate.size > 10 * 1024 * 1024) {
            newErrors.push(t('insurance.validateCertificate'));
        }

        setValidationErrors(newErrors);
        return newErrors.length === 0;
    };

    return (
        <>
            {isConfirmModalVisible ? (
                <ConfirmationModal
                    showConfirmationModal={isConfirmModalVisible}
                    handleCloseConfirmationModal={handleCancelSubmit}
                    sendFormClick={() => save()}
                />
            ) : (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{t("insurance.editInsurance")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group-no-background">
                            <div className="form-row">
                                <div className="form-col col-1-1 mb-4">
                                    <div>
                                        <label>{t("insurance.collectivePolicy")}</label><br />
                                        <input
                                            type="radio"
                                            name="collectivePolicy"
                                            value="true"
                                            onChange={() => handleRadioChange(true)}
                                            checked={hasCollectivePolicy === true}
                                        /> <span className="me-2">{t("insurance.collective_yes")}</span>
                                        <input
                                            type="radio"
                                            name="collectivePolicy"
                                            value="false"
                                            onChange={() => handleRadioChange(false)}
                                            checked={hasCollectivePolicy === false}
                                        /> <span>{t("insurance.collective_no")}</span>
                                    </div>
                                </div>
                            </div>

                            {hasCollectivePolicy !== null && (
                                <>
                                    <div className="form-row">
                                        <div className="form-col col-1-1">
                                            <div className="controls">
                                                <input className="form-control"
                                                    name="insurer"
                                                    value={formData.insurer}
                                                    onChange={handleInputChange}
                                                    disabled={hasCollectivePolicy}
                                                />
                                                <label className='control-label required'>{t('insurance.insurer')}</label>
                                            </div>
                                        </div>
                                    </div> <div className="form-row">
                                        <div className="form-col col-1-1">
                                            <div className="controls">
                                                <InputMask
                                                    mask="9999999999"
                                                    className="form-control"
                                                    name="insurerNumber"
                                                    value={formData.insurerNumber}
                                                    onChange={handleInputChange}
                                                    disabled={hasCollectivePolicy}
                                                />
                                                <label className='control-label required'>{t('insurance.insurerNumber')}</label>
                                            </div>
                                        </div>
                                    </div> <div className="form-row">
                                        <div className="form-col col-1-1">
                                            <div className="controls">
                                                <input className="form-control"
                                                    name="policyNumber"
                                                    value={formData.policyNumber}
                                                    onChange={handleInputChange}
                                                    disabled={hasCollectivePolicy}
                                                />
                                                <label className='control-label required'>{t('insurance.policyNumber')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {hasCollectivePolicy && (
                                        <div className="form-row">
                                            <div className="form-col col-1-1">
                                                <div className="controls">
                                                    <input className="form-control" name="accessionNumber" value={formData.accessionNumber} onChange={handleInputChange} />
                                                    <label className='control-label required'>{t('insurance.accessionNumber')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-row">
                                        <div className="form-col col-1-1">
                                            <div className="controls">
                                                <input type="date" className="form-control" name="coveragePeriodFrom" value={formatDateForDateInput(formData.coveragePeriodFrom)} onChange={handleInputChange} max="9999-12-31" />
                                                <label className='control-label required'>{t('insurance.startDate')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-col col-1-1">
                                            <div className="controls">
                                                <input type="date" className="form-control" name="coveragePeriodUntil" value={formatDateForDateInput(formData.coveragePeriodUntil)} onChange={handleInputChange} max="9999-12-31" />
                                                <label className='control-label required'>{t('insurance.endDate')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-col col-1-1">
                                            <div className="controls">
                                                <input type="file" className="form-control" accept="application/pdf" onChange={handleFileChange} />
                                                <label className='control-label required'>{t('insurance.uploadCertificate')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!hasCollectivePolicy && (
                                        <div className="form-row">
                                            <div className="form-col col-1-1">
                                                <label htmlFor="insuranceConfirm">
                                                    <input
                                                        type="checkbox"
                                                        id="insuranceConfirm"
                                                        checked={confirmCheckboxChecked}
                                                        onChange={handleConfirmCheckboxChange}
                                                    />
                                                    {t('insurance.confirmation')}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-row">
                                        <div className="form-col col-1-1">
                                            {validationErrors && validationErrors.length > 0 && (
                                                <>
                                                    <span className="warning-text">{t("insurance.validationErrors")}</span>
                                                    <ul className='warning-text'>
                                                        {validationErrors.map((error, index) => (
                                                            <li key={index}>{error}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t("popup.cancel")}
                        </Button>
                        <Button className="btn-eloket" variant="secondary" onClick={handleSubmit} disabled={(validationErrors && validationErrors.length > 0)}>
                            {t("popup.save")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default EditInsuranceModal;