import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { getNetworkDetail, deleteNetworkDetail } from './http/HttpClientHelper';
import Add from '../ProfileNetworks/Add/add';
import NaturalPersonInfoHeader from '../../../NaturalPersonInfoHeader/NaturalPersonInfoHeader.tsx';

const ProfileNetworks = ({ userDetail }) => {
    const { t } = useTranslation();
    const [myNetworkDetail, setMyNetworkDetail] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [wentThrough, setWentThrough] = useState(false);

    useEffect(() => {
        (async () => {
            const networkDetail = await getNetworkDetail(userDetail.id).finally(() => {
                setLoading(false);
            });
            setMyNetworkDetail(networkDetail);
            setWentThrough(false);
        })();
    }, [userDetail.id, wentThrough]);

    const deleteNetwork = async (selectedNetworkId) => {
        setLoading(true);
        await deleteNetworkDetail(selectedNetworkId).finally(() => {
            setLoading(false);
        });
        setWentThrough(true);
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false); // Sluit de modal
    };

    const handleNetworkNameChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filtered networks based on search term
    const filteredNetworks = myNetworkDetail.filter(item => item.networkName.toLowerCase().includes(searchTerm));

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <div id="content">
                <NaturalPersonInfoHeader userDetail={userDetail} />
                <div id="table">
                    <div className="text-h1">{t('network.title')}</div>
                    <div className="form-group">
                        <p>{t('network.subtitleMyNetwork')}</p>
                        <div className='form-row'>
                            <div className='form-col'>
                                <div className='controls'>
                                    <Form.Control
                                        className="network-search"
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleNetworkNameChange}
                                    />
                                    <label className='control-label'>{t('network.search')}</label>
                                </div>
                            </div>
                        </div>

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>{t('network.name')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredNetworks.length > 0 ? (
                                    filteredNetworks.map((item, index) => (
                                        <tr key={index}>
                                            <td width="75%">{item.networkName}</td>
                                            <td className='lastColumn' width="25%">
                                                <Button
                                                    className="btn btn-danger"
                                                    variant="primary"
                                                    onClick={() => deleteNetwork(item.naturalPersonNetworkId)}
                                                >
                                                    {t('network.delete')}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>{t('network.noDetail')}</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <footer>
                    <div className="button-save-warning-container">
                        <span onClick={() => setShowAddModal(true)}>
                            <button className="btn btn-eloket">
                                <span className="fa fa-plus"></span> {t('network.addNetwork')}
                            </button>
                        </span>
                    </div>
                </footer>
                {showAddModal && (
                    <Add
                        show={showAddModal}
                        handleClose={() => setShowAddModal(false)}
                        natPersonId={userDetail.id}
                        existingNetworks={myNetworkDetail} // Pass all networks to check duplicates
                    />
                )}
            </div>
        </>
    );
};

export default ProfileNetworks;
