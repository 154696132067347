export enum GeneralAssemblyRegistrationOptionType {
    Online = 'Online',
    Onsite = 'Onsite',
    NoShow = "noShow",
    WithProxy= "withProxy",
    Show = "Show"
}
export enum GeneralAssemblyWillAttend {
    Yes = 979670000,
    No = 979670001
}

export enum GeneralAssemblyRegistrationType {
    Online = 979670000,
    Onsite = 979670001,
}