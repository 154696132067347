import { NaturalPersonMembershipInsurance } from '../models/MyDetails';
import apiClient from './client/apiClient'
import apiFileClient from './client/apiFileClient';
import { getCompanyOwnClientele } from './companyOwnClienteleApi';

export const getProfileItaaDetails = async (naturalpersonId) => {
    const response = await apiClient.get(`/api/profiles/${naturalpersonId}/itaadetails`);
    return response.data;
};

export const getProfileDetails = async (naturalpersonId) => {
    return apiClient
        .get(`/api/Profiles/${naturalpersonId}/mydetails`)
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};

export const patchProfileInsurance = async (insurance: NaturalPersonMembershipInsurance) => {
    return apiClient
        .patch('api/Profiles/insurance', insurance)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}

export const postProfileInsurance = async (insurance: NaturalPersonMembershipInsurance) => {
    return apiClient
        .post('api/Profiles/insurance', insurance)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}

export const uploadProfileInsuranceCertificate = async (file: File, naturalPersonId: string, startDate: Date) => {
    const formData = new FormData();
    const date = new Date(startDate);
    formData.append('file', file);
    formData.append('naturalPersonId', naturalPersonId);
    formData.append('insuranceStartDate', date.toISOString());

    return apiFileClient
        .post(`/api/profiles/insurance/certificate`, formData)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}

export const patchProfileDetails = (naturalpersonId, detailsToUpdate) => {
    return apiClient
        .patch(`/api/Profiles/${naturalpersonId}/mydetails`, detailsToUpdate)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};

export const getProfileCompanies = async (naturalpersonId) => {
    const response = await apiClient.get(`/api/profiles/${naturalpersonId}/companies`);
    for (let company of response.data) {
        const clienteleInfo = await getCompanyOwnClientele(company.legalPersonId);
        company.isManagementCompany = clienteleInfo.isManagementCompany;
    }
    return response.data;
};

export const getProfileOffices = async (naturalpersonId) => {
    const response = await apiClient.get(`/api/profiles/${naturalpersonId}/offices`);
    return response.data;
};

export const patchNationalNumber = async (naturalpersonId, nationalNumber) => {
    try {
        const response = await apiClient
            .patch(`/api/Profiles/${naturalpersonId}/nationalnumber`, nationalNumber, {
                headers: {
                    'X-Skip-Error-Toast': 'true'
                }
            });
        return response.data;
    }
    catch (error) {
        return '500';
    }
};

export const getRegisteredOffice = async (naturalpersonId, language) => {
    return apiClient
        .get(`/api/profiles/${naturalpersonId}/kbo?language=${language}`).then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};
