export enum OfficialType { 
  CandidateIntern = 175470000, 
  InternalIntern = 175470001, 
  ExternalIntern = 175470002, 
  _7Year = 175470003, 
  _7YearArt56 = 175470004, 
  InternalMember = 175470005, 
  ExternalMember = 175470006, 
  HonoraryMember = 175470007, 
  Bridge = 175470008, 
  BridgeArt21 = 175470009 
}