import React, { useEffect, useState } from 'react';
import DropdownWithActiveTrackPhaseType from './DropDownWithActiveTrackPhaseType.tsx';
import { useTranslation } from "react-i18next";
import { CurrentUserModel } from '../../../../models/CurrentUserModel.tsx';
import { GeneralAssemblyRegistrationOptionType } from '../../../../Enums/GeneralAssemblyRegistrationType.tsx';
import { Button } from 'react-bootstrap';
import CountdownTextComponent from '../../../countdown/CountdownTextComponent.tsx';

interface RadioOption {
  value: string;
  label: string;
  subOptions?: RadioOption[];
  hasDropdown?: boolean;
  isInternalMember?: boolean;
  isExternalMember?: boolean;
  hasTimer?: boolean;
  hasCheckBox?:boolean;
  proxyDateOngoing?:boolean;
}

interface OptionMenuComponentProps {
  options: RadioOption[];
  setSelectedOption: (selectedId: string) => void;
  setselectPerson: (selectedId: string) => void;
  userDetail: CurrentUserModel;
  setCheckboxChecked: (checked: boolean) => void;
  checkboxChecked:boolean;
  logout:()=> void;
  isSessionElapsedGreaterThan: (thresholdInSeconds: number) => { 
    isGreaterThan: boolean; 
    remainingSeconds: number; 
  };
  setProxyFeatureUnavailable: (checked: boolean) => void;
  proxyFeatureUnavailable: boolean;
}


const OptionMenuComponent: React.FC<OptionMenuComponentProps> = ({ options, setSelectedOption, setselectPerson, userDetail, setCheckboxChecked, checkboxChecked, setProxyFeatureUnavailable, proxyFeatureUnavailable, isSessionElapsedGreaterThan, logout}) => {
  const [selectedOption, setSelectedOptionState] = useState<string>('');
  const [subOption, setSubOption] = useState<string>('');
  const [initialSeconds, setInitialSeconds] = useState<number>(0);
  const { t } = useTranslation();
  
  useEffect(() => {
    const threshold = 180; 
    const result = isSessionElapsedGreaterThan(threshold);
    setProxyFeatureUnavailable(result.isGreaterThan);
    setInitialSeconds(result.remainingSeconds);
  }, [isSessionElapsedGreaterThan, setProxyFeatureUnavailable, selectedOption]);

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  }

  const handleOptionChange = (value: string) => {
    setSelectedOptionState(value); 
    setSubOption('');  
    setCheckboxChecked(false); 
    setSelectedOption(value);
  };

  const handleSubOptionChange = (value: string) => {
    setSubOption(value); 
    setSelectedOption(value); 
  };

  const handleCountdownFinish = () => {
    setProxyFeatureUnavailable(true);
  };

  return (
    <div className="p-4 border rounded shadow-md w-96 my-4">
    <h5 className="font-bold">{t('generalAssembly.ChooseOption')}</h5>
    {options
  .filter((option) => {
    if (option.value === GeneralAssemblyRegistrationOptionType.WithProxy) {
      return option.proxyDateOngoing && (option.isExternalMember || option.isInternalMember);
    }
    return true;
  })
  .map((option) => (
    <div key={option.value} className="mb-2">
      <label className="block">
        <input
          type="radio"
          value={option.value}
          checked={selectedOption === option.value}
          onChange={() => handleOptionChange(option.value)} 
        />
        {option.label}
      </label>

      {selectedOption === option.value && option.subOptions && (
        <div className="mb-2">
          {option.subOptions.map((sub) => (
            <label key={sub.value} className="mb-2 d-block ms-3">
              <input
                type="radio"
                value={sub.value}
                checked={subOption === sub.value}
                onChange={() => handleSubOptionChange(sub.value)} 
                className="me-2"
              />
              {sub.label}
            </label>
          ))}
        </div>
      )}

      {selectedOption === option.value && (option.hasDropdown && !proxyFeatureUnavailable) && (
          <div className="pl-4">
            <CountdownTextComponent
              text={t('generalAssembly.registration.optionMenu.proxy.session.sessionValid')} 
              initialSeconds={initialSeconds}
              onFinish={handleCountdownFinish} 
              placeholder="countdown"
            />
            <DropdownWithActiveTrackPhaseType
              onSelect={setselectPerson} 
              userDetail={userDetail}
            />
            <div className="form-check">
              <input
                className="form-check-input" 
                type="checkbox" 
                id="proxyConfiramationCheckbox"
                name="proxyConfiramationCheckbox"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
              <label  className="form-check-label" htmlFor="proxyConfiramationCheckbox">{t('generalAssembly.registration.optionMenu.proxy.digitalSignature.checkboxText')}</label>
            </div>
            <section className="text-danger mt-3" dangerouslySetInnerHTML={{ __html: t("generalAssembly.registration.optionMenu.proxy.warning") }} />
          </div>
          
      )}

      {selectedOption === option.value && option.hasTimer && (
              <div className="pl-4 mt-2">
                {proxyFeatureUnavailable &&(
                  <div  className="alert alert-danger" role="alert" >
                    <p>{t('generalAssembly.registration.optionMenu.proxy.session.sessionInvalid')}</p>
                      <div className="justify-content-end">
                        <Button 
                          variant="secondary" 
                          className="btn-eloket" 
                          onClick={logout}
                        > 
                          {t('generalAssembly.registration.optionMenu.proxy.session.buttonText')}
                        </Button>
                      </div>

                  </div>
                )}
              </div>
            )}
    </div>
  ))}
  </div>
  )  
};

export default OptionMenuComponent;


