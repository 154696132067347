import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getBeExcellentData, patchBeExcellentAdmin, postBeExcellentRequest } from './http/HttpClientHelper';
import { useParams } from 'react-router-dom';
import AutocompleteResults from './AutoComplete/AutoComplete';
import { getMainContactOrCoAdmin } from '../MainContact/http/HttpClientHelper';
import { getOffice } from '../BeExcellent/http/HttpClientHelper';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { formatItaaNumber, formatShortDateAsLocalDate } from '../../../../utils/formatUtils.tsx';
import BeExcellentRequest from '../../../../models/office/BeExcellentRequest';
import AddNewEmployee from '../NewEmployee/AddNewEmployee';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';

const BeExcellent = ({ userDetail }) => {
    const { t } = useTranslation();
    const { legalPersonId } = useParams();

    const [beExcellentData, setBeExcellentData] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [termsConditionsAccepted, setTermsConditionsAccepted] = useState('');
    const [url, setUrl] = useState('');
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedName, setSelectedName] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [officeDetails, setOfficeDetails] = useState(null);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);
        await patchBeExcellentAdmin(legalPersonId, selectedPerson.naturalPersonId);
        window.location.reload();
    }

    const handleSubmit = async () => {
        setLoading(true);
        const newBeExcellentData = new BeExcellentRequest({
            legalPersonId: legalPersonId,
            naturalPersonId: selectedPerson.naturalPersonId,
            url: url,
            language: selectedLanguage,
            termsAndConditionsAccepted: termsConditionsAccepted,
        });
        await postBeExcellentRequest(newBeExcellentData);
        window.location.reload();
    }

    const changeUrl = (e) => { setUrl(e.target.value); }

    const fetchOfficeDetails = async () => {
        const data = await getOffice(legalPersonId);
        setOfficeDetails(data);
    };

    const fetchBeExcellentData = async () => {
        setLoading(true);
        let data = await getBeExcellentData(legalPersonId);
        if (!data) { data = {} }
        setBeExcellentData(data);

        var currentUserIsMainContactOrCoAdmin = await getMainContactOrCoAdmin(legalPersonId, userDetail.id);
        setShowWarning(!currentUserIsMainContactOrCoAdmin.isMainContactOrCoAdmin);
        setLoading(false);
    };

    useEffect(() => {
        const fetchDetails = async () => {
            fetchOfficeDetails();
            fetchBeExcellentData();
        }

        fetchDetails().finally(() => {
            setLoading(false);
        });
    }, [legalPersonId]);

    useEffect(() => {
        setButtonDisabled(!isCheckboxChecked || !buttonEnabled);
    }, [isCheckboxChecked, buttonEnabled]);

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedPerson(selectedResult);
        setSelectedName(selectedResult.fullName);
        setIsClickedResult(true);
        setButtonEnabled(true);
    };

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
        setButtonEnabled(true);
        setIsClickedResult(false);
    };

    const handleDropdownChange = (event) => {
        setSelectedLanguage(event.target.value);
        setButtonEnabled(true);
    };

    const handleCheckboxChange = (event) => {
        setCheckboxChecked(event.target.checked);
        if (event.target.checked) {
            setButtonEnabled(true);
            setTermsConditionsAccepted(new Date().toISOString());
        }
        else {
            setButtonEnabled(false);
            setTermsConditionsAccepted('');
        }
    };

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {(beExcellentData && officeDetails) && (
                <div id="content">
                    <LegalPersonInfoHeader
                        legalPersonId={legalPersonId}
                        userDetail={userDetail}
                        legalPersonType={LegalPersonTypeEnum.Office}
                    />
                    {beExcellentData.subscription ? (
                        <>
                            {beExcellentData.licenceCode ? (
                                <>
                                    <Row>
                                        <Col>
                                            <div className="text-h1">{t('be_excellent.info')}</div>
                                            <div className='form-group'>
                                                <div className='beexcellentlabel'><span className='completed'>{t('be_excellent.label_completed')}</span></div>
                                                <span>{t('be_excellent.book_language')}</span><b>{t(`be_excellent.language_${beExcellentData.languageCode}`)}</b><br />
                                                <span>{t('be_excellent.license_code')}</span><b>{beExcellentData.licenceCode}</b><br />
                                                <a href={t('be_excellent.licenceletter_url')} target="_blank">{t('be_excellent.license_letter')}</a><b>{formatShortDateAsLocalDate(beExcellentData.dLicenceLetter)}</b><br />
                                                <span>{t('be_excellent.version')}</span><b>{t(`be_excellent.version_${beExcellentData.version.id}`)}</b><br />
                                                <span>{t('be_excellent.owner_subscription')}</span><b>{beExcellentData.admin.fullName}, ({beExcellentData.admin.city}) #{formatItaaNumber(beExcellentData.admin.itaaNumber)}</b><br />
                                                <a href={t('be_excellent.termsconditions_url')} target="_blank">{t('be_excellent.terms_and_conditions')}</a><span> {t('be_excellent.accepted_on')}</span><b>{formatShortDateAsLocalDate(beExcellentData.dTermsConditions)}</b>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="text-h1">{t('be_excellent.open')}</div>
                                            <div className='form-group'>
                                                <a href={beExcellentData.beExcellentUrl} target='_blank'>{beExcellentData.beExcellentUrl}</a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-h1">{t('be_excellent.title')}</div>
                                            <div className="form-group">
                                                {beExcellentData.admin.naturalPersonId === userDetail.id ? (
                                                    <>
                                                        <div className='form-row'>
                                                            <div className='form-col'>
                                                                <div className='controls'>
                                                                    <p>{t('be_excellent.subscription_changelanguage')}</p>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={selectedName}
                                                                        placeholder=""
                                                                        autoComplete='off'
                                                                        onChange={handleNameChange}
                                                                    />
                                                                    <label className="control-label">{t('be_excellent.be_excellent_admin')}</label>
                                                                    {selectedName.trim().length >= 2 && !isClickedResult && (
                                                                        <AutocompleteResults
                                                                            inputValue={selectedName}
                                                                            handleSelection={handleAutocompleteSelection}
                                                                            legalPersonId={legalPersonId}
                                                                        />
                                                                    )}
                                                                    <div className='form-button-row'>
                                                                        <button onClick={handleUpdate} className="btn btn-eloket" type="button" disabled={!buttonEnabled}> {t('be_excellent.verify')} </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <p>{t('be_excellent.not_allowed', { adminFullName: beExcellentData.admin.fullName })}</p>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Col>
                                            <div className="text-h1">{t('be_excellent.title')}</div>
                                            <div className="form-group">
                                                <div className='beexcellentlabel'><span className='processing'>{t('be_excellent.label_processing')}</span></div>
                                                <p>{t('be_excellent.processing', { adminFullName: beExcellentData.admin.fullName })}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="form-group">
                                <div className='beexcellentlabel'><span className='none'>{t('be_excellent.label_none')}</span></div>
                                {showWarning && (
                                    <div className='form-row'>
                                        <div className='alert alert-danger mb-0'>
                                            <span>{t('office_composition.warningMainContact')}</span>
                                        </div>
                                    </div>
                                )}
                                {showAddNew && (
                                    <AddNewEmployee show={showAddNew} handleClose={() => setShowAddNew(false)} />
                                )}
                                <div className="form-row">
                                    <p className="text-description">
                                    </p>
                                </div>
                                <div className="form-row mb-3">
                                    <span dangerouslySetInnerHTML={{ __html: t('be_excellent.information_form') }} />
                                </div>
                                <div className="form-row row">
                                    <div className='form-col col-sm-12 col-md-6'>
                                        <div className='controls'>
                                            {!showWarning ? (
                                                <select className='form-control' id="dropdown" value={selectedLanguage} onChange={handleDropdownChange}>
                                                    <option value="" disabled>{t('be_excellent.placeholder')}</option>
                                                    <option value="100000001">{t('be_excellent.dutch')}</option>
                                                    <option value="100000000">{t('be_excellent.french')}</option>
                                                    <option value="100000002">{t('be_excellent.daf')}</option>
                                                </select>
                                            ) : (
                                                <input className="read-only-input" value={t('be_excellent.placeholder')} readOnly />
                                            )}
                                            <label className='control-label' htmlFor="dropdown">{t('be_excellent.dropdown_label')}</label>
                                        </div>
                                    </div>
                                    <div className='form-col col-sm-12 col-md-6'>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className='form-col col-sm-12 col-md-6'>
                                        <div className='controls'>
                                            {!showWarning ? (
                                                <>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={selectedName}
                                                        placeholder=""
                                                        autoComplete='off'
                                                        onChange={handleNameChange}
                                                    />
                                                    <label className="control-label">{t('be_excellent.be_excellent_admin')}</label>
                                                    {selectedName.trim().length >= 2 && !isClickedResult && (
                                                        <AutocompleteResults
                                                            inputValue={selectedName}
                                                            handleSelection={handleAutocompleteSelection}
                                                            legalPersonId={legalPersonId}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <input className="read-only-input" value={selectedName} readOnly />
                                                    <label className="control-label">{t('be_excellent.be_excellent_admin')}</label>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-col col-sm-12 col-md-6'>
                                        <span dangerouslySetInnerHTML={{ __html: t('aml_officers.new_employee') }} />
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className='form-col col-sm-12 col-md-6'>
                                        <div className='controls'>
                                            {!showWarning ? (
                                                <input className="form-control" type="text" value={url} onChange={changeUrl} />
                                            ) : (
                                                <input className="read-only-input" readOnly />
                                            )}
                                            <label className="control-label">URL</label>
                                        </div>
                                    </div>
                                    <div className='form-col col-sm-12 col-md-6'>
                                        <p>{t('be_excellent.info_url')}</p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="controls row-s" data-val="false">
                                        <div className="form-col">
                                            <input
                                                type='checkbox'
                                                name='SelectedMailingLists'
                                                checked={isCheckboxChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <a target="_blank" rel="noopener noreferrer" href={t('be_excellent.termsconditions_url')}>
                                                {t('be_excellent.terms_and_conditions')}
                                            </a>
                                            {(!isCheckboxChecked) && <p>{t('be_excellent.warning')}</p>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {!showWarning && (
                                <footer>
                                    <div className="button-save-warning-container">

                                        <button className="btn btn-eloket" type="button" onClick={handleSubmit} disabled={isButtonDisabled}> {t('be_excellent.verify')} </button>
                                    </div>
                                </footer>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default BeExcellent;