import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { formatItaaNumber } from '../../../utils/formatUtils.tsx';
import { searchNaturalPersonData } from '../http/HttpClientHelper';

const AutocompleteNaturalPersonResults = ({ inputValue, handleSelection, legalPersonId }) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                setLoading(true);
                const response = await searchNaturalPersonData(inputValue);
                setResults(response);
                setLoading(false);
            };

            if (inputValue.trim() !== '') {
                fetchAutocompleteResults();
            } else {
                setResults([]);
            }
        }, 200);

        return () => clearTimeout(delaySearch);
    }, [inputValue, legalPersonId]);

    const handleItemClick = async (result) => {
        await handleSelection(result);
        setResults([]);
    };

    return (
        <ListGroup>
            {loading && (
                <ListGroup.Item className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </ListGroup.Item>
            )}
            {!loading && results.map((result) => (
                <ListGroup.Item key={result.naturalPersonId} onMouseDown={() => handleItemClick(result)}>
                    {result.fullName}, #{formatItaaNumber(result.itaaNumber)}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

export default AutocompleteNaturalPersonResults;