import apiClient from "../../../../api/client/apiClient";

export const getUserVisibilities = async (userId) => {
  const response = await apiClient.get(`/api/Users/${userId}/visibilities`);
  return response.data;
};

export const getActiveInfoMessages = async () => {
  const response = await apiClient.get("/api/infomessages/active");
  return response.data;
};

export const getNaturalPersonInformationValidation = async (
  naturalPersonId
) => {
  const response = await apiClient.get(
    `/api/naturalPersonInformationValidation/${naturalPersonId}`
  );


  return response.data;
};

export const patchNaturalPersonInformationValidation = async (
  naturalPersonId,
  patchObject
) => {
  const response = await apiClient.post(
    `/api/naturalPersonInformationValidation/${naturalPersonId}`,
    patchObject
  );
  return response.data;
};

export const resetPostponementsNaturalPersonInformationValidation = async (naturalPersonId) => {
  const response = await apiClient.post(
    `/api/naturalPersonInformationValidation/${naturalPersonId}/postponements/reset`
  );
  return response.data;
}

export const getEstoxAdminLink = (naturalPersonId) => {
  const url = `/api/Users/${naturalPersonId}/estoxadminlink`;
 
  return apiClient
    .get(url)
    .then((response) => {
      return response.data.estoxLink;
    });
}

export const getInisLink = (naturalPersonId) => {
  const url = `/api/Users/${naturalPersonId}/inislink`;
  return apiClient
    .get(url)
    .then((response) => {
      return response.data;
    });
};

export const getCompanyWebLink = (naturalPersonId) => {
    const url = `/api/Users/${naturalPersonId}/companyweblink`;
    return apiClient
        .get(url)
        .then((response) => {
            return response.data;
        });
};