import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserMissions } from '../Sidebar/http/HttpClientHelper';
import { formatFileNumber, formatShortDateAsLocalDate } from '../../../utils/formatUtils.tsx';
import { Modal, Button} from 'react-bootstrap';

const statusIcons = {
  Research: 'fa fa-folder-open',
  WithoutConsequence: 'fa fa-folder',
  CommentLetter: 'fa fa-folder',
  Covocation1: 'fa fa-user',
  Covocation2: 'fa fa-user',
  Covocation3: 'fa fa-user',
  CovocationLetter: 'fa fa-folder',
  LegalService: 'fa fa-pencil',
};

const Missions = ({ userDetail }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [userMissions, setUserMissions] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedMission, setSelectedMission] = useState(null);

  const handleShow = (mission) => {
    setSelectedMission(mission);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const missions = await getUserMissions(userDetail.id);
      setUserMissions(missions);
      setLoading(false);
    })();
  }, [userDetail]);

  return (
    <div id="main">
      {!loading && (
        <>
          <div id="content">
            <div className="text-h1">{t("missions.title")}</div>
          </div>
          <div className='form-group'>
            {userMissions.length > 0 && (
              <table className="table mb-4">
                <thead>
                  <tr>
                    <th>{t("missions.header_receivedDate")}</th>
                    <th>{t("missions.header_dossier")}</th>
                    <th>{t("missions.header_type")}</th>
                    <th>{t("missions.header_company")}</th>
                    <th>{t("missions.header_status")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userMissions.map((mission, index) => (
                    <tr key={index}>
                      <td>{formatShortDateAsLocalDate(mission.receivedDate)}</td>
                      <td>{formatFileNumber(mission.file)}</td>
                      <td>{t(`missions.types.${mission.type}`)}</td>
                      <td>{mission.company}</td>
                      <td><i className={`${statusIcons[mission.status.name]} mr-2`}></i> {t(`missions.statusses.${mission.status.name}`)}</td>
                      <td className='lastColumn'>
                        <button className='btn btn-eloket' onClick={() => handleShow(mission)}>
                          {t('missions.details')}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div id="content">
            <div className="text-h1">{t("missions.title_steps")}</div>
          </div>
          <div className='form-group'>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("missions.header_status")}</th>
                  <th>{t("missions.header_definition")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><i className='fa fa-folder-open'></i></td>
                  <td>{t('missions.statusses.Research')}</td>
                  <td>{t('missions.statusdefinitions.Research')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-folder'></i></td>
                  <td>{t('missions.statusses.WithoutConsequence')}</td>
                  <td>{t('missions.statusdefinitions.WithoutConsequence')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-folder'></i></td>
                  <td>{t('missions.statusses.CommentLetter')}</td>
                  <td>{t('missions.statusdefinitions.CommentLetter')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-user'></i></td>
                  <td>{t('missions.statusses.Covocation1')}</td>
                  <td>{t('missions.statusdefinitions.Covocation1')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-user'></i></td>
                  <td>{t('missions.statusses.Covocation2')}</td>
                  <td>{t('missions.statusdefinitions.Covocation2')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-user'></i></td>
                  <td>{t('missions.statusses.Covocation3')}</td>
                  <td>{t('missions.statusdefinitions.Covocation3')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-folder'></i></td>
                  <td>{t('missions.statusses.CovocationLetter')}</td>
                  <td>{t('missions.statusdefinitions.CovocationLetter')}</td>
                </tr>
                <tr>
                  <td><i className='fa fa-pencil'></i></td>
                  <td>{t('missions.statusses.LegalService')}</td>
                  <td>{t('missions.statusdefinitions.LegalService')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}

      {selectedMission && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('missions.details')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>{t('missions.header_dossier')}:</strong> {selectedMission.name} <br />
              <strong>{t('missions.header_receivedDate')}:</strong> {selectedMission.receivedDate} <br />
              <strong>{t('missions.header_type')}:</strong> {t(`missions.types.${selectedMission.type}`)} <br />
              <strong>{t('missions.header_company')}:</strong> {selectedMission.company}
            </p>
            <div id="content">
              <div className="text-h1">{t("missions.header_status")}</div>
              <table className='table'>
                <thead>
                  <tr>
                    <th>{t("missions.header_date")}</th>
                    <th>{t("missions.header_status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMission.researchExam && (
                    <tr>
                      <td>{formatShortDateAsLocalDate(selectedMission.researchExam)}</td>
                      <td>{t('missions.statusses.Research')}</td>
                    </tr>
                  )}
                  {selectedMission.withoutConsequence && (
                    <tr>
                      <td>{formatShortDateAsLocalDate(selectedMission.withoutConsequence)}</td>
                      <td>{t('missions.statusses.WithoutConsequence')}</td>
                    </tr>
                  )}
                  {selectedMission.commentLetter && (
                    <tr>
                      <td>{formatShortDateAsLocalDate(selectedMission.commentLetter)}</td>
                      <td>{t('missions.statusses.CommentLetter')}</td>
                    </tr>
                  )}
                  {selectedMission.covocations && selectedMission.covocations.map((date, index) => (
                    <tr key={index}>
                      <td>{formatShortDateAsLocalDate(date)}</td>
                      <td>{t(`missions.statusses.Covocation${index + 1}`)}</td>
                    </tr>
                  ))}
                  {selectedMission.covocationLetter && (
                    <tr>
                      <td>{formatShortDateAsLocalDate(selectedMission.covocationLetter)}</td>
                      <td>{t('missions.statusses.CovocationLetter')}</td>
                    </tr>
                  )}
                  {selectedMission.legalService && (
                    <tr>
                      <td>{formatShortDateAsLocalDate(selectedMission.legalService)}</td>
                      <td>{t('missions.statusses.LegalService')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('popup.cancel')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Missions;