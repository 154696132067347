import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionContext } from '../../providers/SessionProvider';
import {  useLocation } from "react-router-dom";

const AuthError = () => {
    const { logout } = useSessionContext();
    const { t } = useTranslation();
    const location = useLocation();
    const  errormessage  = location.state.errormessage.message;
    const errorText = t(`errors.${errormessage}`, { defaultValue: t('errors.auth_default') });
    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <div className="mb-4 lead" dangerouslySetInnerHTML={{ __html: errorText }}>
                        </div>
                        <button className="btn-error" onClick={logout}>
                            <i className="fa fa-arrow-left"></i>&nbsp;{t("navbar.logout")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthError;
