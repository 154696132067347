import React from "react";
import { Container } from "react-bootstrap";

const SplashScreen = ({ logoSrc, text }) => {
  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light"
    >
      {logoSrc && (
        <img
          src={logoSrc}
          alt="ITAA Logo"
          className="img-fluid mb-4"
          style={{ maxWidth: "150px", height: "auto" }}
        />
      )}
      <div className="loader mb-3"></div>

      {text && <p className="text-muted small mb-4">{text}</p>}
    </Container>
  );
};

export default SplashScreen;
