import apiClient from "../../../../../api/client/apiClient";

export const getOfficeComposition = (legalPersonId) => {
  return apiClient
    .get(`/api/offices/${legalPersonId}/officecomposition`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const patchOfficeComposition = (legalPersonId, officeComposition) => {
  return apiClient
    .patch(`/api/offices/${legalPersonId}/officecomposition`, officeComposition)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const postOfficeComposition = (legalPersonId, naturalPersonId) => {
  return apiClient
    .post(`/api/offices/${legalPersonId}/officecomposition`, naturalPersonId)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const searchPersons = (legalPersonId, search) => {
  return apiClient
    .get(`/api/offices/${legalPersonId}/officecomposition/search/${search}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const deletePersonOfficeComposition = (
  legalPersonId,
  naturalPersonId
) => {
  return apiClient
    .delete(
      `/api/offices/${legalPersonId}/officecomposition/${naturalPersonId}/deleteperson`
    )
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
