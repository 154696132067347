import apiClient from "../../../../../api/client/apiClient";
import { ActiveGeneralAssembly,GetGeneralAssemblyProxyMembersQueryResult,GeneralAssemblyParticipant, ParticipantsCountQuery, GeneralAssemblyRegistrationOptionMenuModel, GeneralAssemblyQueryResult } from "../../../../../models/ActiveGeneralAssembly";
import { AxiosResponseModel } from "../../../../../models/AxiosResponseModel.tsx";

export const getActiveGeneralAssembly = async (): Promise<ActiveGeneralAssembly| null> => {
  const response: AxiosResponseModel<ActiveGeneralAssembly> = await apiClient.get("/api/GeneralAssemblies/active");
  return response.data ?? null;
};

export const getLatestGeneralAssembly = async (): Promise<GeneralAssemblyQueryResult> => {
  const response: AxiosResponseModel<GeneralAssemblyQueryResult> = await apiClient.get("/api/GeneralAssemblies/last");
  return response.data ?? null;
};



export const getGeneralAssemblyProxies = async (search: string, naturalPersonId: string): Promise<GetGeneralAssemblyProxyMembersQueryResult[]> => {
  const response: AxiosResponseModel<GetGeneralAssemblyProxyMembersQueryResult[]> = await apiClient.get(`api/GeneralAssemblies/proxies/search`, {
    params: { search, naturalPersonId } 
  });
  return response.data;
};

export const getGeneralAssemblyParticipant = async (generalAssemblyId: string,naturalPersonId: string): Promise<GeneralAssemblyParticipant | null> => {
  const response: AxiosResponseModel<GeneralAssemblyParticipant> = await apiClient.get(`api/GeneralAssemblies/${generalAssemblyId}/participants/${naturalPersonId}`);
  return response.data ? response.data : null;
};

export const getGeneralAssemblyParticipantCount = async (): Promise<ParticipantsCountQuery> => {
  const response: AxiosResponseModel<ParticipantsCountQuery> = await apiClient.get(`api/GeneralAssemblies/participants/count`);
  return response.data;
};

export const declineGeneralAssemblyParticipant = async (
  generalAssemblyId: string, 
  request: object
): Promise<GeneralAssemblyRegistrationOptionMenuModel> => {
  const response: AxiosResponseModel<GeneralAssemblyRegistrationOptionMenuModel> = await apiClient.post(
    `api/GeneralAssemblies/${generalAssemblyId}/participants/declined`, 
    request, 
    { headers: { 'X-Skip-Error-Toast': 'true' } }
  );
  return response.data;
};

export const proxyGeneralAssemblyParticipant = async (
  generalAssemblyId: string, 
  request: object,
): Promise<GeneralAssemblyRegistrationOptionMenuModel> => {
  const response: AxiosResponseModel<GeneralAssemblyRegistrationOptionMenuModel> = await apiClient.post(
    `api/GeneralAssemblies/${generalAssemblyId}/participants/proxied`, 
    request, 
    { headers: { 'X-Skip-Error-Toast': 'true' } }
  );
  return response.data;
};

export const confirmGeneralAssemblyParticipantWillAttend = async (
  generalAssemblyId: string, 
  request: object
): Promise<GeneralAssemblyRegistrationOptionMenuModel> => {
  const response: AxiosResponseModel<GeneralAssemblyRegistrationOptionMenuModel> = await apiClient.post(
    `api/GeneralAssemblies/${generalAssemblyId}/participants/willattend`, 
    request, 
    { headers: { 'X-Skip-Error-Toast': 'true' } }
  );
  return response.data;
};
