export const isValidDate = (datestring : string): boolean => {
    if(!datestring)
        return false;

    var date = new Date(datestring);
    if(isNaN(date.getTime())){
        return false;
    }

    if(date.getFullYear() < 1753){
        return false;
    }
    return true;
}