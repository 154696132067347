import React, { useState } from 'react';
import Navbar from "./Navbar/Navbar";
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';

const MainLayout = ({ userDetail }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar
        onSearch={handleSearch}
        isMobile={isMobile}
        userDetail={userDetail}
      />
      <Outlet context={{ searchQuery, setSearchQuery, userDetail, isMobile}} />
    </React.Fragment>
  );
};

export default MainLayout;