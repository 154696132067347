import { useTranslation } from "react-i18next";

const OfficialTypeTableCell = ({ item, isMobile }) => {
    const { t } = useTranslation();
    
    // Handle null/undefined for item.officialTypes and nested type.type
    const officialTypesArray = item?.officialTypes || [];
    
    const officialTypes = officialTypesArray
        .map(type => 
            type?.type?.name 
                ? t(`trackphasetypes.${type.type.name}`) 
                : '-'
        )
        .filter(Boolean); // Removes empty strings if any
    
    const content = officialTypes.length > 0 ? officialTypes.join(" | ") : "-";
    
    return isMobile ? (
        <div className="pill-badge-primary">{content}</div>
    ) : (
        <td>{content}</td>
    );
};

export default OfficialTypeTableCell;