import apiClient from "../../../../api/client/apiClient";

export const getUserCompanies = async (userId) => {
  try {
    const response = await apiClient.get(`/api/Profiles/${userId}/companies`);
    for (let company of response.data) {
        const clienteleInfo = await getCompaniesOwnClientele(company.legalPersonId);
        company.isManagementCompany = clienteleInfo.isManagementCompany;
    }
    localStorage.setItem("USER_COMPANIES", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    localStorage.setItem("USER_COMPANIES", "[]");
    throw new Error(`HTTP error! ${error}`);
  }
};

export const getUserOffices = async (userId) => {
  try {
    const response = await apiClient.get(`/api/Profiles/${userId}/offices`);
    localStorage.setItem("USER_OFFICES", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    localStorage.setItem("USER_OFFICES", "[]");
    throw new Error(`HTTP error! ${error}`);
  }
};

export const getCompaniesOwnClientele = (companyId) => {
  return apiClient
    .get(`/api/companies/${companyId}/ownclientele`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const getUserMissions = async (userId) => {
  try {
    const response = await apiClient.get(`/api/Profiles/${userId}/missions`);
    return response.data;
  } catch (error) {
    throw new Error(`HTTP error! ${error}`);
  }
};
