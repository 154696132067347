import React from "react";

const LoaderSpinnerOverlay = () => {
  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default LoaderSpinnerOverlay;
