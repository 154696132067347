import NewUser from "../../../../../models/naturalPerson/NewUser"
import apiClient from "../../../../../api/client/apiClient";
import showErrorToast from "../../../../Toast/Toast";
import i18next from "i18next";

export const postNewUser = async (user) => {
    if (user instanceof (NewUser)) {
        return await apiClient
            .post(`/api/employees/create`, user);
    } else {
        showErrorToast(i18next.t('errors.addUser'));
    }
};