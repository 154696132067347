import React, { useState, useEffect, ChangeEvent } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getUserImpersonations, getNaturalPersonTrackTypes, getNaturalPersonQualifications } from '../../components/Main/Impersonation/http/HttpClientHelper';
import { useOutletContext, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { formatItaaNumber } from '../../utils/formatUtils.tsx';
import { useSessionContext } from '../../providers/SessionProvider';
import QualificationTableCell from '../../components/ELoket/MembershipInformation/QualificationTableCell';
import OfficialTypeTableCell from '../../components/ELoket/MembershipInformation/OfficialTypeTableCell';

const ITEMS_PER_PAGE = 18;

interface ImpersonationProps {
    id: string;
    itaaNumber: string;
    name: string;
    hasPortalAccess: boolean;
    isITAAEmployee: boolean;
    officialTypes: any[];
    itaaQualifications: any[];
}

interface Qualification {
    id: string;
    name: string;
}

interface TrackType {
    id: string;
    name: string;
}

const Impersonation: React.FC = () => {
    const [impersonations, setImpersonations] = useState<ImpersonationProps[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [response, setResponse] = useState<any>({});
    const { t } = useTranslation();
    const { searchQuery, userDetail, isMobile, setSearchQuery } = useOutletContext<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [ismImpersonating, setIsmImpersonating] = useState<boolean>(false);
    const { impersonate } = useSessionContext();
    const navigate = useNavigate();
    const [trackTypesFilter, setTrackTypesFilter] = useState<TrackType[]>([]);
    const [qualificationsFilter, setQualificationsFilter] = useState<Qualification[]>([]);
    const [selectedQualification, setSelectedQualification] = useState<Qualification | null>(null);
    const [selectedTrackType, setSelectedTrackType] = useState<TrackType | null>(null);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [hasFilter, setHasFilter] = useState<boolean>(false);

    useEffect(() => {
        if (!userDetail.isItaaEmployee) {
            navigate('/');
        }
    }, [userDetail, navigate]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const trackTypes = await getNaturalPersonTrackTypes();
                setTrackTypesFilter(trackTypes);

                const qualifications = await getNaturalPersonQualifications();
                setQualificationsFilter(qualifications);
            } catch (error) {
                console.error('Error fetching filters', error);
            }
        };

        fetchFilters();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const responseData = await getUserImpersonations(
                    ITEMS_PER_PAGE,
                    currentPage,
                    searchQuery,
                    selectedQualification,
                    selectedTrackType,
                    hasFilter
                );

                setImpersonations(responseData?.currentPage ?? []);
                setResponse(responseData);
                setHasFilter(showFilters);
            } catch (error) {
                setImpersonations([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, hasFilter, searchQuery, selectedQualification, selectedTrackType, showFilters, userDetail]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleImpersonate = async (selectedUserId: string) => {
        setIsmImpersonating(true);
        await impersonate(selectedUserId, userDetail.id);
        setIsmImpersonating(false);
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handleQualificationChange = (e) => {
        const selectedQualName = e.target.value;
        const selectedQual = qualificationsFilter.find(qualification => qualification.name === selectedQualName);
        setSelectedQualification(selectedQual || null);
    };

    const handleTrackTypeChange = (e) => {
        const selectedTrackName = e.target.value;
        const selectedTrack = trackTypesFilter.find(track => track.name === selectedTrackName);
        setSelectedTrackType(selectedTrack || null);
    };

    return (
        <div id="table" className="container">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div className='row'>
                {isMobile && (
                    <div className='search-bar'>
                        <input
                            className="form-control"
                            placeholder={t('navbar.search')}
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                )}
            </div>

            <div className="d-flex justify-content-end mb-3 filter-button-wrapper">
                <Button
                    className="btn-eloket"
                    variant="secondary" onClick={() => setShowFilters(!showFilters)}>
                    {t('impersonation.filterButton')}
                </Button>
            </div>

            {showFilters && (
                <div className="row my-3 filter-section">
                    <div className="col-md-6 filter-item">
                        <Form.Group controlId="qualificationFilter">
                            <Form.Label>{t('impersonation.filterQualification')}</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedQualification?.name ?? ''}
                                onChange={handleQualificationChange}
                            >
                                <option value="">{t('impersonation.select')}</option>
                                {qualificationsFilter?.map((qualification) => (
                                    <option key={qualification.id} value={qualification.name}>
                                        {t(`qualifications.${qualification.name}`)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 filter-item">
                        <Form.Group controlId="trackTypeFilter">
                            <Form.Label>{t('impersonation.filterTrackType')}</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedTrackType?.name ?? ''}
                                onChange={handleTrackTypeChange}
                            >
                                <option value="">{t('impersonation.select')}</option>
                                {trackTypesFilter?.map((trackType) => (
                                    <option key={trackType?.id} value={trackType.name}>
                                        {t(`trackphasetypes.${trackType.name}`)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
            )}

            <Table responsive>
                <thead>
                    <tr>
                        <th>{t('impersonation.itaaNumber')}</th>
                        <th>{t('impersonation.name')}</th>
                        {!isMobile && (
                            <>
                                <th>{t('impersonation.accessPortal')}</th>
                                <th>{t('impersonation.isEmployee')}</th>
                                <th>{t('impersonation.itaaQualification')}</th>
                                <th>{t('impersonation.officialType')}</th>
                            </>
                        )}
                        <th className='lastColumn'>{t('impersonation.action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {impersonations.map((item :ImpersonationProps) => (
                        <tr key={item.id}>
                            <td>{formatItaaNumber(item.itaaNumber)}</td>
                            <td>{item.name}</td>
                            <td>{item.hasPortalAccess ? t('impersonation.yes') : t('impersonation.no')}</td>
                            <td>{item.isITAAEmployee || item.officialTypes.length === 0 ? t('impersonation.yes') : t('impersonation.no')}</td>
                            {!isMobile && (
                                <>
                                    <QualificationTableCell item={item} isMobile={isMobile} />
                                    <OfficialTypeTableCell item={item} isMobile={isMobile} />
                                </>
                            )}
                            <td className='lastColumn'>
                                <Button className="btn btn-home" variant="primary" onClick={() => handleImpersonate(item.id)}>
                                    {t('impersonation.detail')}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="d-flex justify-content-center my-3">
                <span>{t('impersonation.page')} {currentPage}</span>
            </div>
            <Pagination className="justify-content-center">
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    {t('impersonation.previous')}
                </Pagination.Prev>
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!response.hasNextPage}
                >
                    {t('impersonation.next')}
                </Pagination.Next>
            </Pagination>
        </div>
    );
};

export default Impersonation;
