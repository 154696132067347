import React from "react";
import { useState, useEffect } from "react";

interface CountdownTextProps {
  text: string;
  initialSeconds: number;
  onFinish: (finished: boolean) => void;
  placeholder: string;
}

const CountdownTextComponent: React.FC<CountdownTextProps> = ({ text, initialSeconds, onFinish, placeholder }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds <= 0) {
      onFinish(true);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, onFinish]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const secs = Math.ceil(time % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const placeholderText = `{{${placeholder}}}`;
  const renderedText = text.replace(placeholderText, formatTime(seconds));

  return <section className="mt-2" dangerouslySetInnerHTML={{ __html: renderedText }}/>;
};

export default CountdownTextComponent;
