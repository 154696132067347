import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { getCompanyDetails, patchCompanyDetails } from "../../components/ELoket/Company/BasicData/http/HttpClientHelper";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import LegalPersonInfoHeader from "../../components/ELoket/LegalPersonInfoHeader/LegalPersonInfoHeader";
import { ValidateEmail } from "../../utils/validationUtils";
import Validation from "../../components/Validation/Validation";
import { LegalPersonTypeEnum } from "../../Enums/LegalPersonTypeEnum";

const BasicData = () => {
    const { userDetail } = useOutletContext();
    const { t } = useTranslation();
    const [companyDetails, setCompanyDetails] = useState([]);
    const location = useLocation();
    const pathParts = location.pathname.split("/");
    const { legalPersonId } = useParams();
    const [loading, setLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errors, setErrors] = useState({});

    const [isSubmitted, setIsSubmited] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const companydetail = await getCompanyDetails(legalPersonId);
            companydetail.generalEmailCommunicationId = companydetail.communications.find(
                communication => communication.communicationType.name === "Email"
            )?.id || '00000000-0000-0000-0000-000000000000';
            companydetail.generalEmail = companydetail.communications.find(
                communication => communication.communicationType.name === "Email"
            )?.value || '';
            companydetail.registeredOfficeTelephoneCommunicationId = companydetail.communications.find(
                communication => communication.communicationType.name === "Phonenumber"
            )?.id || '00000000-0000-0000-0000-000000000000';
            companydetail.registeredOfficeTelephone = companydetail.communications.find(
                communication => communication.communicationType.name === "Phonenumber"
            )?.value || '';
            companydetail.generalWebsiteCommunicationId = companydetail.communications.find(
                communication => communication.communicationType.name === "Website"
            )?.id || '00000000-0000-0000-0000-000000000000';
            companydetail.generalWebsite = companydetail.communications.find(
                communication => communication.communicationType.name === "Website"
            )?.value || '';

            setCompanyDetails(companydetail);
            setLoading(false);
        })();
    }, [legalPersonId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCompanyDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
        setIsSubmited(true);
    };

    const patchBasicData = async () => {
        setLoading(true);
        const legalPersonId = pathParts[pathParts.length - 1];
        await patchCompanyDetails(legalPersonId, companyDetails);
        setIsSuccess(true);
        setIsConfirmModalVisible(false)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = {};
        ValidateEmail(companyDetails.generalEmail) || (validationErrors.email = t('validation.emailFormat'));
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await patchBasicData().finally(() => {
            setLoading(false);
        });
    };

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("mandate_application.modal_success_title")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t("popup.successUpdate")}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}
                        >
                            {t("mandate_application.modal_success_close")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Company}
                />
                <div className="form-row row">
                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        {/* Form 1 */}
                        <form
                            action="#"
                            className="form-overview form-detect-unsaved"
                            id="form"
                            method="post"
                            onSubmit={handleSubmit}
                        >
                            <div className="text-h1">{t("basicdata.general")}</div>
                            <div className="form-group">
                                <div className="form-row row">
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control detect-unsaved-ignore"
                                                type="text"
                                                name="generalEmail"
                                                value={companyDetails?.generalEmail || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label
                                                className="control-label required"
                                                htmlFor="GeneralEdit_Email"
                                            >
                                                {t("basicdata.email")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control detect-unsaved-ignore"
                                                type="text"
                                                name="generalWebsite"
                                                value={companyDetails?.generalWebsite || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="GeneralEdit_Website"
                                            >
                                                {t("basicdata.website")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control read-only-input"
                                                type="text"
                                                name="generalCompanyNumber"
                                                value={companyDetails?.generalCompanyNumber || ""}
                                                disabled
                                                onChange={handleInputChange}
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="GeneralEdit_CompanyNumber"
                                            >
                                                {t("basicdata.companyNumber")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <Validation errors={errors} />
                            </div>
                        </form>
                    </div>
                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        {/* Form 2 */}
                        <form
                            action="#"
                            className="form-overview form-detect-unsaved"
                            id="form"
                            method="post"
                            onSubmit={handleSubmit}
                        >
                            <div className="text-h1">{t("basicdata.registeredOffice")}</div>
                            <div className="form-group">
                                <div className="form-row row">
                                    <b className="mb-3">{t("basicdata.registeredOfficeInformation")}</b>
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control read-only-input"
                                                type="text"
                                                name="registeredOfficeStreet"
                                                value={companyDetails?.registeredOfficeStreet || ""}
                                                disabled
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="HeadquarterAddress_Street"
                                            >
                                                {t("basicdata.street")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control read-only-input"
                                                type="text"
                                                name="registeredOfficeCountry"
                                                value={companyDetails?.registeredOfficeCountry || ""}
                                                disabled
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="HeadquarterAddress_CountryName"
                                            >
                                                {t("basicdata.country")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control read-only-input"
                                                type="text"
                                                name="registeredOfficePostalCode"
                                                value={companyDetails?.registeredOfficePostalCode || ""}
                                                disabled
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="HeadquarterAddress_PostalCode"
                                            >
                                                {t("basicdata.postalCode")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control read-only-input"
                                                type="text"
                                                name="registeredOfficeCity"
                                                value={companyDetails?.registeredOfficeCity || ""}
                                                disabled
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="HeadquarterAddress_CityName"
                                            >
                                                {t("basicdata.place")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="form-col col-md-12 col-lg-6">
                                        <div className="controls">
                                            <input
                                                className="form-control detect-unsaved-ignore"
                                                type="text"
                                                name="registeredOfficeTelephone"
                                                value={companyDetails?.registeredOfficeTelephone || ""}
                                                onChange={handleInputChange}
                                            />
                                            <label
                                                className="control-label"
                                                htmlFor="HeadquarterAddress_Telephone"
                                            >
                                                {t("basicdata.telephoneNumber")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-col col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        {/* Form 3 */}
                        <form
                            action="#"
                            className="form-overview form-detect-unsaved"
                            id="form"
                            method="post"
                            onSubmit={handleSubmit}
                        >
                            <footer>
                                <span>
                                    <div className="button-save-warning-container">
                                        <button className="btn btn-eloket" type="submit" disabled={!isSubmitted}>
                                            <i className="fa fa-check mr-2"></i> {t("popup.save")}
                                        </button>
                                        {isSubmitted && <div className="save-warning"> {t("basicdata.notSaved")} </div>}
                                    </div>
                                </span>
                            </footer>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicData;
