import apiClient from "../../../../api/client/apiClient";

export const getOffice = async (legalPersonId) => {
  const response = await apiClient
    .get(`/api/offices/${legalPersonId}/detail`);
  return response.data;
};

export const getMyDetails = async (legalPersonId) => {
    const response = await apiClient
      .get(`/api/companies/${legalPersonId}/ownclientele`);

    return response.data;
};


export const patchMyDetails = async (legalPersonId, detailsToUpdate) => {
    await apiClient
      .patch(`/api/companies/${legalPersonId}/ownclientele`, detailsToUpdate);
 
};

export const getUserOffices = async (userId) => {
    const response = await apiClient.get(`/api/Profiles/${userId}/offices`);
    localStorage.setItem("USER_OFFICES", JSON.stringify(response.data));
    return response.data;
};