import { NaturalPersonMembershipCurrentTrack } from "./NaturalPersonMembershipCurrentTrackModel";
import { NaturalPersonMembershipQualification } from "./NaturalPersonMembershipQualification";
import { OfficialType } from "../Enums/OfficialType.tsx";
import { EnumResponse } from "./EnumResponse.tsx";
import { QualificationsEnum } from "../Enums/QualificationsEnum.tsx";

export class CurrentUserModel {
  id: string;
  membershipId: string;
  firstName: string;
  lastName: string;
  gender: string;
  itaaNumber: number;
  language: string;
  dateOfBirth?: Date;
  birthPlace?: string;
  nationality?: string;
  nationalRegistrationNumber?: string;
  officialTypes: EnumResponse<OfficialType>[] = [];
  qualifications: NaturalPersonMembershipQualification[] = [];
  vatNumberAsNaturalPerson: string;
  verifiedDate?: Date;
  isItaaEmployee: boolean;
  isImpersonated: boolean;
  isItaaIT: boolean;
  isItaaSRD: boolean;
  email?: string;
  tracks: NaturalPersonMembershipCurrentTrack[] = [];
  vatNumber?: string;


  hasOfficialType(...types: OfficialType[]): boolean {
    return this.officialTypes.some((officialType) => 
        types.includes(officialType.id)
    );
  }

  hasNoActiveTracks(): boolean {
    return !this.officialTypes || this.officialTypes.length === 0;
  }

  hasExternalQualification(): boolean {
      return this.qualifications?.some(type => 
        QUALIFICATION_CATEGORIES[type.qualification.id] === 'External'
    ) ?? false;}

  isHonoraryMember(): boolean {
    return this.hasOfficialType(OfficialType.HonoraryMember);
  }
  isExternalMember(): boolean {
    return this.hasOfficialType(OfficialType.ExternalMember);
  }
  isInternalMember(): boolean {
    return this.hasOfficialType(OfficialType.InternalMember);
  }
  isExternalType(): boolean {
    return this.hasOfficialType(OfficialType.ExternalMember, OfficialType.ExternalIntern);
  }
  isInternalType(): boolean {
    return this.hasOfficialType(OfficialType.InternalMember, OfficialType.InternalIntern);
  }
  isIntern(): boolean {
    return this.hasOfficialType(OfficialType.InternalIntern, OfficialType.ExternalIntern);
  }
}

const QUALIFICATION_CATEGORIES = Object.fromEntries(
  Object.keys(QualificationsEnum).map((key) => [
      QualificationsEnum[key],
      key.startsWith('Internal') || key.startsWith('TraineeInternal') ? 'Internal' : 'External',
  ])
);
