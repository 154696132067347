import React, { useState, useEffect } from 'react';
import { getOfficeComposition, patchOfficeComposition, deletePersonOfficeComposition } from './http/HttpClientHelper';
import { getOffice } from '../BeExcellent/http/HttpClientHelper';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Add from './add/Add';
import { Modal, Button } from 'react-bootstrap';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';
import { getOfficeRoles } from '../../Profile/OfficeRoles/http/HttpClientHelper';
import { OfficeRoleEnum } from '../../../../Enums/OfficeRoleEnum';

const OfficeComposition = ({ userDetail, isMobile }) => {
    const { t } = useTranslation();
    const { legalPersonId } = useParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [officeDetails, setOfficeDetails] = useState(null);
    const [officeComposition, setOfficeComposition] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [isSumbited, setIsSubmited] = useState(false)
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [currentUserIsMainContactOrCoAdmin, setCurrentUserIsMainContactOrCoAdmin] = useState(false);

    useEffect(() => {
        const fetchOfficeDetails = async () => {
            const data = await getOffice(legalPersonId);
            setOfficeDetails(data);
        };
        fetchOfficeDetails();
    }, [legalPersonId]);

    useEffect(() => {
        const fetchOfficeComposition = async () => {
            setLoading(true);
            const data = await getOfficeComposition(legalPersonId);
            setOfficeComposition(data);

            const officeRoles = await getOfficeRoles(userDetail.id);

            const isMainContactPerson = officeRoles.some(role =>
                (role.role === OfficeRoleEnum.MainContactPerson || role.role === OfficeRoleEnum.CoAdministrator) && role.company.id === legalPersonId
            );

            setCurrentUserIsMainContactOrCoAdmin(isMainContactPerson);
            setShowWarning(!isMainContactPerson);
            setLoading(false);
        };

        fetchOfficeComposition().finally(() => {
            setLoading(false);
        });
    }, [legalPersonId, userDetail]);


    if (!officeDetails || !officeComposition) {
        return (
            <div id="main">
                {loading && (
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                )}
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Office}
                />
                <div id="content">
                    <div className="text-h1">{t('office_composition.title')}</div>
                    <div className="form-group">
                        <div className="form-row">
                            <p className="text-description">{t('office_composition.not_available')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const { externalMembers, internalMembers, externalInternships, internalInternschips, ibrMembers, naturalPersons, total } = officeComposition;

    const patchCompositionOffice = async () => {
        setLoading(true);
        await patchOfficeComposition(legalPersonId, officeComposition);
        const data = await getOffice(legalPersonId);
        setOfficeDetails(data);
        setIsSuccess(true);
        setLoading(false);
        setIsSubmited(false);
    };

    const handleSave = () => {
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await patchCompositionOffice();
    };

    const handleInputChange = (event) => {
        setIsSubmited(true);
        setOfficeComposition(prevDetails => ({ ...prevDetails, [event.target.name]: event.target.value }));
    };

    const filteredOfficeStaff = officeComposition.officeStaff.filter((manager) =>
        manager.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDelete = async (selectedPersonId) => {
        setLoading(true);
        await deletePersonOfficeComposition(legalPersonId, selectedPersonId);
        const data = await getOfficeComposition(legalPersonId);
        setOfficeComposition(data);
        setLoading(false);
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };
    const inputClassName = currentUserIsMainContactOrCoAdmin ? "" : "read-only-input";

    const hiddenBadges = [
        "ExternalMember",
        "InternalMember",
        "InternalIntern",
        "ExternalIntern",
        // Add other badges you want to hide
    ];

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        {t("confirmation.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                        {t("confirmation.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Office}
                />
                <div className="text-h1">{t('office_composition.title')}</div>
                <div className="form-group">
                    {showWarning && (
                        <div className='form-row'>
                            <div className='alert alert-danger mb-0'>
                                <span>{t('office_composition.warningMainContact')}</span>
                            </div>
                        </div>
                    )}
                    <div className="form-row">
                        <p className="text-description">{t('office_composition.explanation')}</p>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <div className='controls'>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <label className='control-label'>{t('office_composition.search')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('office_composition.header_name')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOfficeStaff
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((manager, index) => (
                                        <tr key={index}>
                                            <td className="name-col">{manager.name}</td>
                                            <td className="badges-office-composition">
                                                {manager.itaaQualifications &&
                                                    manager.itaaQualifications.length > 0 &&
                                                    manager.itaaQualifications
                                                        .filter((qualification) => {
                                                            return !hiddenBadges.includes(qualification.name);
                                                        })
                                                        .map((qualification) => (
                                                            <div key={qualification.id} className="pill-badge primary">
                                                                {t(`qualifications.${qualification.name}`)}
                                                            </div>
                                                        ))}

                                                {manager.officialTypes &&
                                                    manager.officialTypes.length > 0 &&
                                                    manager.officialTypes
                                                        .filter((type) => {
                                                            return !hiddenBadges.includes(type.name);
                                                        })
                                                        .map((type) => (
                                                            <div key={type.id} className="pill-badge primary">
                                                                {t(`trackphasetypes.${type.name}`)}
                                                            </div>
                                                        ))}
                                            </td>
                                            <td className="lastColumn">
                                                {!showWarning && (
                                                    <button
                                                        className="btn btn-eloket"
                                                        type="button"
                                                        onClick={() => handleDelete(manager.naturalPersonId)}
                                                    >
                                                        {!isMobile ? (
                                                            <>{t('access_tools.button_delete')}</>
                                                        ) : (
                                                            <i className="fa fa-trash"></i>
                                                        )}
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="text-h1">
                    {t('office_composition.title_amounts')}
                </div>
                <div className="form-group">
                    <div className="form-header">
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    <input className="read-only-input" value={externalMembers} readOnly />
                                    <label className="control-label">{t('office_composition.amount_one')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    {!showWarning ? (
                                        <input
                                            defaultValue={internalMembers}
                                            name="internalMembers"
                                            type="number"
                                            min="0"
                                            pattern="[0-9]"
                                            onChange={handleInputChange}
                                            className={inputClassName}
                                        />
                                    ) : (
                                        <input className="read-only-input" value={internalMembers} readOnly />
                                    )}
                                    <label className="control-label">{t('office_composition.amount_two')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    <input className="read-only-input" value={externalInternships} readOnly />
                                    <label className="control-label">{t('office_composition.amount_three')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    {!showWarning ? (
                                        <input
                                            defaultValue={internalInternschips}
                                            name="internalInternschips"
                                            type="number"
                                            min="0"
                                            pattern="[0-9]"
                                            onChange={handleInputChange}
                                            className={inputClassName}
                                        />
                                    ) : (
                                        <input className="read-only-input" value={internalInternschips} readOnly />
                                    )}
                                    <label className="control-label">{t('office_composition.amount_four')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    {!showWarning ? (
                                        <input
                                            defaultValue={ibrMembers}
                                            name="ibrMembers"
                                            type="number"
                                            min="0"
                                            pattern="[0-9]"
                                            onChange={handleInputChange}
                                            className={inputClassName}
                                        />
                                    ) : (
                                        <input className="read-only-input" value={ibrMembers} readOnly />
                                    )}
                                    <label className="control-label">{t('office_composition.amount_five')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    {!showWarning ? (
                                        <input
                                            defaultValue={naturalPersons}
                                            name="naturalPersons"
                                            type="number"
                                            min="0"
                                            pattern="[0-9]"
                                            onChange={handleInputChange}
                                            className={inputClassName}
                                        />
                                    ) : (
                                        <input className="read-only-input" value={naturalPersons} readOnly />
                                    )}
                                    <label className="control-label">{t('office_composition.amount_six')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className='form-col col-md-12 col-lg-9'>
                                <div className='controls'>
                                    <input className="read-only-input" value={total} readOnly />
                                    <label className="control-label">{t('office_composition.total')}</label>
                                </div>
                            </div>
                        </div>


                        {showAddModal && (
                            <Add
                                show={showAddModal}
                                handleClose={() => setShowAddModal(false)}
                                legalPersonId={legalPersonId}
                            />
                        )}
                    </div>
                </div>
                {(currentUserIsMainContactOrCoAdmin) && (
                    <footer>
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="submit" onClick={handleSave} disabled={!isSumbited}>{t('popup.save')}</button>
                            <button className="btn btn-eloket" type="submit" disabled={isSumbited} onClick={() => setShowAddModal(true)}>{t('office_composition.add')}</button>
                            {isSumbited && <div className="save-warning"> {t("basicdata.notSaved")} </div>}
                        </div>
                    </footer>
                )}
            </div>
        </div>
    );
};

export default OfficeComposition;