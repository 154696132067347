export default {
  nl: {
    success: {
      title: "GELUKT",
      body: "De gegevens werden succesvol bijgewerkt.",
      close: "Sluiten",
    },
    cancel: "Annuleren",
    save: "Opslaan",
    confirm: "Bevestigen",
    successUpdate: "De gegevens werden succesvol bijgewerkt",
    verifyChange: "Bent u zeker dat u deze gegevens wilt bewerken?",
    verifyChangeTitle: "De gegevens bewerken",
    confirmOnSaveCheck:"Ik heb kennis genomen van bovenstaande verplichting.",
    compatibleBrowserWarning: "<b>Let op!</b> De applicatie is enkel beschikbaar in de browsers Chrome, Edge en Firefox. Aanvaard ook steeds het verzoek om pop-ups toe te staan.",
    questions: "Voor bijkomende vragen kan u contact opnemen met <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
  },
  fr: {
    success: {
      title: "SUCCÈS",
      body: "Les données ont été mises à jour avec succès",
      close: "Fermer",
    },
    cancel: "Annuler",
    save: "Sauvegarder",
    confirm: "Confirmer",
    successUpdate: "Les données ont été mises à jour avec succès",
    verifyChange: "Êtes-vous sûr de vouloir mettre à jour ces données ?",
    verifyChangeTitle: "Mettre à jour les données",
    confirmOnSaveCheck:"J’ai pris note de l’obligation susmentionnée.",
    compatibleBrowserWarning: "<b>Attention!</b> L'application est uniquement disponible dans les navigateurs Chrome, Edge et Firefox. Accepter toujours la demande pour autoriser les pop-ups.",
    questions: "Si vous avez des questions supplémentaires, prenez contact avec <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
  },
};
