export default {
    nl: {
        session_expired_title: "Sessie verlopen",
        session_expired_body: "Je sessie is verlopen. Log opnieuw in om verder te gaan.",
        login_again: "Opnieuw inloggen",
        loading_profile_title: "Profiel wordt geladen",
        loading_profile_body: "We zijn bezig met het laden van je profiel.",
        loading_impersonation_title: "Impersonatie wordt geladen",
        loading_impersonation_body: "We zijn bezig met het laden van de impersonatie.",
        stopping_impersonation_title: "Impersonatie wordt gestopt",
        stopping_impersonation_body: "We stoppen de impersonatie.",
    },
    fr: {
        session_expired_title: "Session expirée",
        session_expired_body: "Votre session a expiré. Veuillez vous reconnecter pour continuer.",
        login_again: "Se reconnecter",
        loading_profile_title: "Chargement du profil",
        loading_profile_body: "Nous chargeons votre profil.",
        loading_impersonation_title: "Chargement de l'usurpation",
        loading_impersonation_body: "Nous chargeons l'usurpation.",
        stopping_impersonation_title: "Arrêt de l'usurpation",
        stopping_impersonation_body: "L'usurpation est en cours d'arrêt.",
    },
};
