import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AutocompleteResults from '../AutoComplete/AutoComplete';
import { postAntiMoneyLaunderingRolesData } from '../http/HttpClientHelper';
import AddNewEmployee from '../../NewEmployee/AddNewEmployee';
import { AMLFunctionEnum } from '../../../../../Enums/AMLFunctionEnum';

const Add = ({ show, handleClose, legalPersonId, antiMoneyLaunderingRolesData }) => {
    const { t } = useTranslation();
    const [selectedName, setSelectedName] = useState('');
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [errorValidation, setErrorValidation] = useState('');

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
        setIsClickedResult(false);
        setErrorValidation('');
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const validateForm = () => {
        if (!selectedName.trim()) {
            setErrorValidation(t('aml_officers.error_name_required'));
            return false;
        }
        if (!selectedRole) {
            setErrorValidation(t('aml_officers.error_role_required'));
            return false;
        }
        if (!selectedPerson) {
            setErrorValidation(t('aml_officers.error_person_required'));
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorValidation('');

        if (!validateForm()) return;

        setLoading(true);

        const idExists = antiMoneyLaunderingRolesData.some(item => item.naturalPersonId === selectedPerson.naturalPersonId);
        const roleExists = antiMoneyLaunderingRolesData.some(item => item.role === selectedRole);

        if (!idExists && !roleExists || !roleExists) {
            await postAntiMoneyLaunderingRolesData(legalPersonId, selectedPerson.naturalPersonId, selectedRole);
            setIsSuccess(true);
        } else {
            setErrorValidation(t('aml_officers.error_person_role_assignment'));
        }

        setLoading(false);
    };

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedPerson(selectedResult);
        setSelectedName(selectedResult.fullName);
        setIsClickedResult(true);
        setErrorValidation('');
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsClickedResult(true);
        }, 100);
    };

    const addEmployeePopup = () => {
        setShowAddNew(true);
    };

    const handleCloseAddNew = () => {
        setShowAddNew(false);
    };

    useEffect(() => {
        const link = document.getElementById('add-employee-link');
        if (link) {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                addEmployeePopup();
            });
        }
        return () => {
            if (link) {
                link.removeEventListener('click', addEmployeePopup);
            }
        };
    }, []);

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess ? (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <>
                    {showAddNew ? (
                        <AddNewEmployee show={showAddNew} handleClose={handleCloseAddNew} />
                    ) : (
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('aml_officers.modal_title')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formNetworkRole">
                                        <Form.Label>
                                            {t('aml_officers.modal_role_description')} <span style={{ color: 'red' }}>*</span>
                                        </Form.Label>
                                        <Form.Select
                                            value={selectedRole}
                                            onChange={handleRoleChange}
                                            onBlur={handleBlur}
                                            required
                                        >
                                            <option value="" disabled>{t('aml_officers.select_function')}</option>
                                            <option value={AMLFunctionEnum.VPHN}>{t('aml_officers.AML_VPHN')}</option>
                                            <option value={AMLFunctionEnum.AMLCO}>{t('aml_officers.AML_AMLCO')}</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group controlId="formNetworkName">
                                        <Form.Label>
                                            {t('aml_officers.modal_input_description')} <span style={{ color: 'red' }}>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedName}
                                            onChange={handleNameChange}
                                            onBlur={handleBlur}
                                            autoComplete='off'
                                            required
                                        />
                                        {selectedName.trim().length >= 3 && !isClickedResult && (
                                            <AutocompleteResults
                                                inputValue={selectedName}
                                                handleSelection={handleAutocompleteSelection}
                                                legalPersonId={legalPersonId}
                                                selectedRole={selectedRole}
                                            />
                                        )}
                                    </Form.Group>
                                    {errorValidation && (
                                        <div className="alert alert-danger">
                                            {errorValidation}
                                        </div>
                                    )}
                                    <br />
                                    <p dangerouslySetInnerHTML={{ __html: t('aml_officers.select_employee') }}></p>
                                    <p dangerouslySetInnerHTML={{ __html: t('aml_officers.new_employee') }}></p>
                                    <Modal.Footer className="justify-content-between">
                                        <Button variant="secondary" onClick={handleClose}>
                                            {t('popup.cancel')}
                                        </Button>
                                        <Button
                                            className="btn-eloket"
                                            variant="secondary"
                                            type="submit"
                                            disabled={!selectedName.trim()}
                                        >
                                            {t('office_composition.modal_add')}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default Add;