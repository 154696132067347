import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getNaturalPersonCertificateData } from '../Certificate/http/HttpClientHelper';
import { saveAs } from 'file-saver';

const CertificateDownloadModal = ({ showModal, handleClose, tracks, userDetail }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedTrackId, setSelectedTrackId] = useState(null);

    const handleTrackClick = (trackId) => {
        setSelectedTrackId(trackId);
    };

    useEffect(() => {
        if(tracks.length === 1){
            setSelectedTrackId(tracks[0].trackId);
        }
    }, [])

    const generateCertificate = async () => {
        setLoading(true);
        const data = await getNaturalPersonCertificateData(userDetail?.id, selectedTrackId);
        var binaryString = window.atob(data.pdf_certificate);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        const file = new Blob([bytes], { type: "application/pdf" });
        saveAs(file, t('personaldata.certificateDownloadName'));
        setLoading(false);
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <Modal.Header closeButton>
                <Modal.Title>{t('itaadata.certificate.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">
                    {tracks?.filter(track => track.currentPhase.qualificationId).map((track) => (
                        <li
                            key={track.id}
                            className={`list-group-item list-group-item-action ${selectedTrackId === track.id ? 'selected' : ''}`}
                            onClick={() => handleTrackClick(track.id)}
                        >
                            {t(`qualifications.${track?.type?.name}`)}
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={generateCertificate} disabled={!selectedTrackId}>
                    {t('itaadata.certificate.download')}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    {t('popup.cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CertificateDownloadModal;
