import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoaderSpinnerOverlay from '../loader/LoaderSpinnerOverlay';

interface ConfirmationModalProps {
    showConfirmationModal: boolean;
    handleCloseConfirmationModal: () => void;
    sendFormClick: () => Promise<void>;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    showConfirmationModal,
    handleCloseConfirmationModal,
    sendFormClick,
}) => {
    const { t } = useTranslation()
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleConfirmClick = async () => {
        setLoading(true);
        try {
            await sendFormClick();
            setShowSuccess(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSuccess = () => {
        setShowSuccess(false);
        window.location.reload();
    };

    return (
        <>
            {loading && (
                <LoaderSpinnerOverlay />
            )}

            <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("popup.verifyChangeTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                        {t("popup.cancel")}
                    </Button>
                    <Button variant="primary" className="btn-eloket" onClick={handleConfirmClick}>
                        {t("popup.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            {showSuccess && (
                <Modal show={showSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('popup.success.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.success.body')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="secondary" className="btn-eloket" onClick={handleCloseSuccess}>
                            {t('popup.success.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ConfirmationModal;