import React, { useState, useEffect } from "react";
import { getProfileDetails } from '../../../api/profileApi.tsx';
import { useTranslation } from "react-i18next";
import NaturalPersonInfoHeader from "../../../components/ELoket/NaturalPersonInfoHeader/NaturalPersonInfoHeader.tsx";
import { CurrentUserModel } from "../../../models/CurrentUserModel.tsx";
import { ProfileDetailsQueryResult, NaturalPersonMembershipInsurance } from "../../../models/MyDetails.tsx";
import LoaderSpinnerOverlay from "../../../components/loader/LoaderSpinnerOverlay.jsx";
import AddInsuranceModal from "../../../components/ELoket/Insurance/AddInsuranceModal.tsx";
import { PersonType } from "../../../Enums/PersonType.tsx";
import EditInsuranceModal from "../../../components/ELoket/Insurance/EditInsuranceModal.tsx";
import InsuranceTable from "../../../components/ELoket/Insurance/InsurancesTable.tsx";
import { MembershipInsurance } from "../../../models/MembershipInsurance.tsx";

const Insurance: React.FC<{ userDetail: CurrentUserModel, }> = ({ userDetail }) => {
    const { t } = useTranslation();
    const [myDetails, setMyDetails] = useState<Partial<ProfileDetailsQueryResult>>({});
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editInsurance, setEditInsurance] = useState<NaturalPersonMembershipInsurance | null>(null);
    const [naturalPersonMembershipInsurance] = useState<Partial<NaturalPersonMembershipInsurance>>({});

    const handleEditClick = (insurance: MembershipInsurance) => {
        setEditInsurance(insurance as NaturalPersonMembershipInsurance);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const details = await getProfileDetails(userDetail.id);
            const updatedDetails = {
                ...details,
                insurances: details.insurances || [],
            };
            setMyDetails(updatedDetails);
            setLoading(false);
        })();
    }, [userDetail]);

    return (
        <div id="main">
            {loading && (
                <LoaderSpinnerOverlay />
            )}

            {showAddModal && (
                <AddInsuranceModal
                    personId={userDetail.id}
                    personType={PersonType.NaturalPerson}
                    membershipId={userDetail.membershipId}
                    initialFormData={naturalPersonMembershipInsurance}
                    show={showAddModal}
                    handleClose={() => setShowAddModal(false)}
                />
            )}

            {editInsurance && (
                <EditInsuranceModal
                    personId={userDetail.id}
                    personType={PersonType.NaturalPerson}
                    membershipId={userDetail.membershipId}
                    initialFormData={editInsurance}
                    show={editInsurance}
                    handleClose={() => setEditInsurance(null)}
                />
            )}

            <div id="content">
                <NaturalPersonInfoHeader
                    userDetail={userDetail}
                />

                <InsuranceTable
                    insurances={myDetails?.insurances}
                    handleEditClick={handleEditClick}
                />
            </div>
            <footer>
                <span>
                    <div className="button-save-warning-container">
                        <span onClick={() => setShowAddModal(true)}>
                            <button className="btn btn-eloket">
                                <span className="fa fa-plus mr-2"></span> {t('insurance.addInsurance')}
                            </button>
                        </span>
                    </div>
                </span>
            </footer>
        </div>
    );
};

export default Insurance;
