import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { ExamTypeEnum } from '../../../../Enums/ExamEnumTypes.tsx';
import { useOutletContext } from "react-router-dom";
import GeneralAssemblyDocumentation from "../../LandingPage/GeneralAssembly/GeneralAssemblyDocumentation.tsx";

const LandingPageData = ({ loading, userVisibilities, handleCardClick }) => {
    const { t, i18n } = useTranslation();
    const {  isMobile } = useOutletContext();
    
    const cardData = [
        {
            id: 1,
            title: t('landingpage.eloket.title'),
            subtitle: t('landingpage.eloket.subtitle'),
            link: t('landingpage.eloket.link'),
            key: 'isVisibleEloket',
            openInNewTab: false,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.eloket.faqLink'),
        },
        {
            id: 3,
            title: t('landingpage.generalAssembly.registration.title'),
            subtitle: t('landingpage.generalAssembly.registration.subtitle'),
            link: '#', // Empty
            key: 'isVisibleGeneralAssemblyRegistration',
            openInNewTab: false,
            openInModal: true,
            isITAABlock: false,
        },
        {
            id: 3,
            title: t('landingpage.generalAssembly.documentation.title'),
            subtitle: t('landingpage.generalAssembly.documentation.subtitle'),
            link: '#', // Empty
            key: 'isVisibleGeneralAssemblyDocumentation',
            openInNewTab: false,
            openInModal: true,
            isITAABlock: false,
        },
        {
            id: 4,
            title: t('landingpage.forms.title'),
            subtitle: t('landingpage.forms.subtitle'),
            link: t('landingpage.forms.link'),
            key: 'isVisibleForms',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.forms.faqLink'),
        },
        {
            id: 5,
            title: t('landingpage.internshipBook.title'),
            subtitle: t('landingpage.internshipBook.subtitle'),
            link: t('landingpage.internshipBook.link'),
            key: 'isVisibleInternshipBook',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.internshipBook.faqLink'),
        },
        {
            id: 6,
            title: t('landingpage.entranceExam.title'),
            subtitle: t('landingpage.entranceExam.subtitle'),
            link: t('landingpage.entranceExam.link'),
            key: 'isVisibleEntranceExam',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: i18n.t('landingpage.entranceExam.faqLink'),
            examType: ExamTypeEnum.ENTRANCE,
        },
        {
            id: 7,
            title: t('landingpage.intermediateExam.title'),
            subtitle: t('landingpage.intermediateExam.subtitle'),
            link: t('landingpage.intermediateExam.link'),
            key: 'isVisibleIntermediateExam',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.intermediateExam.faqLink'),
            examType: ExamTypeEnum.INTERMEDIATE,
        },
        {
            id: 8,
            title: t('landingpage.intermediateExam2.title'),
            subtitle: t('landingpage.intermediateExam2.subtitle'),
            link: t('landingpage.intermediateExam2.link'),
            key: 'isVisibleIntermediateExam2',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.intermediateExam2.faqLink'),
            examType: ExamTypeEnum.INTERMEDIATE2,
        },
        {
            id: 9,
            title: t('landingpage.transferExam.title'),
            subtitle: t('landingpage.transferExam.subtitle'),
            link: t('landingpage.transferExam.link'),
            key: 'isVisibleTransferExam',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.transferExam.faqLink'),
            examType: ExamTypeEnum.TRANSFER,
        },
        {
            id: 10,
            title: t('landingpage.backpackExam.title'),
            subtitle: t('landingpage.backpackExam.subtitle'),
            link: t('landingpage.backpackExam.link'),
            key: 'isVisibleBackpackExam',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.backpackExam.faqLink'),
            examType: ExamTypeEnum.BACKPACK,
        },
        {
            id: 11,
            title: t('landingpage.finalExam.title'),
            subtitle: t('landingpage.finalExam.subtitle'),
            link:t('landingpage.finalExam.link'),
            key: 'isVisibleFinalExam',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.finalExam.faqLink'),
            examType: ExamTypeEnum.FINAL,
        },
        {
            id: 12,
            title: t('landingpage.nonCertifiedExam.title'),
            subtitle: t('landingpage.nonCertifiedExam.subtitle'),
            link: t('landingpage.nonCertifiedExam.link'),
            key: 'isVisibleCertifiedExam',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.nonCertifiedExam.faqLink'),
            examType: ExamTypeEnum.NONCERTIFIED,
        },
        {
            id: 13,
            title: t('landingpage.trainingProgram.title'),
            subtitle: t('landingpage.trainingProgram.subtitle'),
            link: t('landingpage.trainingProgram.link'),
            key: 'isVisibleTrainingProgram',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.trainingProgram.faqLink'),
        },
        {
            id: 14,
            title: t('landingpage.inisSystemFacturation.title'),
            subtitle:t('landingpage.inisSystemFacturation.subtitle'),
            link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
            key: 'isVisibleInisSystemFacturation',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.inisSystemFacturation.faqLink'),
        },
        {
            id: 15,
            title: t('landingpage.inisSystemEducation.title'),
            subtitle: t('landingpage.inisSystemEducation.subtitle'),
            link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
            key: 'isVisibleInisSystemEducation',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.inisSystemEducation.faqLink'),
        },
        {
            id: 16,
            title: t('landingpage.professionalDocumentation.title'),
            subtitle: t('landingpage.professionalDocumentation.subtitle'),
            link: t('landingpage.professionalDocumentation.link'),
            key: 'isVisibleProfessionalDocumentation',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
        },
        {
            id: 17,
            title: t('landingpage.qualityOverview.title'),
            subtitle: t('landingpage.qualityOverview.subtitle'),
            link: t('landingpage.qualityOverview.link'),
            key: 'isVisibleQualityOverview',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.qualityOverview.faqLink'),
        },
        {
            id: 18,
            title: t('landingpage.community.title'),
            subtitle: t('landingpage.community.subtitle'),
            link: t('landingpage.community.link'),
            key: 'isVisibleCommunity',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
        },
        {
            id: 19,
            title: t('landingpage.estox.title'),
            subtitle: t('landingpage.estox.subtitle'),
            //subtitle: i18n.t('landingpage.eloket.subtitle'),
            link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
            key: 'isVisibleEstox',
            openInNewTab: false,
            openInModal: true,
            isITAABlock: false,
            faqLink: t('landingpage.estox.faqLink'),
        },
        {
            id: 20,
            title: t('landingpage.mandateManagement.title'),
            subtitle: t('landingpage.mandateManagement.subtitle'),
            link: '#', // NO NEED FOR URL IT'S GENERATED AUTOMATICALLY
            key: 'isVisibleMandateManagement',
            openInNewTab: false,
            openInModal: true,
            isITAABlock: false,
            faqLink: t('landingpage.mandateManagement.faqLink'),
        },
        {
            id: 21,
            title: t('landingpage.app4Acc.title'),
            subtitle: t('landingpage.app4Acc.subtitle'),
            link: t('landingpage.app4Acc.link'),
            key: 'isVisibleApp4Acc',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.app4Acc.faqLink'),
        },
        {
            id: 22,
            title: t('landingpage.beExcellent.title'),
            subtitle: t('landingpage.beExcellent.subtitle'),
            link: t('landingpage.beExcellent.link'), 
            key: 'isVisibleBeExcellent',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
        },
        {
            id: 23,
            title: t('landingpage.companyWeb.title'),
            subtitle: t('landingpage.companyWeb.subtitle'),
            link: t('landingpage.companyWeb.link'),
            key: 'isVisibleCompanyWeb',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.companyWeb.faqLink'),
        },
        {
            id: 24,
            title: t('landingpage.excelForAccountancy.title'),
            subtitle: t('landingpage.excelForAccountancy.subtitle'),
            link: '#',
            key: 'isVisibleExcelForAccountancy',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
            faqLink: t('landingpage.excelForAccountancy.faqLink'),
        },
        {
            id: 25,
            title: t('landingpage.serviceDesk.title'),
            subtitle: t('landingpage.serviceDesk.subtitle'),
            link: t('landingpage.serviceDesk.link'),
            key: 'isVisibleServiceDesk',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: false,
        },
        {
            id: 26,
            title: t('landingpage.estoxAdmin.title'),
            subtitle: t('landingpage.estoxAdmin.subtitle'),
            link: "#", // https://admin.estox.be/api/auth/?idp=iab&organizationName={token}
            key: 'isVisibleEstoxAdmin',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: true,
        },
        {
            id: 27,
            title: t('landingpage.crm.title'),
            subtitle: t('landingpage.crm.subtitle'),
            link: t('landingpage.crm.link'),
            key: 'isVisibleCrm',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: true,
        },
        {
            id: 28,
            title: t('landingpage.crm2.title'),
            subtitle: t('landingpage.crm2.subtitle'),
            link: t('landingpage.crm2.link'),
            key: 'isVisibleCrm',
            openInNewTab: true,
            openInModal: false,
            isITAABlock: true,
        },
        {
            id: 29,
            title: t('landingpage.certificateGeneration.title'),
            subtitle: t('landingpage.certificateGeneration.subtitle'),
            link: t('landingpage.certificaat.link'), 
            key: 'isVisibleCertificateGeneration',
            openInNewTab: false,
            openInModal: false,
            isITAABlock: true,
        },
        {
            id: 30,
            title: t('landingpage.infoMessages.title'),
            subtitle: t('landingpage.infoMessages.subtitle'),
            link: t('landingpage.infoMessages.link'), 
            key: 'isVisibleInfoMessage',
            openInNewTab: false,
            openInModal: false,
            isITAABlock: true,
        },
    ];
    
    const isItemVisible = (key) => {
        if (userVisibilities && userVisibilities[key] === true) {
            return true;
        }
        return false;
    };

    const filteredData = cardData.filter(item => {
        return (
            isItemVisible(item.key) &&
            (item.title.toLowerCase().includes(isMobile))
        );
    });
    
    const renderCards = () => (

        <Row className='row-m-1'>
            {loading&& filteredData.length === 0 && (
                <Col md={12}>
                    <p>{t('errors.noNavigation')}</p>
                </Col>
            )}
            {cardData.map(item => (
                isItemVisible(item.key) && (
                    <Col xs={12} sm={6} md={4} lg={3} key={item.id}>
                        <Card
                            id={item.id}
                            className={`h-100 landing-page-card`}
                            onClick={(e) => handleCardClick(e,item)}
                        >
                            <Card.Title
                                className={
                                    item.isITAABlock ? 'Landing-Page-Card-Title-Admin' :
                                        'Landing-Page-Card-Title'
                                }
                            >
                                <p>{item.title}</p>
                            </Card.Title>
                            <Card.Body>
                                <Card.Text>{item.subtitle}</Card.Text>
                                <div className="d-grid gap-2 mt-auto align-self-end card-button">
                                    {(item.faqLink && item.key !== 'isVisibleEloket') && (
                                        <Button
                                            className={
                                                item.isITAABlock ? 'btn-home-Admin' : 'btn btn-home'}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                window.open(item.faqLink, '_blank');
                                            }}
                                            disabled={(item.key === 'isVisibleEloket')}
                                        >
                                            FAQ
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            ))}
        </Row>
    );

    return renderCards();
};

export default LandingPageData;
