import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import history from "./utils/history";
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0Configuration } from "./config/auth0Configuration";
import { SessionProvider } from "./providers/SessionProvider";

import "./styles/App.scss";

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};
const auth0ProviderConfiguration = {
    domain: auth0Configuration.domain,
    clientId: auth0Configuration.clientId,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: auth0Configuration.audience,
    },
    useRefreshTokens: auth0Configuration.useRefreshTokens,
    useRefreshTokensFallback: auth0Configuration.useRefreshTokensFallback,
    cacheLocation: auth0Configuration.cacheLocation,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18n}>
         <Auth0Provider {...auth0ProviderConfiguration}>
            <SessionProvider>
                <App />
            </SessionProvider>
        </Auth0Provider>
    </I18nextProvider>
);