import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getProfileGroups } from "./http/httpHelper"
import NaturalPersonInfoHeader from '../../../NaturalPersonInfoHeader/NaturalPersonInfoHeader.tsx';

const ProfileGroups = () => {
    const { userDetail } = useOutletContext();
    const [itaaGroups, setItaaGroups] = useState([])
    const { t } = useTranslation();
    const [langIndex, setLangIndex] = useState(0);

    useEffect(() => {
        const language = localStorage.getItem('i18nextLng');
        let languageIndex = language === 'fr' ? 1 : 0;
        setLangIndex(languageIndex);
    }, []);

    useEffect(() => {
        (async () => {
            const groups = await getProfileGroups(userDetail.id);
            setItaaGroups(groups.itaaGroups);
        })();
    }, [userDetail]);

    return (
        <div id="main">
            <div id="content">
               <NaturalPersonInfoHeader userDetail={userDetail} />
                <div className="text-h1">
                    {t('groups.title')}
                </div>
                <div className='form-group'>
                    <div className='form-row'>
                        <p dangerouslySetInnerHTML={{ __html: t('groups.text') }} />
                    </div>
                    <div className='form-row'>
                        <ul>
                            {
                                itaaGroups.filter(item => item) // Filter items die niet leeg zijn
                                    .map((item, index) => {
                                        const splitItem = item.split('/');
                                        return <li key={index}>{splitItem[langIndex]}</li>;
                                    })
                            }
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ProfileGroups;