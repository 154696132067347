export default {
  nl: {
    title: "Verzekering burgerlijke aansprakelijkheid",
    subText: "Verzekering burgerlijke aansprakelijkheid.",
    titleProfile: "Verzekering",
    membershipWarning: "Verzekering burgerlijke aansprakelijkheid enkel beschikbaar voor leden.",
    notSaved: "Uw wijzigingen zijn nog niet opgeslagen...",
    noInsurance: 'Staat uw verzekering niet in de lijst? Neem dan contact op met het ITAA  (<a href="mailto:publicregister@itaa.be">publicregister@itaa.be</a>).',
    addInsurance: 'Verzekering toevoegen',
    editInsurance: 'Verzekering bewerken',
    collectivePolicy: 'Ik heb een collectieve polis',
    collective_yes: 'Ja',
    collective_no: 'Nee',
    insurer: "Naam hoofdverzekeringsmaatschappij",
    insurerNumber_collective: 'Ondernemingsnummer verzekeringsmaatschappij',
    insurerNumber: 'Ondernemingsnummer hoofdverzekeringsmaatschappij',
    policyNumber_collective: "Polisnummer",
    policyNumber: "Polisnummer hoofdverzekeringsmaatschappij",
    accessionNumber: 'Toetredingsnummer',
    statement: 'Ik verklaar dat de polis voldoet aan de dekkingsvoorwaarden van de ITAA-reglementering.',
    startDate: 'Begindatum',
    endDate: 'Einddatum',
    uploadCertificate: 'Upload verzekeringsattest',
    default_collective_insurer: 'Allianz (Willemot - Marsh)',
    default_collective_insurerNumber: '0403258197',
    default_collective_policyNumber: 'ZCN690001092 / NCN690001093',
    validationErrors: "Verzekering kan momenteel niet opgeslagen worden:",
    confirmation: "Ik verklaar dat de polis voldoet aan de dekkingsvoorwaarden van de ITAA-reglementering",
    validateConfirmation: "De verklaring is verplicht",
    validateRequiredFields: "Vul alle verplichte velden in",
    validateCertificate: "Het certificaat moet in PDF formaat geupload worden en mag maximaal 10MB groot zijn.",
    validateStartDate: "Begindatum moet voor de einddatum liggen.",
    validateEndDate: "Einddatum moet na begindatum liggen",
    validateDuration: "Verschil tussen begin en einddatum mag maximaal 1 jaar zijn.",
    invalidStartDate: "Geef een geldige startdatum in.",
    invalidEndDate: "Geef een geldige einddatum in.",
    validateInsurerNumber: "Geef een geldig ondernemingsnummer in.",
    badgeSuccess: 'Upload voltooid',
    badgeInProgress: 'Upload bezig',
    badgeError: 'Upload mislukt',
    download: 'Download certificaat',
  },
  fr: {
    title: "Assurance responsabilité civile professionnelle",
    subText: "Assurance responsabilité civile professionnelle.",
    titleProfile: "Assurance",
    membershipWarning: "Assurance responsabilité civile professionnelle uniquement disponible pour les membres.",
    notSaved: "Vos modifications n’ont pas encore été enregistrées...",
    noInsurance: 'Si votre assurance ne se trouve pas dans la liste, prenez contact avec l’ITAA  (<a href="mailto:publicregister@itaa.be">publicregister@itaa.be</a>).',
    addInsurance: 'Ajouter une assurance',
    editInsurance: 'Modifier une assurance',
    collectivePolicy: 'J’ai une police collective',
    collective_yes: 'Oui',
    collective_no: 'Non',
    insurer: "Nom de la compagnie d'assurance principale",
    insurerNumber_collective: "Numéro d'entreprise de la compagnie d'assurance",
    insurerNumber: "Numéro d'entreprise de la compagnie d'assurance principale",
    policyNumber_collective: "Numéro de police",
    policyNumber: "Numéro de police de la compagnie d'assurance principale",
    accessionNumber: "Numéro d'adhésion",
    statement: "Je déclare que la police satisfait aux conditions de couverture de la réglementation de l'ITAA.",
    startDate: "Date de début",
    endDate: "Date de fin",
    uploadCertificate: "Télécharger l'attestation d'assurance",
    default_collective_insurer: 'Allianz (Willemot - Marsh)',
    default_collective_insurerNumber: '0403258197',
    default_collective_policyNumber: 'ZCN690001092 / NCN690001093',
    validationErrors: "L'assurance ne peut pas être enregistrée pour le moment:",
    confirmation: "Je déclare que la police satisfait aux conditions de couverture de la réglementation de l'ITAA",
    validateConfirmation: "La déclaration est obligatoire",
    validateRequiredFields: "Veuillez remplir tous les champs obligatoires",
    validateCertificate: "Le certificat doit être téléchargé au format PDF et ne peut pas dépasser 10 MB.",
    validateStartDate: "La date de début doit être antérieure à la date de fin.",
    validateEndDate: "La date de fin doit être postérieure à la date de début",
    validateDuration: "L'écart entre la date de début et la date de fin ne peut pas dépasser un an.",
    invalidStartDate: "Veuillez saisir une date de début valide.",
    invalidEndDate: "Veuillez saisir une date de fin valide.",
    validateInsurerNumber: "Veuillez saisir un numéro d'entreprise valide.",
    badgeSuccess: 'Téléchargement terminé',
    badgeInProgress: 'Téléchargement en cours',
    badgeError: 'Téléchargement échoué',
    download: 'Télécharger le certificat',
  },
};
