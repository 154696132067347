import {CommunicationDetail} from './CommunicationDetail.tsx';
import {ReferencedType}from './ReferencedType.tsx';
import {EnumResponse} from './EnumResponse.tsx'
import {CompanyType} from '../Enums/CompanyType.js'
import {EntityType} from '../Enums/EntityType.js'


export class UserCompany {
    legalPersonId: string;
    companyName: string;
    itaaNumber: number;
    dataVerifiedOn?: Date | null;
    emails: CommunicationDetail[];
    mainContacts: ReferencedType[];
    coAdministrators: ReferencedType[];
    entityType?: EnumResponse<typeof EntityType>;
    companyType?: EnumResponse<typeof CompanyType>;
}