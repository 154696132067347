import React, { useState} from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import showToastMessage from "../../../Toast/Toast.jsx";
import CertificateDownloadModal from "../../CertificateDownloadModal";
import { CurrentUserModel} from "../../../../models/CurrentUserModel.tsx";
import EloketNavigationItem from "../../../../models/EloketNavigationItem.tsx"
import { OfficialType } from '../../../../Enums/OfficialType.tsx';

const MyProfile: React.FC<{ userDetail: CurrentUserModel; isMobile: boolean }> = ({
  userDetail,
  isMobile,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [showTrackModal, setShowTrackModal] = useState<boolean>(false);

  const handleCertificateClick = async () => {
    try {
      setLoading(true);
      if (userDetail?.tracks && Array.isArray(userDetail.tracks)) {
        setShowTrackModal(true);
      } else {
        showToastMessage(t("errors.certificateNoTracks"));
      }
    } catch (error) {
      showToastMessage(t("errors.certificateGeneration"));
    } finally {
      setLoading(false);
    }
  };

  const myData: EloketNavigationItem[] = [
    {
      url: "/eloket/data",
      title: t("sidebar.contact"),
      text: t("data.text"),
    },
    {
      url: "/eloket/personaldata",
      title: t("sidebar.personalData"),
      text: t("personaldata.personalDataText1"),
    },
    ...( userDetail.hasExternalQualification() || userDetail.isExternalType() ? 
        [
          {
            url: "/eloket/insurance",
            title: t("sidebar.myInsurance"),
            text: t("insurance.subText"),
          },
        ]
      : []),
  ];

  const myItaaData: EloketNavigationItem[] = [
    {
      url: "/eloket/itaadata/general",
      title: t("sidebar.general"),
      text: t("data.text_itaadata"),
      isCertificate: false,
      newTab: false,
    },
    ...(userDetail.hasNoActiveTracks()
      ? []
      : [
          {
            url: `${process.env.REACT_APP_URL_SEARCH}${i18n.language}-${i18n.language}/ExternalLink/Redirect?Number=${userDetail?.itaaNumber}&ExternalLinkType=0`,
            title: t("sidebar.myPublicProfile"),
            text: t("personaldata.publicProfile"),
            isCertificate: false,
            newTab: true,
          },
          ...(userDetail.hasOfficialType(OfficialType.ExternalMember, OfficialType.InternalMember) ? [
              {
                url: '/',
                title: t('sidebar.myCertificates'),
                text: t('personaldata.certificateText'),
                isCertificate: true,
                newTab: false,
              }] : []),
          {
            url: "/eloket/officeroles",
            title: t("sidebar.myOfficeRoles"),
            text: t("personaldata.officeRolesText"),
            newTab: false,
          },
          {
            url: "/eloket/itaadata/groups",
            title: t("sidebar.myGroups"),
            text: t("personaldata.groupsText"),
            newTab: false,
          },
          {
            url: "/eloket/networks",
            title: t("sidebar.myNetwork"),
            text: t("personaldata.networksText"),
            newTab: false,
          },
        ]),
  ];

  return (
    <div className="container" id="main">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {isMobile && <div className="text-h1">{t("sidebar.profile")}</div>}
      <div className="text-h2">{t("sidebar.data")}</div>
      <Row className="row-m-1">
        {myData.map((data, index) => (
          <Col key={index} md={4}>
            {!data.isCertificate ? (
              <Link to={data.url} target={data.newTab ? "_blank" : "_self"}>
                <Card lang={i18n.language} className="h-100 custom-card">
                  <Card.Body>
                    <Card.Title>{data.title}</Card.Title>
                    <Card.Text>{data.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            ) : (
              <Card
                lang={i18n.language}
                className="h-100 custom-card"
                onClick={handleCertificateClick}
              >
                <Card.Body>
                  <Card.Title>{data.title}</Card.Title>
                  <Card.Text>{data.text}</Card.Text>
                </Card.Body>
              </Card>
            )}
          </Col>
        ))}
      </Row>
      <br />
      <div className="text-h2">{t("sidebar.itaaData")}</div>
      <Row className="row-m-1">
        {myItaaData.map((data, index) => (
          <Col key={index} md={4}>
            {!data.isCertificate ? (
              <Link to={data.url} target={data.newTab ? "_blank" : "_self"}>
                <Card lang={i18n.language} className="h-100 custom-card">
                  <Card.Body>
                    <Card.Title>{data.title}</Card.Title>
                    <Card.Text>{data.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            ) : (
              <Card
                lang={i18n.language}
                className="h-100 custom-card"
                onClick={handleCertificateClick}
              >
                <Card.Body>
                  <Card.Title>{data.title}</Card.Title>
                  <Card.Text>{data.text}</Card.Text>
                </Card.Body>
              </Card>
            )}
          </Col>
        ))}
      </Row>

      <CertificateDownloadModal
        showModal={showTrackModal}
        handleClose={() => setShowTrackModal(false)}
        tracks={userDetail.tracks}
        userDetail={userDetail}
      />
    </div>
  );
};

export default MyProfile;
