const isProduction = process.env.NODE_ENV === 'production';

const getFormattedDateTime = () => {
    const now = new Date();
    return now.toISOString();
};

export const log = {
    debug: (message, color = 'black') => {
        if (!isProduction) {
            const timestamp = getFormattedDateTime();
            console.debug(`%c[${timestamp}] [debug] ${message}`, `color: ${color};`);
        }
    },
    info: (message, color = 'black') => {
        if (!isProduction) {
            const timestamp = getFormattedDateTime();
            console.info(`%c[${timestamp}] [info] ${message}`, `color: ${color};`);
        }
    },
    warn: (message, color = 'orange') => {
        if (!isProduction) {
            const timestamp = getFormattedDateTime();
            console.warn(`%c[${timestamp}] [warning] ${message}`, `color: ${color};`);
        }
    },
    error: (message, error, color = 'red') => {
        if (!isProduction) {
            const timestamp = getFormattedDateTime();
            console.error(`%c[${timestamp}] [error] ${message}`, `color: ${color};`, error);
        }
        if (isProduction && error) {
            // send to remote logging service
        }
    }
};