import apiClient from "../../../../api/client/apiClient";

export const getInfoMessages = () => {
  return apiClient
    .get("/api/infomessages")
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const postInfoMessage = (infoMessage) => {
  return apiClient
    .post("/api/infomessages", infoMessage)
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const patchInfoMessage = (infoMessage) => {
  return apiClient
    .patch(`/api/infomessages/${infoMessage.id}/update`, infoMessage)
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};

export const deleteInfoMessage = (id) => {
  return apiClient
    .delete(`/api/infomessages/${id}/delete`)
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(`HTTP request failed: ${error.message}`);
    });
};