import React, { useEffect, useState } from "react";
import { Modal, Button,FormControl,InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getActiveGeneralAssembly, getGeneralAssemblyParticipant, declineGeneralAssemblyParticipant,proxyGeneralAssemblyParticipant,confirmGeneralAssemblyParticipantWillAttend } from "./http/HttpClientHelper.tsx";
import GeneralAssemblyDocumentation from "./GeneralAssemblyDocumentation.tsx";
import OptionMenuComponent from './OptionMenuComponent.tsx';
import LoaderSpinnerOverlay from '../../../loader/LoaderSpinnerOverlay.jsx'
import { GeneralAssemblyRegistrationType,GeneralAssemblyRegistrationOptionType } from "../../../../Enums/GeneralAssemblyRegistrationType.tsx";
import { ActiveGeneralAssembly,GeneralAssemblyParticipant, GeneralAssemblyRegistrationOptionMenuModel } from "../../../../models/ActiveGeneralAssembly.tsx";
import {CurrentUserModel} from '../../../../models/CurrentUserModel.tsx';
import { useSessionContext } from '../../../../providers/SessionProvider.jsx';
interface GeneralAssemblyProps {
  show: boolean;
  userDetail: CurrentUserModel
  handleClose: () => void;
}

const GeneralAssemblyRegistration: React.FC<GeneralAssemblyProps> = ({ show, handleClose, userDetail}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [generalAssembly, setGeneralAssembly] = useState<ActiveGeneralAssembly | null>(((null)));
  const [activeGeneralAssemblyParticipant, setActiveGeneralAssemblyParticipant] = useState<GeneralAssemblyParticipant | null>(Object.assign(new GeneralAssemblyParticipant()));
  const [selectPerson, setselectPerson] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const [seatsLeft, setSeatsLeft] = useState<number>(0)
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const {logout,isSessionElapsedGreaterThan} = useSessionContext();
  const [proxyFeatureUnavailable, setProxyFeatureUnavailable] = useState<boolean>(true);

  const today = new Date();
  today.setHours(0,0,0,0)
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    setLoading(true);
    getActiveGeneralAssembly()
      .then(async activeGeneralAssembly => {
        if (activeGeneralAssembly?.registrationClosingDate) {
          const registrationClosingDate = new Date(activeGeneralAssembly?.registrationClosingDate);
          registrationClosingDate.setHours(0, 0, 0, 0);
          activeGeneralAssembly.registrationClosingDate = registrationClosingDate;
        }
        
        if (activeGeneralAssembly?.proxyVoteClosingDate) {
            const registrationProxyVoteClosingDate = new Date(activeGeneralAssembly.proxyVoteClosingDate);
            registrationProxyVoteClosingDate.setHours(0, 0, 0, 0);
            activeGeneralAssembly.proxyVoteClosingDate = registrationProxyVoteClosingDate;
        }
        
        if(activeGeneralAssembly){
          setGeneralAssembly(activeGeneralAssembly);
          const generalAssemblyParticipant = await getGeneralAssemblyParticipant(activeGeneralAssembly.kid, userDetail.id);
          setActiveGeneralAssemblyParticipant( generalAssemblyParticipant);
          setSeatsLeft(activeGeneralAssembly.physicalSeats - activeGeneralAssembly.placesLeft)
        }
      })
      .finally(() => {
        setLoading(false);   
      });  

  }, [userDetail]);

  const handleButtonClick = async () => {
    try {
    
      let assembly: GeneralAssemblyRegistrationOptionMenuModel = new GeneralAssemblyRegistrationOptionMenuModel();
      setLoading(true);
      assembly.naturalPersonId = userDetail.id;
      
      switch (selectedOption) {
        case GeneralAssemblyRegistrationOptionType.Onsite:
          assembly.willBePhysicallyPresent = true;
          await confirmGeneralAssemblyParticipantWillAttend(generalAssembly!.kid, assembly);
          break;
  
        case GeneralAssemblyRegistrationOptionType.Online:
          assembly.willBePhysicallyPresent = false;
          await confirmGeneralAssemblyParticipantWillAttend(generalAssembly!.kid, assembly);
          break;
  
        case GeneralAssemblyRegistrationOptionType.WithProxy:
          assembly.ProxyNaturalPersonId = selectPerson;
          assembly.HasAgreedToTerms=checkboxChecked;
          await proxyGeneralAssemblyParticipant(generalAssembly!.kid, assembly);
          break;
  
        case GeneralAssemblyRegistrationOptionType.NoShow:
          await declineGeneralAssemblyParticipant(generalAssembly!.kid, assembly);
          break;
      }
      setIsSuccessful(true);
    } catch (error: any) {
      if (error.response?.status === 400) {
        setErrorMessage(t(`validation.generalAssembly.${error.response.data.errorCode}`));
      } else {
        setErrorMessage(t(`validation.generalAssembly.${error}`));
      }
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      {loading && <LoaderSpinnerOverlay />}
      <Modal show={show} onHide={handleClose} className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{t("generalAssembly.registration.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GeneralAssemblyDocumentation />
  
          { (userDetail.isInternalType() || userDetail.isExternalType() || userDetail.isHonoraryMember()) && (
            <>
              {(generalAssembly && generalAssembly?.registrationClosingDate?.getTime() > today.getTime()) && (
                (activeGeneralAssemblyParticipant?.willAttend === null || activeGeneralAssemblyParticipant === null) ? (
                  <>
                    <section className="mt-3" dangerouslySetInnerHTML={{ __html: t('generalAssembly.registration.subtitle') }}/>
                    {errorMessage !== '' && !proxyFeatureUnavailable && !isSuccessful && (
                      <section className="mb-3">
                        <div className="alert alert-danger" role="alert">
                          {errorMessage}
                        </div>
                      </section>
                    )}
                    {isSuccessful ? (
                      <section className="mt-3">
                        <div className="alert alert-success" role="alert">
                          {t("generalAssembly.registration.success")}
                        </div>
                      </section>
                    ) : (
                      <>
                        <section>
                          <OptionMenuComponent
                            setSelectedOption={setSelectedOption}
                            setselectPerson={setselectPerson}
                            userDetail={userDetail}
                            checkboxChecked={checkboxChecked}
                            setCheckboxChecked={setCheckboxChecked}
                            logout={logout}
                            isSessionElapsedGreaterThan={isSessionElapsedGreaterThan}
                            setProxyFeatureUnavailable={setProxyFeatureUnavailable}
                            proxyFeatureUnavailable={proxyFeatureUnavailable}
                            options={[
                              {
                                value: GeneralAssemblyRegistrationOptionType.Show,
                                label: t("generalAssembly.registration.optionMenu.attend"),
                                hasDropdown: false,
                                subOptions: [
                                  {
                                    value: GeneralAssemblyRegistrationOptionType.Onsite,
                                    label: `${t("generalAssembly.registration.optionMenu.subOption.followOnSite")} ${seatsLeft === -1 ? 0 : seatsLeft} ${t("generalAssembly.registration.optionMenu.subOption.places")}`
                                  },
                                  {
                                    value: GeneralAssemblyRegistrationOptionType.Online,
                                    label: t("generalAssembly.registration.optionMenu.subOption.followRemote")
                                  }
                                ]
                              },
                              { value: GeneralAssemblyRegistrationOptionType.WithProxy, label: t("generalAssembly.registration.optionMenu.proxy.choice"), 
                                hasDropdown: true , isInternalMember: userDetail.isInternalMember(), isExternalMember: userDetail.isExternalMember(),
                                proxyDateOngoing: (generalAssembly?.proxyVoteClosingDate?.getTime() > today.getTime()), 
                                hasTimer:true
                              },
                              { value: GeneralAssemblyRegistrationOptionType.NoShow, label: t("generalAssembly.registration.optionMenu.doNotAttend"), hasDropdown: false }
                            ]}
                          />
                        </section>
                        <section className="mt-3">
                          {t("generalAssembly.registration.sendMail")}
                        </section>
                        <Modal.Footer className="justify-content-end">
                          <Button 
                            variant="secondary" 
                            disabled={(selectedOption===GeneralAssemblyRegistrationOptionType.WithProxy && 
                              ((proxyFeatureUnavailable || (!checkboxChecked || selectPerson === '')))) ||selectedOption === '' ||selectedOption === GeneralAssemblyRegistrationOptionType.Show} 
                            className="btn-eloket" 
                            onClick={handleButtonClick}
                          > 
                            {t("generalAssembly.registration.send")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )}
                  </>
                ) : (
                  <section className="mt-3" dangerouslySetInnerHTML={{ __html: t('generalAssembly.registration.confirmed') }} />
                )
              )}
  
              {(generalAssembly && generalAssembly?.registrationClosingDate !== null && generalAssembly?.registrationClosingDate?.getTime() <= today.getTime()) && (
                <>
                  <section className="mt-3 alert alert-danger" role="alert"
                    dangerouslySetInnerHTML={{ __html: t('generalAssembly.registration.registrationExpired') }}
                  />
                    <section className="mt-3">
                      <div className="mb-3">
                        <InputGroup>
                          <FormControl
                            as="div"
                            className="form-control"
                            dangerouslySetInnerHTML={{
                              __html: `<a href="${t("generalAssembly.registration.registeredOnline.lightPortal")}" target="_blank" rel="noopener noreferrer">${t("generalAssembly.registration.registeredOnline.lightPortal")}</a>`
                            }}
                          />
                          <Button onClick={() => handleCopy(t("generalAssembly.registration.registeredOnline.lightPortal"))}>
                            <i className="fa fa-clone"></i>
                          </Button>
                        </InputGroup>
                        <small>{t("generalAssembly.registration.hasStarted")}</small>
                      </div> 
                    </section>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>  
  );
};

export default GeneralAssemblyRegistration;