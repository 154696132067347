import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

import LandingPage from "./pages/landingpage/LandingPage";
import BasicData from "./pages/company/BasicData";
import MainLayout from "./components/Main/MainLayout";
import Impersonation from "./pages/main/Impersonation.tsx";
import ELoketLayout from "./components/ELoket/ELoketLayout";
import Data from "./pages/profile/data/Data.tsx";
import ItaaData from "./components/ELoket/Profile/ItaaData/ItaaData.tsx";
import PersonalData from "./components/ELoket/Profile/PersonalData/PersonalData";
import DataAtItaa from "./components/ELoket/Company/DataAtItaa/DataAtItaa";
import BeExcellent from "./components/ELoket/Office/BeExcellent/BeExcellent";
import OfficeComposition from "./components/ELoket/Office/OfficeComposition/OfficeComposition";
import MainContact from "./components/ELoket/Office/MainContact/MainContact";
import OfficeCoAdministrator from "./components/ELoket/Office/CoAdministrator/OfficeCoAdministrator";
import QualityResponsible from "./components/ELoket/Office/QualityResponsible/QualityResponsible";
import AntiMoneyLaunderingOfficers from "./components/ELoket/Office/AntiMoneyLaunderingOfficers/AntiMoneyLaunderingOfficers";
import AccessTools from "./components/ELoket/Office/AccessTools/AccessTools";
import DirectorAndPartner from "./components/ELoket/Company/DirectorAndPartner/DirectorAndPartner";
import Network from "./pages/profile/network/Network";
import Clientele from "./components/ELoket/Company/Clientele/Clientele";
import Certificate from "./components/Certificate/Certificate";
import MyProfile from "./components/ELoket/NavigationPages/NavigationPageMyProfile/MyProfile.tsx";
import NavCompanies from "./components/ELoket/NavigationPages/NavigationPageItaaCompanies/NavCompanies";
import NavOffices from "./components/ELoket/NavigationPages/NavigationPageOffices/NavOffices";
import InfoMessage from "./components/Main/InfoMessage/Overview";
import Quality from "./components/Main/Quality/Quality";
import Missions from "./components/ELoket/Missions/missions";
import ErrorBoundary from "./components/errors/ErrorBoundary";
import MyDdata from "./components/ELoket/NavigationPages/NavigationPageMyProfile/MyData.tsx";
import MyItaaData from "./components/ELoket/NavigationPages/NavigationPageMyProfile/MyItaaData.tsx";
import ProfileNetworks from "./components/ELoket/Profile/ItaaData/ProfileNetworks/ProfileNetworks";
import Insurance from "./pages/profile/insurance/Insurance.tsx";
import ProfileGroup from "./components/ELoket/Profile/ItaaData/ProfileGroups/ProfileGroups";
import OfficeRoles from "./components/ELoket/Profile/OfficeRoles/OfficeRoles";
import CompanyGeneralData from "./components/ELoket/NavigationPages/companies/CompanyGeneralData";
import NavOfficeDetail from "./components/ELoket/NavigationPages/NavigationPageOffices/NavOfficeDetail";
import NavCompaniesDetail from "./components/ELoket/NavigationPages/NavigationPageCompaniesDetail/NavCompaniesDetail";
import CompanyInsurance from "./pages/company/insurance/Insurance.tsx";
import ItaaDataDetails from "./components/ELoket/Company/ItaaDataDetails/ItaaDataDetails";
import AuthError from "./components/errors/AuthError";
import SidebarLayout from "./components/SidebarLayout";
import { useSessionContext } from "./providers/SessionProvider";
import EstoxExternalProviderShare from "./components/Main/LandingPage/Estox/EstoxExternalProviderShare";
import PrivateRoute from "./components/auth/PrivateRoute";
import { CurrentUserModel } from "./models/CurrentUserModel.tsx";

function App() {
  const { currentUser } = useSessionContext();
  var userDetail = Object.assign(new CurrentUserModel(), currentUser);
  const isMobile = useMediaQuery({ maxWidth: 768 }) || false;
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute Component={MainLayout} userDetail={userDetail}>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route index element={<LandingPage userDetail={userDetail} />} />
          <Route path="impersonation" element={<Impersonation />} />
          <Route path="infomessage/overview" element={<InfoMessage />} />
          <Route path="quality" element={<Quality />} />
          <Route path="*" element={<ErrorBoundary />} />
        </Route>
        <Route
          path="estox/externalprovidershare"
          element={
            <PrivateRoute Component={SidebarLayout} userDetail={userDetail}>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route
            path="*"
            index
            element={<EstoxExternalProviderShare userDetail={userDetail} />}
          />
        </Route>
        <Route
          path="infomessage/overview"
          element={
            <PrivateRoute Component={SidebarLayout} userDetail={userDetail}>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route index element={<InfoMessage userDetail={userDetail} />} />
        </Route>

        <Route
          path="certificate"
          element={
            <PrivateRoute Component={SidebarLayout} userDetail={userDetail}>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route index element={<Certificate />} />
        </Route>

        <Route
          path="eloket/*"
          element={
            <PrivateRoute Component={ELoketLayout} userDetail={userDetail}>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route
            path="myprofile"
            element={<MyProfile userDetail={userDetail} isMobile={isMobile} />}
          />
          <Route
            path="myprofile/itaadata/:natPersonId"
            element={
              <MyItaaData userDetail={userDetail} isMobile={isMobile} />
            }
          />
          <Route
            path="myprofile/basicdata/:natPersonId"
            element={<MyDdata isMobile={isMobile} userDetail={userDetail} />}
          />
          <Route
            path="myitaadata"
            element={<MyItaaData userDetail={userDetail} />}
          />
          <Route
            path="companies"
            element={
              <NavCompanies userDetail={userDetail} isMobile={isMobile} />
            }
          />
          <Route
            path="offices"
            element={
              <NavOffices userDetail={userDetail} isMobile={isMobile} />
            }
          />
          <Route path="data" element={<Data />} />
          <Route path="insurance" element={<Insurance userDetail={userDetail}/>} />
          <Route path="personaldata" element={<PersonalData />} />
          <Route
            path="company/:legalPersonId"
            element={<NavCompaniesDetail userDetail={userDetail} />}
          />
          <Route
            path="company/basicdata/general/:legalPersonId"
            element={<CompanyGeneralData userDetail={userDetail} />}
          />
          <Route
            path="company/basicdata/contact/:legalPersonId"
            element={<BasicData userDetail={userDetail} />}
          />
          <Route
            path="company/basicdata/insurance/:legalPersonId"
            element={<CompanyInsurance userDetail={userDetail} />}
          />
          <Route
            path="company/itaadata/details/:legalPersonId"
            element={<ItaaDataDetails userDetail={userDetail} />}
          />
          <Route
            path="company/itaadata/general/:legalPersonId"
            element={<DataAtItaa userDetail={userDetail} />}
          />
          <Route
            path="company/itaadata/clientele/:legalPersonId"
            element={<Clientele userDetail={userDetail} />}
          />
          <Route
            path="company/itaadata/directorandpartner/:legalPersonId"
            element={
              <DirectorAndPartner
                userDetail={userDetail}
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="company/itaadata/network/:legalPersonId"
            element={<Network userDetail={userDetail} />}
          />
          <Route
            path="company/itaadata/coadministrator/:legalPersonId"
            element={
              <OfficeCoAdministrator
                userDetail={userDetail}
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="company/itaadata/maincontact/:legalPersonId"
            element={<MainContact userDetail={userDetail} />}
          />
          <Route
            path="office/:legalPersonId"
            element={<NavOfficeDetail userDetail={userDetail} />}
          />
          <Route
            path="office/beexcellent/:legalPersonId"
            element={<BeExcellent userDetail={userDetail} />}
          />
          <Route
            path="office/officecomposition/:legalPersonId"
            element={
              <OfficeComposition userDetail={userDetail} isMobile={isMobile} />
            }
          ></Route>
          <Route
            path="office/maincontact/:legalPersonId"
            element={<MainContact userDetail={userDetail} />}
          ></Route>
          <Route
            path="office/coadministrator/:legalPersonId"
            element={
              <OfficeCoAdministrator
                userDetail={userDetail}
                isMobile={isMobile}
              />
            }
          ></Route>
          <Route
            path="office/qualityresponsible/:legalPersonId"
            element={
              <QualityResponsible
                userDetail={userDetail}
                isMobile={isMobile}
              />
            }
          ></Route>
          <Route
            path="office/antimoneylaunderingofficers/:legalPersonId"
            element={
              <AntiMoneyLaunderingOfficers
                userDetail={userDetail}
                isMobile={isMobile}
              />
            }
          ></Route>
          <Route
            path="office/accesstools/:legalPersonId"
            element={
              <AccessTools userDetail={userDetail} isMobile={isMobile} />
            }
          ></Route>
          <Route
            path="missions"
            element={<Missions userDetail={userDetail} />}
          ></Route>
          <Route
            path="networks"
            element={<ProfileNetworks userDetail={userDetail} />}
          ></Route>
          <Route
            path="officeroles"
            element={<OfficeRoles userDetail={userDetail} />}
          ></Route>
          <Route
            path="itaadata/general"
            element={<ItaaData userDetail={userDetail} />}
          />
          <Route
            path="itaadata/groups"
            element={<ProfileGroup userDetail={userDetail} />}
          ></Route>

          <Route path="*" element={<ErrorBoundary />} />
        </Route>

        <Route path="auth-error" element={<AuthError />} />
        <Route path="*" element={<ErrorBoundary />} />
      </Routes>
    </Router>
  );
}

export default App;
