import ExistingUserToolAccess from "../../../../../models/naturalPerson/ExistingUserToolAccess";
import NewUserToolAccess from "../../../../../models/naturalPerson/NewUserToolAccess";
import showErrorToast from "../../../../Toast/Toast";
import apiClient from "../../../../../api/client/apiClient";
import i18next from "i18next";

export const getAccessToolsData = async (legalPersonId) => {
  const response = await apiClient
    .get(`/api/offices/${legalPersonId}/accesstools`);
  return response.data;
};

export const getLanguages = async () => {
  const response = await apiClient
    .get(`/api/common/languages`);
  return response.data;
};

export const getGenders = async () => {
  const response = await apiClient
    .get(`/api/common/genders`);
  return response.data;
};

export const getNationalities = async () => {
  const response = await apiClient
    .get(`/api/common/nationalities`);
  return response.data;
};

export const searchAccessToolsCandidates = async (legalPersonId, search) => {
  const response = await apiClient
    .get(`/api/offices/${legalPersonId}/accesstools/search/${search}`);
  return response.data;
};

export const postPersonAccessTools = async (userData) => {
  if (userData instanceof ExistingUserToolAccess) {
    return await apiClient.post(`/api/offices/${userData.legalPersonId}/accesstools/addtoolaccess`, userData);
  } else {
    showErrorToast(i18next.t('errors.addTools'));
  }
};

export const postNewPersonAccessTools = async (userData) => {
  if (userData instanceof NewUserToolAccess) {
    return await apiClient
      .post(`/api/offices/${userData.legalPersonId}/accesstools/addnewusertoolaccess`, userData);
  } else {
    showErrorToast(i18next.t('errors.addTools'));
  }
};

export const deletePersonAccessTools = async (deletePersonAccessToolsParams) => {
  const response = await apiClient.delete(`/api/offices/deletetoolsaccess`, {
    data: deletePersonAccessToolsParams
  });
  return response;
};
