import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getLocalStorageCurrentUser,
  setLocalStorageCurrentUser,
  getLocalStorageLoginLogged,
  setLocalStorageLoginLogged,
  getLocalStorageUserOffices,
  setLocalStorageUserOffices,
  removeLocalStorageUserOffices,
  getLocalStorageUserCompanies,
  setLocalStorageUserCompanies,
  removeLocalStorageUserCompanies,
  clearLocalStorage,
} from "../utils/storage/localStorage";
import { clearSessionStorage } from "../utils/storage/sessionStorage";
import { auth0Configuration } from "../config/auth0Configuration";
import { postActionHistory } from "../api/actionHistoryApi";
import { putUserVisibilities,  getUserVisibilities} from "../api/userApi";
import {
  getProfileItaaDetails,
  getProfileCompanies,
  getProfileOffices,
} from "../api/profileApi.tsx";
import { UserActionType } from "../Enums/UserActionType.tsx";
import { CurrentUserLanguage } from "../Enums/CurrentUserLanguage";
import { addAccessTokenInterceptor } from "../api/client/apiClient";
import { addAccessTokenInterceptor as addFileClientInterceptor } from '../api/client/apiFileClient';

import { log } from "../utils/log";
import { useTranslation } from "react-i18next";
import { jwtDecode } from 'jwt-decode';

const SessionContext = createContext();
export const useSessionContext = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const {
    loginWithRedirect,
    logout,
    user,
    error,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const { i18n } = useTranslation();
  const [loadingState, setLoadingState] = useState({
    profile: false,
    impersonation: false,
    stopImpersonation: false,
  });
  const setLoading = (key, value) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const [hasBOSAIdentity, setHasBOSAIdentity] = useState(false);

  const [currentUser, setCurrentUser] = useState(() => {
    const cachedUser = getLocalStorageCurrentUser();
    return cachedUser ? cachedUser : null;
  });

  const [companies, setCompanies] = useState(() => {
    const cachedCompanies = getLocalStorageUserCompanies();
    return cachedCompanies ? cachedCompanies : [];
  });

  const [offices, setOffices] = useState(() => {
    const cachedOffices = getLocalStorageUserOffices();
    return cachedOffices ? cachedOffices : [];
  });

  const getAccessToken = async () => {
    return await getAccessTokenSilently({
      cacheMode: auth0Configuration.tokenCacheMode,
    });
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessToken);
    addFileClientInterceptor(getAccessToken);
  }, [getAccessTokenSilently]);

  const checkHasBOSAIdentity = async () => {
    const token = await getAccessToken();
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const { sub } = decodedToken;
        if (sub.includes("BOSA")) {
          setHasBOSAIdentity(true);
        }
      } catch (error) {
        setHasBOSAIdentity(false);
      }
    }
  };
  const LANGUAGES = {
    NL: "nl",
    FR: "fr",
  };
  useEffect(() => {
    const handleOperations = async () => {
      setLoading("profile", true);
      const naturalpersonId = user.externalid;
      await Promise.all([
        auditLoginActionHistory(naturalpersonId),
        fetchProfile(naturalpersonId),
        putUserVisibilities(naturalpersonId),
        fetchProfileCompanies(naturalpersonId),
        fetchProfileOffices(naturalpersonId),
      ]);
      setLoading("profile", false);
    };
    if (isAuthenticated) {
      checkHasBOSAIdentity();
    }
    if (isAuthenticated && user && !currentUser) {
      if (user.language === CurrentUserLanguage.NL) {
          i18n.changeLanguage(LANGUAGES.NL);
        } else {
          i18n.changeLanguage(LANGUAGES.FR);
        }
      log.info("fetching profile");
      handleOperations();
    }
  }, [isAuthenticated, user, currentUser]);

  const fetchProfile = async (naturalpersonId, force = false) => {
    if (!isAuthenticated) return;
    if (!naturalpersonId) return;

    if (force) {
      const profile = await getProfileItaaDetails(naturalpersonId);
      setCurrentUser(profile);
      setLocalStorageCurrentUser(profile);
      return profile;
    }

    const currentUserLocalStorage = getLocalStorageCurrentUser();
    if (currentUserLocalStorage) {
      setCurrentUser(currentUserLocalStorage);
      return currentUserLocalStorage;
    }

    const profile = await getProfileItaaDetails(naturalpersonId);
    setCurrentUser(profile);
    setLocalStorageCurrentUser(profile);
    return profile;
  };

  const fetchProfileCompanies = async (naturalpersonId) => {
    if (!isAuthenticated) return;
    if (!naturalpersonId) return;

    const companiesLocalStorage = getLocalStorageUserCompanies();
    if (companiesLocalStorage) {
      setCompanies(companiesLocalStorage);
      return companiesLocalStorage;
    } else {
      const companies = await getProfileCompanies(naturalpersonId);
      setCompanies(companies);
      setLocalStorageUserCompanies(companies);
      return companies;
    }
  };

  const fetchProfileOffices = async (naturalpersonId) => {
    if (!isAuthenticated) return;
    if (!naturalpersonId) return;

    const officesLocalStorage = getLocalStorageUserOffices();
    if (officesLocalStorage) {
      setOffices(officesLocalStorage);
      return officesLocalStorage;
    } else {
      const offices = await getProfileOffices(naturalpersonId);
      setOffices(offices);
      setLocalStorageUserOffices(offices);
      return offices;
    }
  };

  const auditLoginActionHistory = async (naturalpersonId) => {
    if (!isAuthenticated) return;
    if (!naturalpersonId) return;

    const isLoginLogged = getLocalStorageLoginLogged();
    if (!isLoginLogged) {
      await postActionHistory(naturalpersonId, UserActionType.Login);
      setLocalStorageLoginLogged("true");
    }
  };

  const logoutUser = async () => {
    log.info("login out");
    clearSession();
    await logout();
  };

  const redirectToHome = () => {
    window.location.href = "/";
  };

  const impersonate = async (naturalpersonId, employeeId) => {
    if (!isAuthenticated) return;

    if (!currentUser) return;
    log.info("impersonating");
    setLoading("impersonation", true);
    removeLocalStorageUserOffices();
    removeLocalStorageUserCompanies();

    const impersonatedProfile = await fetchProfile(naturalpersonId, true);
    if (!impersonatedProfile) return;

    impersonatedProfile.itaaEmployeeId = employeeId;
    impersonatedProfile.isImpersonated = true;
    impersonatedProfile.isItaaEmployee = true;
    impersonatedProfile.isItaaIT = currentUser.isItaaIT;
    impersonatedProfile.isItaaSRD = currentUser.isItaaSRD;

    setCurrentUser(impersonatedProfile);
    setLocalStorageCurrentUser(impersonatedProfile);

    await Promise.all([
      putUserVisibilities(naturalpersonId),
      fetchProfileCompanies(naturalpersonId),
      fetchProfileOffices(naturalpersonId),
    ]);
    setLoading("impersonation", false);
    redirectToHome();
  };

  const stopImpersonation = async (employeeId) => {
    if (!isAuthenticated) return;

    if (!currentUser) return;

    log.info("stop impersonating");
    setLoading("stopImpersonation", true);
    removeLocalStorageUserOffices();
    removeLocalStorageUserCompanies();
    const profile = await fetchProfile(employeeId, true);
    if (!profile) return;

    setCurrentUser(profile);
    setLocalStorageCurrentUser(profile);

    await Promise.all([
      fetchProfileCompanies(employeeId),
      fetchProfileOffices(employeeId),
    ]);

    setLoading("stopImpersonation", false);
    redirectToHome();
  };

  const clearSession = () => {
    log.info("clearing session");
    clearLocalStorage();
    clearSessionStorage();
    setCurrentUser(null);
    setCompanies([]);
    setOffices([]);
  };

  const isSessionElapsedGreaterThan = (thresholdInSeconds) => {
    if (!user?.session_authenticated_at) return { isGreaterThan: false, remainingSeconds: thresholdInSeconds };
  
    const sessionAuthenticatedAt = new Date(user.session_authenticated_at);
    const currentDate = new Date();
    const elapsedTimeInSeconds = (currentDate.getTime() - sessionAuthenticatedAt.getTime()) / 1000;
    const remainingSeconds = Math.max(thresholdInSeconds - elapsedTimeInSeconds, 0);
  
    return { isGreaterThan: elapsedTimeInSeconds > thresholdInSeconds, remainingSeconds };
  };
  
  return (
    <SessionContext.Provider
      value={{
        loginWithRedirect,
        logout: logoutUser,
        isAuthenticated,
        isLoading,
        error,
        hasBOSAIdentity,
        loadingState,
        currentUser: currentUser,
        companies: companies,
        offices: offices,
        impersonate,
        stopImpersonation,
        clearSession,
        isSessionElapsedGreaterThan
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
