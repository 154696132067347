import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOffice } from '../../Office/BeExcellent/http/HttpClientHelper';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';
import { EntityType } from '../../../../Enums/EntityType.tsx';

const CompanyGeneralData = ({ userDetail }) => {
    const { t, i18n } = useTranslation();
    const [companyDetails, setCompanyDetails] = useState(null);
    const { legalPersonId } = useParams();
    const [loading, setLoading] = useState(false);

    const fetchCompanyDetails = async () => {
        const data = await getOffice(legalPersonId);
        setCompanyDetails(data);
    };

    useEffect(() => {
        setLoading(true);
        fetchCompanyDetails();
        setLoading(false);
    }, []);

    const myProfileData = [
        {
            url: "/eloket/company/basicdata/contact/",
            title: t("sidebar.contact"),
            text: t("data.text"),
        },
        companyDetails?.entityType?.id !== EntityType.EPP ? {
            url: "/eloket/company/basicdata/insurance/",
            title: t("sidebar.insurance"),
            text: t("basicdata.liabilityCompany"),
        } : null,
    ].filter(item => item !== null);

    myProfileData.forEach(item => {
        item.url += legalPersonId;
    });


    return (
        <div className="container" id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <LegalPersonInfoHeader
                legalPersonId={companyDetails?.legalPersonId}
                userDetail={userDetail}
                legalPersonType={LegalPersonTypeEnum.Company}
            />
            <Row className='row-m-1'>
                {myProfileData.map((data, index) => (
                    <Col key={index} md={4}>
                        <Link to={data.url}>
                            <Card lang={i18n.language} className="h-100 custom-card">
                                <Card.Body>
                                    <Card.Title>{data.title}</Card.Title>
                                    <Card.Text>{data.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default CompanyGeneralData;
