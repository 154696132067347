export default {
  nl: {
    title: "Samenstelling kantoor",
    title_amounts: "Perso(o)n(en) werkzaam in het kantoor",
    title_information:
      "Teneinde tegemoet te komen aan de verplichtingen in het kader van de antiwitwaswetgeving en de kwaliteitstoetsing, moet u het ITAA informeren over de samenstelling van uw kantoor.",
    explanation:
      "Deze lijst kan enkel worden beheerd door de hoofdcontactpersoon van het kantoor. N.B.: Enkel indien een onderaannemer exclusief voor uw kantoor werkt of indien deze een permanente functie / titel heeft in uw kantoor, wordt deze onderaannemer in uw kantoor getoetst.Is dit niet het geval, dan zal beschouwd worden dat hij een eigen kantoor heeft en zal er bij hem ter plaatse een toetsing van dossiers en kantoororganisatie plaatsvinden.",
    not_available: "Samenstelling kantoor is niet beschikbaar voor dit kantoor.",
    amount_one: "Beroepsbeoefenaar(s) in het kantoor tewerkgesteld",
    amount_two:
      "Intern(e) ITAA-lid/leden (bedienden) in het kantoor tewerkgesteld",
    amount_three: "Extern(e) ITAA-stagiair(s) werkzaam in het kantoor",
    amount_four: "Intern(e) ITAA-stagiair(s) werkzaam in het kantoor",
    amount_five:
      "IBR-lid/leden werkzaam in het kantoor, ongeacht de samenwerkingsvorm",
    amount_six:
      "Andere natuurlijke perso(o)n(en) die onder bediendenstatuut werkzaam is/zijn in het kantoor",
    total: "Aantal perso(o)n(en) werkzaam in het kantoor",
    add: "+ Persoon toevoegen aan het kantoor",
    search: "Zoeken",
    header_name: "Naam",
    modal_title: "NIEUW PERSOON TOEVOEGEN AAN HET KANTOOR",
    modal_description: "Naam persoon",
    modal_text:
      'U kunt alleen externe leden of externe stagiairs toevoegen die geregistreerd zijn in de databank van het ITAA. Neem bij vragen contact op met de <a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>.',
    modal_add: "Toevoegen",
    modal_success: "SUCCES",
    modal_success_explanation:
      "Persoon werd aan de lijst toegevoegd. Het kan enige tijd innemen alvorens uw wijzigingen zichtbaar zijn op het portaal.",
    modal_success_cancel: "Sluiten",
    text: "Beheer samenstelling kantoor.",
    warningMainContact:
      "Enkel de hoofdcontactpersoon of medebeheerder kan deze gegevens aanpassen",
    director: 'Bestuurder',
    shareholder: 'Vennoot',
    noOffice:'Staat uw kantoor niet in de lijst? Neem dan contact op met het ITAA  (<a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>).'
  },
  fr: {
    title: "Composition du cabinet",
    title_amounts: "Nombre de personne(s) travaillant au cabinet",
    title_information:
      "Afin de répondre aux obligations de la législation anti-blanchiment et d'évaluation de la qualité, vous devez informer l'ITAA de la composition de votre bureau.",
    explanation:
      "Cette liste ne peut être gérée que par la personne de contact principale du cabinet. (N.B. : Dans le cas où un sous - traitant travaille exclusivement pour votre cabinet ou s’il y exerce une fonction permanente, celui - ci fera l’objet d’une revue dans votre cabinet.Si ce n’est pas le cas, il sera considéré comme ayant son propre cabinet et une revue des dossiers et de l’organisation du cabinet sera exécutée chez lui sur place.)",
    not_available: "Composition du cabinet n'est pas disponible pour ce bureau.",
    amount_one: "Professionnel(s) actif(s)au sein du cabinet",
    amount_two:
      "Membre(s) interne(s) ITAA (employé(s)) actif(s) au sein du cabinet",
    amount_three: "Stagiaire(s) extern(e) ITAA actif(s) au sein du cabinet",
    amount_four: "Stagiaire(s) intern(e) ITAA actif(s) au sein du cabinet",
    amount_five:
      "Membre(s) IRE actif(s) au sein du cabinet, quelle que soit la forme de collaboration",
    amount_six:
      "Autre(s) personne(s) physique(s) active(s) au sein du cabinet avec un statut d'employé",
    total: "Nombre de personne(s) travaillant au cabinet",
    add: "+ + Ajouter une personne au cabinet",
    search: "Rechercher",
    header_name: "Nom",
    modal_title: "AJOUTER UNE NOUVELLE PERSONNE AU CABINET",
    modal_description: "Nom de la personne",
    modal_text:
      'Vous ne pouvez ajouter que des membres externes ou des stagiaires externes qui sont enregistrés dans la base de données de l’ITAA. En cas de questions, veuillez contacter <a href="mailto:servicedesk@itaa.be">servicedesk@itaa.be</a>.',
    modal_add: "Ajouter",
    modal_success: "SUCCÈS",
    modal_success_explanation:
      "La personne a été ajoutée à la liste. Cela peut prendre un certain temps avant que vos modifications ne soient visibles sur le portail.",
    modal_success_cancel: "Fermer",
    text: "Gestion de la composition du cabinet.",
    warningMainContact:
      "Seul le contact principal ou co-administrateur peut modifier ces informations",
    director: 'Directeur',
    shareholder: 'Associé',
    noOffice: "Si votre bureau ne se trouve pas dans la liste, prenez contact avec l’ITAA :servicedesk@itaa.be."
  },
};
