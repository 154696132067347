import GeneralAssembly from "./GeneralAssembly";

export default {
    nl: {
        selectedPersonRequired: 'Naam persoon is een verplicht veld.',
        selectedToolsRequired: 'Tools is een verplicht veld.',
        firstNameRequired: 'Voornaam is een verplicht veld.',
        lastNameRequired: 'Naam is een verplicht veld.',
        emailFormat: 'Vul een geldig email adres in.',
        webpage: 'Vul een geldige webpagina in.',
        genderRequired: 'Geslacht is een verplicht veld.',
        languageRequired: 'Taalrol is een verplicht veld.',
        nationalityRequired: 'Nationaliteit is een verplicht veld.',
        birthdateRequired: 'Geboortedatum is een verplicht veld.',
        nationalRegistrationNumber: 'Onjuist rijksregisternummer.',
        phoneNumber: 'Vul een geldig telefoonnummer in.',
        mandateAdminConflict:'Er is al een mandatenbeheerder (maximum 1) aangeduid. Gelieve eerst de vorige te verwijderen.',
        allRequiredFields: 'Vul alle verplichte velden (*) in.',
        startDateBeforeEndDate: 'Start moet vroeger zijn dan einde.',
        selectRole: 'Duid eerst een functie aan',
        generalAssembly:{
            // General
            general: "Er liep iets fout bij het verwerken van uw aanvraag",
          
            // General Assembly Errors
            GA_REG_001: "Algemene vergadering bestaat niet.",
            GA_REG_002: "Registratie gesloten.",
            
            GA_PHY_001: "Er zijn geen plaatsen meer beschikbaar.",
          
            // Proxy Errors
            GA_PROXY_001: "Volmachtstemming gesloten.",
            GA_PROXY_002: "Kan geen volmacht voor zichzelf zijn.",
            GA_PROXY_003: "Er is geen volmacht geselecteerd voor de volmacht.",
            GA_PROXY_004: "De geselecteerde persoon heeft al 2 volmachten. Gelieve een andere persoon te selecteren.",
            GA_PROXY_005: "Kan de volmacht niet aanmaken.",
            GA_PROXY_006: "Kan de volmacht niet bijwerken.",
            GA_PROXY_007: "Kan de opgegeven natuurlijke persoon voor de volmacht niet vinden.",
            GA_PROXY_008: "Een digitale handtekening is vereist om deze actie te voltooien.",
          
            // Participant Errors
            GA_PARTICIPANT_001: "Kan de opgegeven natuurlijke persoon niet vinden.",
            GA_PARTICIPANT_002: "Kan de deelnemer aan de algemene vergadering niet aanmaken.",
            GA_PARTICIPANT_003: "Kan de deelnemer aan de algemene vergadering niet bijwerken."
          }
          
    },
    fr: {
        selectedPersonRequired: 'Nom de la personne est un champ obligatoire.',
        selectedToolsRequired: 'Outils est un champ obligatoire.',
        firstNameRequired: 'Prénom est un champ obligatoire.',
        lastNameRequired: 'Nom est un champ obligatoire.',
        emailFormat: 'Mettez une adresse email valide.',
        webpage: 'Mettez une page web valide.',
        genderRequired: 'Sexe est un champ obligatoire.',
        languageRequired: 'Rôle linguistique est un champ obligatoire.',
        nationalityRequired: 'Nationalité est un champ obligatoire.',
        birthdateRequired: 'Date de naissance est un champ obligatoire.',
        nationalRegistrationNumber: 'Numéro de registre national incorrect.',
        phoneNumber: 'Mettez un numéro de téléphone valide.',
        mandateAdminConflict:"Un gestionnaire de mandats (maximum 1) a déjà été désigné. Veuillez d’abord supprimer le précédent.",
        allRequiredFields: 'Remplissez tous les champs obligatoires (*).',
        startDateBeforeEndDate: 'Le début doit être antérieur à la fin.',
        selectRole: "Veuillez d'abord sélectionner un rôle",
        generalAssembly:{
            // General
            general: "Une erreur s'est produite lors du traitement de votre demande",
          
            // General Assembly Errors
            GA_REG_001: "L'assemblée générale n'existe pas.",
            GA_REG_002: "Inscription fermée.",
            
            GA_PHY_001: "Il n'y a plus de places disponibles.",
          
            // Proxy Errors
            GA_PROXY_001: "Le vote par procuration est fermé.",
            GA_PROXY_002: "Impossible d'être procuration pour soi-même.",
            GA_PROXY_003: "Aucune procuration sélectionnée pour la procuration.",
            GA_PROXY_004: "La personne sélectionnée dispose déjà de 2 procurations. Veuillez sélectionner une autre personne.",
            GA_PROXY_005: "Impossible de créer la procuration.",
            GA_PROXY_006: "Impossible de mettre à jour la procuration.",
            GA_PROXY_007: "Impossible de trouver la personne physique spécifiée pour la procuration.",
            GA_PROXY_008: "Une signature numérique est requise pour compléter cette action.",
          
            // Participant Errors
            GA_PARTICIPANT_001: "Impossible de trouver la personne physique spécifiée.",
            GA_PARTICIPANT_002: "Impossible de créer le participant à l'assemblée générale.",
            GA_PARTICIPANT_003: "Impossible de mettre à jour le participant à l'assemblée générale."
          }
    }
}