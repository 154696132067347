import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { patchNaturalPersonInformationValidation } from '../http/HttpClientHelper.jsx';
import { useTranslation } from 'react-i18next';
import {UserCompany} from '../../../../models/UserCompany.tsx';
import {UserOffice} from '../../../../models/UserOffice.tsx';
import { getLocalStorageUserCompanies, getLocalStorageUserOffices } from '../../../../utils/storage/localStorage.jsx';
import { STORAGE_KEYS } from '../../../../utils/storage/storageKeys.jsx';

const LandingPageModal = ({ showModal, handleClose, validationDataToModal, canIgnore, userDetail, setShowModal }) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [remainingPostponements, setRemainingPostponements] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [offices, setOffices] = useState([]);

    const handleIgnore = async () => {
        await patchNaturalPersonInformationValidation(userDetail?.id, {
            hasIgnored: true,
            hasConfirmed: false,
        })
        handleClose();
    };

    const handleNaturalPersonConfirmClick = async () => {
        setIsLoading(true);
        await patchNaturalPersonInformationValidation(userDetail?.id, {
            hasIgnored: false,
            hasConfirmed: true,
        });
        localStorage.removeItem(STORAGE_KEYS.CURRENT_USER);
        setShowModal(false);
    };

    useEffect(() => {

        const companiesData = getLocalStorageUserCompanies()
        const officesData = getLocalStorageUserOffices()
        
        setCompanies(companiesData.map((company: UserCompany) => Object.assign(new UserCompany(), company)));
        setOffices(officesData.map((office: UserOffice) => Object.assign(new UserOffice(), office)));
        const remaining = validationDataToModal.maxPostponements - validationDataToModal.currentPostponements;
        setRemainingPostponements(remaining);

        if (remaining === 1) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [validationDataToModal]);

    return (
        <Modal show={showModal}>

            <Modal.Header>
                <Modal.Title>{t('confirmation.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('confirmation.descriptionPersonal')}</p>
                {canIgnore !== false &&
                    <p>{t('confirmation.descriptionPersonalCanIgnore')}</p>
                }

                <p>{t('confirmation.descriptionCategories')}</p>

                <p>{t('confirmation.descriptionCompanyOffice')}</p>

                {canIgnore !== false &&
                    <p>{t('confirmation.countDown', { remaining: remainingPostponements })}</p>
                }

                <p>
                    {t('confirmation.labelCategories')} <br />
                    <span className='categoryblock'>{t('sidebar.profile')}</span>
                    {companies.length > 0 && (
                        <span className='categoryblock'>{t('sidebar.company')}</span>
                    )}
                    {offices.length > 0 && (
                        <span className='categoryblock'>{t('sidebar.office')}</span>
                    )}
                </p>

                <p>
                    {t('confirmation.labelEmail')} <br />
                    <span>{userDetail.email}</span>
                </p>

                <p>{t('confirmation.descriptionEditEmail')}</p>
                <p>{t('confirmation.descriptionEmailInfo')}</p>

                {showAlert && (
                    <div className='alert alert-danger mt-4 mb-0'>
                        <span>{t('confirmation.alertMaxPostponements')}</span>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {canIgnore !== false && (
                    <Button variant="primary" onClick={handleIgnore}>
                        {t('confirmation.cancel')}
                    </Button>
                )}

                <button className="btn btn-eloket" onClick={handleNaturalPersonConfirmClick}>
                    <i className="fa fa-calendar-check-o mr-2"></i> {t('confirmation.title')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default LandingPageModal;
