import apiClient from "../../../../../api/client/apiClient";

export const getEstoxCompanies = (naturalPersonId) => {
    return apiClient
        .get(`/api/Users/${naturalPersonId}/estoxcompanies`)
        .then((response) => response.data);
}

export const getEstoxLink = (naturalPersonId, entityId, entityTypeToGenerateLinkFor, estoxEnvironmentType) => {
    const url = `/api/Users/${naturalPersonId}/estoxlink`;
    const data = {
        naturalPersonId,
        entityId,
        entityTypeToGenerateLinkFor,
        estoxEnvironmentType,
    };

    return apiClient
        .post(url, data)
        .then((response) => {
            return response.data;
        });
};

export const getExamUrl = (naturalPersonId, examType) => {
    return apiClient
         .get(`/api/Users/${naturalPersonId}/visibilities/ExamRegistrationUrl`, {
            params: {
                examType: examType
            }
        })
        .then((response) => response.data.data);
}


  export const getEstoxExternalProviderShare = (naturalPersonId, callbackUrl) => {
    return apiClient
      .post(`/api/Users/estox/${naturalPersonId}/externalProviderShare`, {
        naturalPersonId,   
        callbackUrl        
      })
      .then((response) => {
        return response.data;  
      })
  };
  
  