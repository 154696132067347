import React, { useState, useEffect, useRef } from "react";
import { getGeneralAssemblyProxies } from './http/HttpClientHelper.tsx';
import { useTranslation } from "react-i18next";
import { ListGroup } from 'react-bootstrap';  
import { GetGeneralAssemblyProxyMembersQueryResult } from "../../../../models/ActiveGeneralAssembly";
import { CurrentUserModel } from "../../../../models/CurrentUserModel.tsx";

interface DropdownProps {
  onSelect: (selectedId: string) => void;
  userDetail: CurrentUserModel;
}

const DropdownWithActiveTrackPhaseType: React.FC<DropdownProps> = ({ onSelect, userDetail }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [results, setResults] = useState<GetGeneralAssemblyProxyMembersQueryResult[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(async () => {
      if (query.length > 2) {
        setLoading(true);
        const proxies = await getGeneralAssemblyProxies(query, userDetail.id);
        setResults(proxies);
        setShowResults(true);
        setLoading(false);
      } else {
        setResults([]);
        setShowResults(false);
      }
    }, 500);
  };

  const handleSelectPerson = (person: GetGeneralAssemblyProxyMembersQueryResult, e: React.MouseEvent) => {
    e.preventDefault();
    setSearchQuery(person.fullName);
    setShowResults(false);
    onSelect(person.naturalPersonId);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current && 
      !dropdownRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return (
    <div className="relative w-full">
      <section>
        <label className="form-label">{t("generalAssembly.registration.optionMenu.proxy.choose")}</label>
        <input
          ref={inputRef}
          type="text"
          value={searchQuery}
          placeholder={t("generalAssembly.registration.optionMenu.proxy.chooseConfratorPlaceholder")}
          onChange={handleSearchChange}
          className="form-control w-100"
          onFocus={() => setShowResults(true)}
        />
        {showResults && searchQuery.length > 2 && (
          <div
            ref={dropdownRef}
            className="absolute w-full max-h-60 overflow-y-auto border mt-2 rounded bg-white shadow-lg z-10"
            style={{ maxHeight: '200px' }}
          >
            <ListGroup>
              {loading ? (
                <ListGroup.Item className="text-center">
                  <div className="spinner-border" role="status" />
                </ListGroup.Item>
              ) : results.length > 0 ? (
                results.map((person: GetGeneralAssemblyProxyMembersQueryResult) => (
                  <ListGroup.Item
                    key={person.itaaNumber}
                    onMouseDown={(e) => handleSelectPerson(person, e)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    {person.fullName} | {person.itaaNumber}
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item className="text-center text-gray-500">
                  {t("generalAssembly.registration.optionMenu.NoPersonFound")}
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
        )}
      </section>
    </div>
  );
};

export default DropdownWithActiveTrackPhaseType;
