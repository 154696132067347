import apiClient from "../../../../../api/client/apiClient";

export const getAntiMoneyLaunderingData = (legalPersonId) => {
  return apiClient
    .get(`/api/offices/${legalPersonId}/antimoneylaunderingsetup`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const postAntiMoneyLaunderingData = (
  legalPersonId,
  antiMoneyLaunderingData
) => {
  return apiClient
    .post(
      `/api/offices/${legalPersonId}/antimoneylaunderingsetup`,
      antiMoneyLaunderingData
    )
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const getAntiMoneyLaunderingRolesData = (legalPersonId) => {
  return apiClient
    .get(`/api/offices/${legalPersonId}/antimoneylaunderingroles`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const postAntiMoneyLaunderingRolesData = (
  legalPersonId,
  naturalPersonId,
  selectedRole
) => {
  const antiMoneyLaunderingRolesData = {
    naturalPersonId: naturalPersonId,
    role: selectedRole,
  };

  return apiClient
    .post(
      `/api/offices/${legalPersonId}/antimoneylaunderingroles`,
      antiMoneyLaunderingRolesData
    )
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const deleteAntiMoneyLaunderingRolesData = (connectionId) => {
  return apiClient
    .delete(`/api/offices/${connectionId}/antimoneylaunderingroles`)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const searchNaturalPersons = async (search) => {
  try {
    const response = await apiClient
      .get(`/api/offices/antimoneylaunderingroles/amlco/search/${search}`);
    return response.data;
  } catch (error) {
    throw new Error(`HTTP error! ${error}`);
  }
};
