import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const QualificationTableCell = ({ item, isMobile }) => {
    const { t } = useTranslation();
    const [content, setContent] = useState("-");

    useEffect(() => {
        if (!item.officialTypes?.length && !item.itaaQualifications?.length) {
            setContent("-");
            return;
        }
        const officialTypes = item?.officialTypes || [];
        const itaaQualifications = item?.itaaQualifications || [];
        const trackResults = officialTypes.map((track) => {
            const qualificationId = track.qualificationId;

            if (!qualificationId) {
                return "-"; 
            }

            const qualification = itaaQualifications?.find(q => q.id === qualificationId);
            
            return qualification ? t(`qualifications.${qualification.name}`) : null ;
        });

        const independentQualifications = itaaQualifications
            .filter(q => !officialTypes.some(track => track.qualificationId === q.id))
            .map(q => t(`qualifications.${q.name}`));

        const hasValidQualifications = trackResults.some(q => q !== "-") || independentQualifications.length > 0;

        const allResults = hasValidQualifications 
            ? [...trackResults ,...independentQualifications, ].filter(Boolean).join(" | ") 
            : "-";

        setContent(allResults);
    }, [item, t]);

    return isMobile ? (
        <div className="pill-badge-primary">{content}</div>
    ) : (
        <td>{content}</td>
    );
};

export default QualificationTableCell;
