import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { searchAccessToolsCandidates } from '../../AccessTools/http/HttpClientHelper'
import { formatItaaNumber } from '../../../../../utils/formatUtils.tsx';

const AutocompleteResults = ({ inputValue, handleSelection, legalPersonId }) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                if (!mounted) {
                    setLoading(true);
                    const response = await searchAccessToolsCandidates(legalPersonId, inputValue);
                    setResults(response);
                }
                setLoading(false);
            };

            if (inputValue.trim() !== '') {
                fetchAutocompleteResults();
            } else {
                setResults([]);
            }
        }, 200);

        return () => {
            clearTimeout(delaySearch);
            setMounted(false);
        }
    }, [inputValue, legalPersonId, mounted]);

    const handleListItemClick = async (result) => {
        await handleSelection(result);
        setResults([]);
    };

    return (
        <ListGroup>
            {loading && (
                <ListGroup.Item className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </ListGroup.Item>
            )}
            {!loading && results.map((result) => (

                <ListGroup.Item key={result.naturalPersonId} onClick={() => handleListItemClick(result)}>
                    {result.fullName} ({result.city}), {formatItaaNumber(result.itaaNumber)}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

export default AutocompleteResults;