import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { formatNationalRegistrationNumber, formatDatePicker } from '../../../../utils/formatUtils.tsx';
import { getLanguages, getGenders, getNationalities } from '../AccessTools/http/HttpClientHelper';
import { ValidateDate, ValidateEmail, ValidateBelgianNationalRegistrationNumber, ValidateRequiredSingleSelect, ValidateRequiredText } from '../../../../utils/validationUtils';
import Validation from '../../../Validation/Validation';
import NewUser from '../../../../models/naturalPerson/NewUser';
import showErrorToast from '../../../Toast/Toast';
import { postNewUser } from './http/HttpClientHelper';

const AddNewEmployee = ({ show, handleClose }) => {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState([]);
    const [genders, setGenders] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedNationality, setSelectedNationality] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [birthdate, setBirthdate] = useState(null);
    const [nationalRegistrationNumber, setNationalRegistrationNumber] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChangeLanguage = (event) => setSelectedLanguage(event.target.value);
    const handleChangeGender = (event) => setSelectedGender(event.target.value);
    const handleChangeEmail = (event) => setEmail(event.target.value);
    const handleChangeFirstName = (e) => setFirstName(e.target.value);
    const handleChangeLastName = (e) => setLastName(e.target.value);
    const handleBirthdateChange = (event) => {
        const formattedDate = formatDatePicker(event.target.value);
        setBirthdate(formattedDate);
    }

    const handleNationalRegistrationNumberChange = (e) => {
        const formattedValue = formatNationalRegistrationNumber(e.target.value);
        setNationalRegistrationNumber(formattedValue);
    };

    const validateFields = () => {
        const validationErrors = {};

        ValidateRequiredText(firstName) || (validationErrors.firstName = t('validation.firstNameRequired'));
        ValidateRequiredText(lastName) || (validationErrors.lastName = t('validation.lastNameRequired'));
        ValidateEmail(email) || (validationErrors.email = t('validation.emailFormat'));
        ValidateRequiredSingleSelect(selectedGender) || (validationErrors.gender = t('validation.genderRequired'));
        ValidateRequiredSingleSelect(selectedLanguage) || (validationErrors.language = t('validation.languageRequired'));
        ValidateRequiredSingleSelect(selectedNationality) || (validationErrors.nationality = t('validation.nationalityRequired'));
        ValidateDate(birthdate) || (validationErrors.birthdate = t('validation.birthdateRequired'));
        ValidateBelgianNationalRegistrationNumber(nationalRegistrationNumber, birthdate, selectedGender) || (validationErrors.nationalRegistrationNumber = t('validation.nationalRegistrationNumber'));

        setErrors(validationErrors);
        return validationErrors;
    }

    const handleSubmit = () => {
        const validationErrors = validateFields();

        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        setIsConfirmModalVisible(true);
    };

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(true);
        setLoading(true);
        const [birthdayDay, birthdayMonth, birthdayYear] = birthdate.split('/').map(Number);
        const birthdateParsed = new Date(birthdayYear, birthdayMonth - 1, birthdayDay); 
        const newUser = new NewUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
            gender: selectedGender,
            language: selectedLanguage,
            nationality: selectedNationality,
            dateOfBirth: birthdateParsed,
            nationalNumber: nationalRegistrationNumber
        });
        var naturalPersonId = await postNewUser(newUser);
        if (naturalPersonId && naturalPersonId !== '00000000-0000-0000-0000-000000000000') {
            setIsSuccess(true);
            setIsConfirmModalVisible(false);
            handleClose();
            setLoading(false);
        }
        else {
            setIsConfirmModalVisible(false);
            setLoading(false);
            showErrorToast(t('errors.addUser'));
        }
    };

    const handleCancelSubmit = () => {
        setIsConfirmModalVisible(false);
    }

    useEffect(() => {
        getLanguages().then(setLanguages);

        getGenders().then(setGenders);

        getNationalities().then(nationalities => {
            setNationalities(nationalities);

            const belgianNationality = nationalities.find(nat => nat.name === 'Belgian');
            if (belgianNationality) {
                setSelectedNationality(belgianNationality.id);
            }
        });
    }, []);

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isConfirmModalVisible || isSuccess ? (
                <>
                    {isConfirmModalVisible ? (
                        <Modal show={isConfirmModalVisible} onHide={handleCancelSubmit}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelSubmit}>
                                    {t("popup.cancel")}
                                </Button>
                                <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                                    {t("confirmation.confirm")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    ) : (
                        <Modal show={isSuccess} onHide={handleCloseSuccess}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <small>{t('popup.successUpdate')}</small>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-between">
                                <Button
                                    variant="secondary"
                                    className="btn-eloket"
                                    onClick={handleCloseSuccess}>
                                    {t('mandate_application.modal_success_close')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </>
            )
                : (
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('access_tools.title_new_person')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="formNetworkName">
                                <Row>
                                    <Col>
                                        <Form.Label>{t('itaadata.firstName')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={firstName}
                                            onChange={handleChangeFirstName}
                                            autoComplete='off'
                                            required
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('itaadata.lastName')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={lastName}
                                            onChange={handleChangeLastName}
                                            autoComplete='off'
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>{t('itaadata.email')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={email}
                                            onChange={handleChangeEmail}
                                            autoComplete='off'
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>{t('itaadata.gender')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedGender}
                                            onChange={handleChangeGender}
                                            autoComplete='off'
                                            required
                                        >
                                            <option value="" disabled>{t(`itaadata.genders.Select`)}</option>
                                            {genders?.map(gender => (
                                                <option key={gender.id} value={gender.id}>
                                                    {t(`itaadata.genders.${gender.name}`)}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('itaadata.language')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedLanguage}
                                            onChange={handleChangeLanguage}
                                            autoComplete='off'
                                            required
                                        >
                                            <option value="" disabled>{t(`itaadata.languages.Select`)}</option>
                                            {languages?.map(language => (
                                                <option key={language.id} value={language.id}>
                                                    {t(`itaadata.languages.${language.name}`)}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>{t('itaadata.nationality')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedNationality}
                                            autoComplete='off'
                                            required
                                            disabled={true}
                                        >
                                            {nationalities.map(nationality => (
                                                <option key={nationality.id} value={nationality.id}>
                                                    {t(`itaadata.nationalities.${nationality.name}`)}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('itaadata.bithDate')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <input
                                                type="text"
                                                value={birthdate}
                                                onChange={handleBirthdateChange}
                                                placeholder={t('itaadata.birthDatePlaceholder')}
                                                className='form-control'
                                                autoComplete='off'
                                                required
                                            />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>{t('itaadata.nationalRegistrationNumber')} <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={nationalRegistrationNumber}
                                            onChange={handleNationalRegistrationNumberChange}
                                            autoComplete='off'
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='alert alert-danger mb-0 mt-2'>
                                            <span> {t('directorAndPartner.newContactNationalityWarning')}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <Validation errors={errors} />
                            </Form.Group>
                            <Modal.Footer className="justify-content-between access_tools_footer">
                                <Button variant="secondary" onClick={handleClose}>
                                    {t('popup.cancel')}
                                </Button>
                                <Button
                                    className="btn-eloket"
                                    variant="secondary"
                                    onClick={handleSubmit}
                                >
                                    {t('office_composition.modal_add')}
                                </Button>
                            </Modal.Footer>
                        </Modal.Body>
                    </Modal>
                )}

        </>
    );
};

export default AddNewEmployee;