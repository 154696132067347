import apiClient from "../../../../../api/client/apiClient";

export const getUserCompanies = (userId) => {
  return apiClient
    .get(`/api/Profiles/${userId}/companies`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
