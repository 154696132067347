import React from "react";
import { useTranslation } from "react-i18next";

import { MembershipInsurance } from "../../../models/MembershipInsurance.tsx";
import { formatShortDateAsLocalDate } from "../../../utils/formatUtils.tsx";
import { ApplicationStatus } from "../../../Enums/ApplicationStatus.tsx";

interface InsuranceTableProps {
    insurances: MembershipInsurance[] | undefined;
    handleEditClick: (insurance: MembershipInsurance) => void;
}

const InsuranceTable: React.FC<InsuranceTableProps> = ({ insurances, handleEditClick }) => {
        const { t } = useTranslation();
    
    return (
        <div className="form-group">
            <table className="table table-wrapper">
                <thead>
                    <tr>
                        <th>{t('insurance.insurer')}</th>
                        <th>{t('insurance.policyNumber_collective')}</th>
                        <th>{t('insurance.accessionNumber')}</th>
                        <th>{t('insurance.startDate')}</th>
                        <th>{t('insurance.endDate')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {insurances && insurances
                        .sort((a, b) => {
                            const dateFromA = a.coveragePeriodFrom ? new Date(a.coveragePeriodFrom) : new Date(0);
                            const dateFromB = b.coveragePeriodFrom ? new Date(b.coveragePeriodFrom) : new Date(0);
                            if (isNaN(dateFromA.getTime()) || isNaN(dateFromB.getTime())) {
                                return 0;
                            }
                            return dateFromA.getTime() - dateFromB.getTime();
                        })
                        .map((insurance, index) => (
                            <tr key={index}>
                                <td>{insurance.insurer}</td>
                                <td>{insurance.policyNumber}</td>
                                <td>{insurance.accessionNumber ?? '/'}</td>
                                <td>{formatShortDateAsLocalDate(insurance.coveragePeriodFrom)}</td>
                                <td>{formatShortDateAsLocalDate(insurance.coveragePeriodUntil)}</td>
                                <td>
                                    {(() => {
                                        switch (insurance.applicationStatus?.id) {
                                            case ApplicationStatus.Success:
                                                return <div className="pill-badge primary">{t('insurance.badgeSuccess')}</div>;
                                            case ApplicationStatus.InProgress:
                                                return <div className="pill-badge gold">{t('insurance.badgeInProgress')}</div>;
                                            case ApplicationStatus.Error:
                                                return <div className="pill-badge red">{t('insurance.badgeError')}</div>;
                                        }
                                    })()}
                                </td>
                                <td>
                                    {(insurance.file?.content && insurance.applicationStatus?.id === ApplicationStatus.Success) && (
                                        <a
                                            href={`data:application/pdf;base64,${insurance.file.content}`}
                                            download={insurance.file.fileName}
                                            className="download-link"
                                        >
                                            {t('insurance.download')}
                                        </a>
                                    )}
                                </td>
                                <td className="lastColumn">
                                    {insurance.applicationStatus?.id !== ApplicationStatus.InProgress && (
                                        <button className="btn btn-eloket" onClick={() => handleEditClick(insurance)}>
                                            {t('basicdata.button_edit')}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};


export default InsuranceTable;