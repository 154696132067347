import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deletePersonAccessTools, getAccessToolsData } from './http/HttpClientHelper';
import AddExisting from './add/AddExisting';
import AddNew from './add/AddNew';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import { FunctionsEnum } from '../../../../Enums/FunctionsEnum';
import OfficialTypeTableCell from '../../MembershipInformation/OfficialTypeTableCell';
import QualificationTableCell from '../../MembershipInformation/QualificationTableCell';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';
import { getOfficeRoles } from '../../Profile/OfficeRoles/http/HttpClientHelper';
import { OfficeRoleEnum } from '../../../../Enums/OfficeRoleEnum';

const AccessTools = ({ userDetail, isMobile }) => {
    const { t } = useTranslation();
    const { legalPersonId } = useParams();
    const [accessToolsData, setAccessToolsData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddExistingModal, setShowAddExistingModal] = useState(false);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [hasMandateUserAdmin, setHasMandateUserAdmin] = useState(false);
    const [currentUserIsMainContactOrCoAdmin, setCurrentUserIsMainContactOrCoAdmin] = useState(false);
    

    const fetchAccessToolsData = async () => {
        setLoading(true);

        const data = await getAccessToolsData(legalPersonId);
        setAccessToolsData(data);

        const officeRoles = await getOfficeRoles(userDetail.id);

        const isMainContactPersonOrCoAdmin = officeRoles.some(role => 
            (role.role === OfficeRoleEnum.MainContactPerson || role.role === OfficeRoleEnum.CoAdministrator) && role.company.id === legalPersonId
        );

        setCurrentUserIsMainContactOrCoAdmin(isMainContactPersonOrCoAdmin);
    
        setLoading(false);
    };
    

    useEffect(() => {
        fetchAccessToolsData();
    }, [legalPersonId, reloadTrigger]);

    useEffect(() => {
        if (accessToolsData) {
            const hasAdmin = accessToolsData.listToolAccess.some(manager =>
                manager.toolIds.some(toolId => toolId.value === FunctionsEnum.MandateAdmin)
            );
            setHasMandateUserAdmin(hasAdmin);
        }
    }, [accessToolsData]);

    const filteredToolAccess = useMemo(() => {
        return accessToolsData
            ? accessToolsData.listToolAccess.filter(manager => manager.name.toLowerCase().includes(searchQuery.toLowerCase()))
            : [];
    }, [accessToolsData, searchQuery]);

    const sortedToolAccess = useMemo(() => {
        return filteredToolAccess.sort((a, b) => {
            const aHasMandateAdmin = a.toolIds.some(toolId => toolId.value === FunctionsEnum.MandateAdmin);
            const bHasMandateAdmin = b.toolIds.some(toolId => toolId.value === FunctionsEnum.MandateAdmin);

            if (aHasMandateAdmin && !bHasMandateAdmin) {
                return -1;
            }
            if (!aHasMandateAdmin && bHasMandateAdmin) {
                return 1;
            }

            return a.name.localeCompare(b.name);
        });
    }, [filteredToolAccess]);

    const amountToolAccess = accessToolsData ? accessToolsData.listToolAccess.length : 0;

    const triggerReload = () => {
        setReloadTrigger(prevState => !prevState);
    };

    const handleDelete = async (selectedPersonId, toolId) => {
        setLoading(true);
        const deletePersonAccessToolsParams = {
            id: legalPersonId,
            userId: selectedPersonId,
            functionId: toolId.value
        };
        await deletePersonAccessTools(deletePersonAccessToolsParams);
        triggerReload();
        setLoading(false);
    };

    const renderToolBadges = (toolIds, naturalPersonId) => {
        return toolIds.map(toolId => (
            <span key={`${toolId.value}-${naturalPersonId}`} className="pill-badge primary">
                {t(`access_tools.${toolId.name}`)}
                {currentUserIsMainContactOrCoAdmin && (
                    <button onClick={() => handleDelete(naturalPersonId, toolId)}>X</button>
                )}
            </span>
        ));
    };

    if (!accessToolsData) {
        return (
            <div id="main">
                {loading && (
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                )}
                <div id="content">
                    <div className="text-h1">{t('access_tools.title')}</div>
                    <div className="form-group">
                        <div className="form-row">
                            <p className="text-description"></p>
                        </div>
                        <div>
                            <p className="text-description">{t('access_tools.amount')}</p>
                        </div>
                        <div className="form-row">
                            <div className="controls">
                                <div className="form-col">
                                    <input className="form-control" type="text" placeholder="Zoeken" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <table className="table .table-wrapper">
                                <thead>
                                    <tr>
                                        <th>{t('access_tools.header_name')}</th>
                                        <th>{t('access_tools.header_qualification')}</th>
                                        <th>{t('access_tools.header_type')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                    </div>
                    <footer>
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="button">
                                {t('access_tools.button_add_existing_person')}
                            </button>
                            <button className="btn btn-eloket" type="button">
                                {t('access_tools.button_add_new_person')}
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Company}
                />
                <div className="text-h1">{t('access_tools.title')}</div>
                <div className="form-group">
                    <div>
                        <p className="text-description">
                            {t('access_tools.amount')}{amountToolAccess}
                        </p>
                    </div>
                    <div className="form-row">
                        <div className="controls">
                            <div className="form-col">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Zoeken"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('access_tools.header_name')}</th>
                                    {!isMobile && (
                                        <>
                                            <th>{t('access_tools.header_qualification')}</th>
                                            <th>{t('access_tools.header_type')}</th>
                                            <th>{t('access_tools.header_tools')}</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedToolAccess?.map((manager, index) => (
                                    <tr key={index}>
                                        <td>{manager.name}</td>
                                        {isMobile ? (
                                            <td className="badges-office-composition">
                                                <QualificationTableCell item={manager} isMobile={isMobile} />
                                                <OfficialTypeTableCell item={manager} isMobile={isMobile} />
                                                {renderToolBadges(manager.toolIds, manager.naturalPersonId)}
                                            </td>
                                        ) : (
                                            <>
                                                <QualificationTableCell item={manager} isMobile={isMobile} />
                                                <OfficialTypeTableCell item={manager} isMobile={isMobile} />
                                                <td>{renderToolBadges(manager.toolIds, manager.naturalPersonId)}</td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                {showAddExistingModal && (
                    <AddExisting
                        show={showAddExistingModal}
                        handleClose={() => setShowAddExistingModal(false)}
                        legalPersonId={legalPersonId}
                        hasMandateUserAdmin={hasMandateUserAdmin}
                    />
                )}
                {showAddNewModal && (
                    <AddNew
                        show={showAddNewModal}
                        handleClose={() => setShowAddNewModal(false)}
                        legalPersonId={legalPersonId}
                        hasMandateUserAdmin={hasMandateUserAdmin}
                    />
                )}

                <footer>
                    {currentUserIsMainContactOrCoAdmin && (
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="button" onClick={() => setShowAddExistingModal(true)}>
                                {t('access_tools.button_add_existing_person')}
                            </button>
                            <button className="btn btn-eloket" type="button" onClick={() => setShowAddNewModal(true)}>
                                {t('access_tools.button_add_new_person')}
                            </button>
                        </div>
                    )}
                </footer>
            </div>
        </div>
    );
};

export default AccessTools;
