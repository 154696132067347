import apiClient from "../../../../../api/client/apiClient";

export const getCompanyDetails = async (legalPersonId) => {
    const response = await apiClient.get(
        `/api/companies/${legalPersonId}/details`
    );
    return response.data;
};

export const patchCompanyDetails = async (legalPersonId, detailsToUpdate) => {
    const communications = [];
    communications.push({
        id: detailsToUpdate.generalEmailCommunicationId,
        value: detailsToUpdate.generalEmail,
        addressId: detailsToUpdate.registeredOfficeAddressId,
        communicationType: { id: 175470002, name: 'Phonenumber' }
    });
    communications.push({
        id: detailsToUpdate.registeredOfficeTelephoneCommunicationId,
        value: detailsToUpdate.registeredOfficeTelephone,
        addressId: detailsToUpdate.registeredOfficeAddressId,
        communicationType: { id: 175470000, name: 'Email' }
    });
    communications.push({
        id: detailsToUpdate.generalWebsiteCommunicationId,
        value: detailsToUpdate.generalWebsite,
        addressId: detailsToUpdate.registeredOfficeAddressId,
        communicationType: { id: 175470003, name: 'Website' }
    });

    let body = {
        communications,
        registeredOfficeAddressId: detailsToUpdate.registeredOfficeAddressId
    };

    const response = await apiClient.patch(
        `/api/companies/${legalPersonId}/details`,
        body
    );
    return response.data;
};

export const getOffice = async (legalPersonId) => {
    const response = await apiClient
        .get(`/api/offices/${legalPersonId}/detail`);
    return response.data;
};