export default {
  nl: {
    title: "TOEGANG TOT DE MANDATENTOEPASSING",
    choseLegalEntityText:
      "Kies uit onderstaande lijst in naam van welke entiteit u activiteiten uitvoert. Indien uw keuze niet in de lijst voorkomt, neem dan contact op met de hoofdcontactpersoon van het kantoor.",
    infoText:
      "Een dossier consulteren of behandelen waarvoor geen mandaat bestaat, kan aanleiding geven tot schade en juridische gevolgen. Hier dient men dus voorzichtig mee om te gaan, rekening houdend met de deontologische voorschriften. <u>Alle raadplegingen en wijzigingen worden geregistreerd.</u>",
    selectTitle: "Maak een keuze",
    bosaLoginRequired: 'Om toegang te krijgen tot de mandatenbeheer toepassing dient u zich aan te melden via E-ID of Itsme.',
    bosaLoginRequired2: 'Aanmelden via gebruikersnaam en wachtwoord is voor deze toepassing niet toegestaan.',
    mandateLoginStep1: 'Gelieve u af te melden via de knop "Afmelden"',
    mandateLoginStep2: 'Gelieve u aan te melden op het ITAA portaal via E-ID of itsme',
    mandateLoginStep3: 'Ga daarna naar de module mandatenbeheer',
  },
  fr: {
    title: "ACCÈS À L'APPLICATION DE MANDATS",
    choseLegalEntityText:
      "Choisissez dans la liste ci-dessous au nom de quelle entité vous effectuez des activités. Si votre choix ne figure pas dans la liste, veuillez contacter la personne de contact principale du cabinet.",
    infoText:
      "Consulter ou traiter un dossier pour lequel aucun mandat n'existe peut entraîner des dommages et des conséquences juridiques. Il est donc nécessaire d'agir avec prudence en tenant compte des règles déontologiques. Toutes les consultations et modifications sont enregistrées.",
    selectTitle: "Faire un choix",
    bosaLoginRequired: 'Pour avoir accès à l’application de gestion des mandats, vous devez vous connecter via E-ID ou Itsme.',
    bosaLoginRequired2: 'Se connecter via un nom d’utilisateur et un mot de passe n’est pas autorisé pour cette application.',
    mandateLoginStep1: 'Veuillez vous déconnecter à l’aide du bouton "Déconnexion"',
    mandateLoginStep2: 'Veuillez vous connecter au portail de l’ITAA via E-ID ou itsme',
    mandateLoginStep3: 'Allez ensuite dans le module de gestion des mandats',
  },
};
