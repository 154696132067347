import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSessionContext } from "./../../providers/SessionProvider";
import { useTranslation } from "react-i18next";
import { log } from "./../../utils/log";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import SplashScreen from "../loader/SplashScreen";
import logo from "./../../assets/itaa-logo.png";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ Component, children, ...props }) => {
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    error,
    isLoading,
    loadingState,
    currentUser,
    clearSession,
  } = useSessionContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isSessionValid, setIsSessionValid] = useState(false);

  useEffect(() => {
    const validateSession = async () => {
      log.info("validating session");

      if (error === "login_required") {
        setSessionExpired(true);
        return;
      }
      if (error) {
        navigate("/auth-error", { state: { errormessage: error } });
        return;
      }

      if (!isLoading && !isAuthenticated && !error) {
        clearSession();
        loginWithRedirect();
        return;
      }
      setIsSessionValid(isAuthenticated && currentUser);
    };

    validateSession();
  }, [isLoading, isAuthenticated, error, currentUser]);

  const handleSessionExpiration = async () => {
    await logout();
    await loginWithRedirect();
  };

  if (sessionExpired) {
    return (
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>{t("privateroute.session_expired_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("privateroute.session_expired_body")}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-eloket" onClick={handleSessionExpiration}>
            {t("privateroute.login_again")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  if (loadingState.profile) {
    return <SplashScreen logoSrc={logo} />;
  }
  if (loadingState.impersonation) {
    return (
      <SplashScreen
        text={t("privateroute.loading_impersonation_title")}
        logoSrc={logo}
      />
    );
  }
  if (loadingState.stopImpersonation) {
    return (
      <SplashScreen
        text={t("privateroute.stopping_impersonation_title")}
        logoSrc={logo}
      />
    );
  }

  if (isSessionValid) {
    const ProtectedComponent = withAuthenticationRequired(Component, {
      onRedirecting: () => <SplashScreen logoSrc={logo} />,
    });

    if (children) {
      return <ProtectedComponent {...props}>{children}</ProtectedComponent>;
    }

    return <ProtectedComponent {...props}></ProtectedComponent>;
  }
};

export default PrivateRoute;
