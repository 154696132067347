export const ValidateRequiredText = (text) => {
    if (text === null || text === undefined || text === '')
        return false;
    return text.length > 0;
};

export const ValidateRequiredSingleSelect = (value) => {
    return value !== null && value !== undefined && value !== '';
}

export const ValidateRequiredMultiSelect = (value) => {
    if (value === null || value === undefined || value === '')
        return false;
    return value.length > 0;
}

export const ValidateDate = (date) => {
    // Splits de datum string in dag, maand en jaar
    const [day, month, year] = date.split('/').map(Number);

    // Basisvalidatie van dag, maand en jaar
    if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
    if (month < 1 || month > 12) return false; // Maand moet tussen 1 en 12 zijn

    // Definieer het aantal dagen per maand (niet rekening houdend met schrikkeljaren)
    const daysInMonth = {
        1: 31, 2: 28, 3: 31, 4: 30, 5: 31, 6: 30,
        7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31
    };

    // Controleer of het jaar een schrikkeljaar is
    const isLeapYear = (year) => (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));

    // Als het een schrikkeljaar is, februari heeft 29 dagen
    if (isLeapYear(year)) {
        daysInMonth[2] = 29;
    }

    // Controleer of de dag geldig is voor de opgegeven maand
    if (day < 1 || day > daysInMonth[month]) return false;

    return true;
};

export const ValidateDates = (date) => {
    if (!date)
        return false;
    if (!(new Date(date) instanceof Date && !isNaN(new Date(date))))
        return false;
    return true;
}

export const ValidateStartEndDateTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : null;

    if (!ValidateDates(startDate)) return false;
    if (!end) return true;
    if (!ValidateDates(endDate)) return false;
    if (startDate >= endDate) return false;

    return true;
}

export const ValidateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const ValidateWebsite = (website) => {
    if(!website || website === '')
        return true;

    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    if (!urlPattern.test(website)) {
        return false; 
    }
    return true; 
}

export const ValidatePhoneNumber = (phonenumber) => {
    if (!phonenumber) {
        return true;
    }

    const phoneRegex = /^[\d\s.()+]*$/;
    return phoneRegex.test(phonenumber);
}

export const ValidateBelgianNationalRegistrationNumber = (nationalRegistrationNumber, birthdate, gender) => {
    const regex = /^\d{2}\.\d{2}\.\d{2}-\d{3}\.\d{2}$/;
    if (!regex.test(nationalRegistrationNumber)) {
        return false;
    }
    
    const cleanedNumber = nationalRegistrationNumber.replace(/[.-]/g, '');
    const birthDatePart = cleanedNumber.substring(0, 6);
    const sequencePart = cleanedNumber.substring(6, 9);
    const year = parseInt(birthDatePart.substring(0, 2), 10);
    const month = parseInt(birthDatePart.substring(2, 4), 10);
    const day = parseInt(birthDatePart.substring(4, 6), 10);

    const [birthdayDay, birthdayMonth, birthdayYear] = birthdate.split('/').map(Number);

    const fullYear = birthdayYear;
    const expectedYear = fullYear % 100;

    if (expectedYear !== year) {
        return false;
    }

    const expectedMonth = birthdayMonth;
    const expectedDay = birthdayDay;
    if (month !== expectedMonth || day !== expectedDay) {
        return false;
    }

    const sequenceNumber = parseInt(sequencePart, 10);
    const isMale = sequenceNumber % 2 !== 0;
    if ((gender === 175470001 && !isMale) || (gender === 175470000 && isMale)) {
        return false;
    }

    return true;
}