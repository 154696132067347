import apiClient from "./client/apiClient";

export const putUserVisibilities = async (naturalpersonId) => {
    await apiClient.put(`/api/users/${naturalpersonId}/visibilities`);
};

export const getUserVisibilities = async (userId) => {
    const response = await apiClient.get(`/api/Users/${userId}/visibilities`);
    return response.data;
  };


