import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCompanyDetails } from '../../../api/companyApi.tsx';
import { useTranslation } from "react-i18next";
import LegalPersonInfoHeader from "../../../components/ELoket/LegalPersonInfoHeader/LegalPersonInfoHeader";
import { CompanyDetailQueryResult, LegalPersonMembershipInsurance } from "../../../models/CompanyDetails.tsx";
import { LegalPersonTypeEnum } from "../../../Enums/LegalPersonTypeEnum.js";
import AddInsuranceModal from "../../../components/ELoket/Insurance/AddInsuranceModal.tsx";
import { PersonType } from "../../../Enums/PersonType.tsx";
import EditInsuranceModal from "../../../components/ELoket/Insurance/EditInsuranceModal.tsx";
import InsuranceTable from "../../../components/ELoket/Insurance/InsurancesTable.tsx";
import { MembershipInsurance } from "../../../models/MembershipInsurance.tsx";

const CompanyInsurance = ({ userDetail }) => {
    const { t } = useTranslation();
    const [companyDetails, setCompanyDetails] = useState<Partial<CompanyDetailQueryResult>>({});
    const [legalPersonMembershipInsurance] = useState<Partial<LegalPersonMembershipInsurance>>({});
    const { legalPersonId } = useParams();
    const [loading, setLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editInsurance, setEditInsurance] = useState<LegalPersonMembershipInsurance | null>(null);

    const handleEditClick = (insurance: MembershipInsurance) => {
        setEditInsurance(insurance as LegalPersonMembershipInsurance);
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const companydetail = await getCompanyDetails(legalPersonId).finally(() => {
                setLoading(false);
            });;
            setCompanyDetails(companydetail);
        })();
    }, [legalPersonId]);

    return (
        <div id="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}

            {showAddModal && (
                <AddInsuranceModal
                    personId={legalPersonId}
                    personType={PersonType.LegalPerson}
                    membershipId={companyDetails.membershipId}
                    initialFormData={legalPersonMembershipInsurance}
                    show={showAddModal}
                    handleClose={() => setShowAddModal(false)}
                />
            )}

            {editInsurance && (
                <EditInsuranceModal
                    personId={legalPersonId}
                    personType={PersonType.LegalPerson}
                    membershipId={companyDetails.membershipId}
                    initialFormData={editInsurance}
                    show={editInsurance}
                    handleClose={() => setEditInsurance(null)}
                />
            )}

            <div id="content">
                <LegalPersonInfoHeader
                    legalPersonId={legalPersonId}
                    userDetail={userDetail}
                    legalPersonType={LegalPersonTypeEnum.Company}
                    isMobile={false}
                />

                <InsuranceTable
                    insurances={companyDetails?.insurances}
                    handleEditClick={handleEditClick}
                />
            </div>
            <footer>
                <span>
                    <div className="button-save-warning-container">
                        <span onClick={() => setShowAddModal(true)}>
                            <button className="btn btn-eloket">
                                <span className="fa fa-plus mr-2"></span> {t('insurance.addInsurance')}
                            </button>
                        </span>
                    </div>
                </span>
            </footer>
        </div>
    );
};

export default CompanyInsurance;