import { LegalPersonMembershipInsurance } from '../models/CompanyDetails';
import apiClient from './client/apiClient'
import apiFileClient from './client/apiFileClient';

export const getCompanyDetails = async (legalPersonId) => {
    try {
        const response = await apiClient
        .get(`/api/companies/${legalPersonId}/details`);
        if (response.status !== 200 && response.status !== 204) {
            throw new Error(`HTTP fout! Status: ${response.status}`);
        }
        return response.data;
    } catch (error) {
        throw new Error(`HTTP request failed: ${error.message}`);
    }
};

export const patchCompanyDetails = (legalPersonId, detailsToUpdate) => {
    return apiClient
        .patch(`/api/companies/${legalPersonId}/details`, detailsToUpdate)
        .then((response) => {
            if (response.status !== 200 && response.status !== 204) {
                throw new Error(`HTTP fout! Status: ${response.status}`);
            }
        })
        .catch((error) => {
            throw new Error(`HTTP request failed: ${error.message}`);
        });
};

export const patchCompanyInsurance = async (insurance: LegalPersonMembershipInsurance) => {
    return apiClient
        .patch('api/companies/insurance', insurance)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}

export const postCompanyInsurance = async (insurance: LegalPersonMembershipInsurance) => {
    return apiClient
        .post('api/companies/insurance', insurance)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}

export const uploadCompanyInsuranceCertificate = async (file: File, legalPersonId: string, startDate: Date) => {
    const formData = new FormData();
    const date = new Date(startDate);
    formData.append('file', file);
    formData.append('legalPersonId', legalPersonId);
    formData.append('insuranceStartDate', date.toISOString());

    return apiFileClient
        .post(`/api/companies/insurance/certificate`, formData)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
}
