export default {
  nl: {
    fetchUserDetails: "Fout bij het ophalen van gebruikersdetails.",
    noNavigation: "Er zijn geen navigatieblokken om weer te geven.",
    fillInAllFields: "Gelieve alle velden in te vullen.",
    certificateGeneration: "Fout bij het genereren van certificaat.",
    addPartner: "Fout bij het toevoegen van een nieuwe vennoot. Kijk na of alle ingevulde waardes kloppen.",
    addTools: "Kon tools niet toevoegen voor geselecteerde persoon. Kijk na of alle ingevulde waardes kloppen.",
    certificateNoTracks: "Er zijn geen trajecten om een certificaat van te genereren.",
    'Access Denied': "Toegang geweigerd. Neem contact op met <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
    'Script execution time exceeded': "Het ITAA-portaal is momenteel niet beschikbaar. Onze technische dienst probeert de website met de hoogste prioriteit weer online te krijgen. Onze excuses voor het ongemak en alvast bedankt voor uw geduld.",
    auth_default:"Neem contact op met <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
    Request404:"De gevraagde bron kon niet worden gevonden.",
    Request401:"Ongeautoriseerde toegang. Log opnieuw in.",
    Request500:"Er liep iets fout bij het verwerken van uw aanvraag",
    Request400:"Foutieve aanvraag. Controleer uw gegevens.",
    addUser: "De nieuwe medewerker kan niet aangemaakt worden. Kijk na of alle ingevulde waardes kloppen.",
    examRegistrationNotAvailable: "Het reservatieportaal is momenteel niet beschikbaar",
  },
  fr: {
    fetchUserDetails:"Erreur lors de la récupération des détails de l'utilisateur.",
    noNavigation: "Il n'y a aucun bloc de navigation à afficher.",
    fillInAllFields: "Veuillez remplir tous les champs.",
    certificateGeneration: "Erreur lors de la génération du certificat.",
    addPartner: "Erreur lors de l'ajout d'un nouveau associé. Vérifiez si toutes les valeurs sont correctes.",
    addTools: "Erreur lors de l'ajout d'un outil. Vérifiez si toutes les valeurs sont correctes.",
    certificateNoTracks: "Il n'existe aucun traject pour générer un certificat.",
    'Access Denied': "Accès refusé. Prenez contact avec <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
    'Script execution time exceeded': "Le portail ITAA n’est actuellement pas disponible. Notre service technique essaie de remettre le site en ligne en priorité. Nous nous excusons pour le désagrément et vous remercions de votre patience.",
    auth_default:"Prenez contact avec <a href='mailto:servicedesk@itaa.be'>servicedesk@itaa.be</a>.",
    Request404: "La ressource demandée n'a pas pu être trouvée.",
    Request401: "Accès non autorisé. Veuillez vous reconnecter.",
    Request500: "Une erreur s'est produite lors du traitement de votre demande.",
    Request400: "Requête incorrecte. Veuillez vérifier vos données.",
    addUser: "Erreur lors de l'ajout d'un collaborateur. Vérifiez si toutes les valeurs sont correctes.",
    examRegistrationNotAvailable: "Le portail de réservation est actuellement indisponible",
  },
};
