import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getOffice } from '../BeExcellent/http/HttpClientHelper';
import { getCoAdmins, deleteCoAdmin } from './http/HttpClientHelper'
import { formatItaaNumber } from '../../../../utils/formatUtils.tsx';
import Add from './Add/Add';
import { Modal, Button } from 'react-bootstrap';
import { FunctionsEnum } from '../../../../Enums/FunctionsEnum';
import LegalPersonInfoHeader from '../../LegalPersonInfoHeader/LegalPersonInfoHeader';
import QualificationTableCell from '../../MembershipInformation/QualificationTableCell';
import OfficialTypeTableCell from '../../MembershipInformation/OfficialTypeTableCell';
import { LegalPersonTypeEnum } from '../../../../Enums/LegalPersonTypeEnum';

const OfficeCoAdministrator = ({ userDetail, isMobile }) => {
    const { t } = useTranslation();
    const { legalPersonId } = useParams();
    const [officeDetails, setOfficeDetails] = useState(null);
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [coAdmins, setCoAdmins] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [canAddCoAdmin, setCanAddCoAdmin] = useState(false);

    var [LegalpersonType, setLegalpersonType] = useState('');

    const getLegalpersonType = (url) => {
        var startIndex = url.indexOf("/eloket/") + "/eloket/".length;
        var endIndex = url.indexOf("/", startIndex);

        setLegalpersonType(LegalpersonType = url.substring(startIndex, endIndex))
    };
    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload();
    };
    const fetchOfficeDetails = async () => {
        setLoading(true);
        const data = await getOffice(legalPersonId);
        setOfficeDetails(data);
        setLoading(false);
    };


    useEffect(() => {
        fetchOfficeDetails().finally(() => {
            setLoading(false);
        });

        (async () => {
            setLoading(true);
            var coAdmin = await getCoAdmins(legalPersonId);
            setCoAdmins(coAdmin);
            setCanAddCoAdmin(coAdmin.coAdministrators.length === 0);
            setLoading(false);
        })();
    }, [legalPersonId]);

    useEffect(() => {
        var url = window.location.href;
        getLegalpersonType(url)

        setButtonDisabled(!isCheckboxChecked);
    }, [isCheckboxChecked]);


    if (!officeDetails) {
        return (
            <div id="main">
                {loading && (
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                )}

                <div id="content">
                    <div className="text-h1">
                        {t('co_administrator.title')}
                    </div>

                </div>
            </div>
        );
    }

    const handleRemoveCoAdmin = async (legalPersonId, naturalPersonId) => {
        const deleteCoAdminParams = {
            id: legalPersonId,
            userId: naturalPersonId,
            functionId: FunctionsEnum.CoAdministrator
        };
        await deleteCoAdmin(deleteCoAdminParams);
        fetchOfficeDetails();
        setLoading(true);
        setIsSuccess(true);
    }

    return (
        <div id="main">
            {isSuccess && (
                <Modal show={isSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('co_administrator.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button
                            variant="secondary"
                            className="btn-eloket"
                            onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div id="content">
                <div id="content">
                    <LegalPersonInfoHeader
                        legalPersonId={legalPersonId}
                        userDetail={userDetail}
                        legalPersonType={LegalPersonTypeEnum.Office}
                    />

                </div>
                <div className="text-h1">
                    {LegalpersonType === 'company' ? t('co_administrator.titleCompany') : t('co_administrator.title')}
                </div>
                <div className="form-group">
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('access_tools.header_name')}</th>
                                    {!isMobile && (
                                        <>
                                            <th>{t('companyitaadata.itaaNumber')}</th>
                                            <th>{t('impersonation.itaaQualification')}</th>
                                            <th>{t('impersonation.officialType')}</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {coAdmins?.coAdministrators?.map((coAdmin, index) => (
                                    <tr key={index}>
                                        <td>{coAdmin.fullName}</td>
                                        {isMobile ? (
                                            <td className="badges-office-composition">
                                                <QualificationTableCell item={coAdmin} isMobile={isMobile} />
                                                <OfficialTypeTableCell item={coAdmin} isMobile={isMobile} />
                                            </td>
                                        ) : (
                                            <>
                                                <td>{formatItaaNumber(coAdmin.itaaNumber)}</td>
                                                <QualificationTableCell item={coAdmin} isMobile={isMobile} />
                                                <OfficialTypeTableCell item={coAdmin} isMobile={isMobile} />
                                            </>
                                        )}
                                        <td className='lastColumn'>
                                            <button className='btn btn-eloket' onClick={() => handleRemoveCoAdmin(officeDetails.legalPersonId, coAdmin.naturalPersonId)}>
                                                {!isMobile ? (
                                                    <>
                                                        {t('co_administrator.remove')}
                                                    </>
                                                ) : (
                                                    <i className='fa fa-trash'></i>
                                                )}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {!canAddCoAdmin && (
                        <div className='alert alert-danger mb-0 mt-2'>
                            <span> {t('co_administrator.add_not_available')}</span>
                        </div>
                    )}
                </div>
                {showAddModal && (
                    <Add
                        show={showAddModal}
                        handleClose={() => setShowAddModal(false)}
                        legalPersonId={legalPersonId}// Doorgeven van de geselecteerde naam
                    />
                )}

                {canAddCoAdmin && (
                    <footer>
                        <div className="button-save-warning-container">
                            <button className="btn btn-eloket" type="button" onClick={() => setShowAddModal(true)}> {t('co_administrator.add')} </button>
                        </div>
                    </footer>
                )}
            </div>
        </div>
    )
};

export default OfficeCoAdministrator;