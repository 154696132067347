import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const moment = require('moment-timezone');

export const formatItaaNumber = (number?: number | string): string => {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return "";
};

export const formatShortDateAsLocalDate = (datetime?: Date | string | null): string => {
    const appointmentTimeZone = 'Europe/Istanbul';
    if (!datetime) {
        return "-";
    }
    const date = new Date(datetime);
    if (isNaN(date.getTime())) {
        return "-";
    }
    return formatInTimeZone(date, appointmentTimeZone, 'dd/MM/yyyy');
};

export const formatDateOfBirth = (datetime?: Date | string | null): string => {
    if (!datetime) return "-";
    const date = new Date(datetime);
    if (isNaN(date.getTime())) return "-";

    const localDate = new Date(date.getTime() + new Date().getTimezoneOffset() * -60000);
    return format(localDate, "dd/MM/yyyy");
};

export const formatDateTimeAsLocalDateTime = (datetime?: Date | string | null): string => {
    if (!datetime) return "-";
    const date = new Date(datetime);
    if (isNaN(date.getTime())) return "-";

    return format(date, 'dd-MM-yyyy HH:mm');
};

export const formatDateTimeForEditorAsLocalDateTime = (datetime?: Date | string | null): string | null => {
    if (!datetime) return null;
    const date = new Date(datetime);
    if (isNaN(date.getTime())) return null;

    return format(date, 'yyyy-MM-dd HH:mm');
};

export const formatDateTimeAsUtc = (datetime?: Date | string | null): string | null => {
    if (!datetime) return null;
    return moment(datetime).utc().format();
};

export const formatFileNumber = (fileNumber: number | string): string => {
    const fileNumberStr = fileNumber.toString();
    return fileNumberStr.slice(0, 2) + '.' + fileNumberStr.slice(2);
};

export const formatNationalRegistrationNumber = (value: string): string => {
    // Verwijder alle niet-cijfertekens
    let numbersOnly = value.replace(/\D/g, '');

    // Voeg punten en streepje toe op de juiste posities
    if (numbersOnly.length > 2) numbersOnly = `${numbersOnly.slice(0, 2)}.${numbersOnly.slice(2)}`;
    if (numbersOnly.length > 5) numbersOnly = `${numbersOnly.slice(0, 5)}.${numbersOnly.slice(5)}`;
    if (numbersOnly.length > 8) numbersOnly = `${numbersOnly.slice(0, 8)}-${numbersOnly.slice(8)}`;
    if (numbersOnly.length > 12) numbersOnly = `${numbersOnly.slice(0, 12)}.${numbersOnly.slice(12, 15)}`;

    // Beperk de lengte tot 15 karakters
    return numbersOnly.slice(0, 15);
};

export const formatDatePicker = (value: string): string => {
    // Verwijder alle niet-numerieke karakters
    const cleanedValue = value.replace(/\D/g, '');

    // Deel de schoongewiste waarde op in dag, maand en jaar
    const day = cleanedValue.substring(0, 2);
    const month = cleanedValue.substring(2, 4);
    const year = cleanedValue.substring(4, 8);

    // Bouw de geformatteerde datum op
    let formattedValue = '';

    if (day) {
        formattedValue += day; // Voeg dag toe
    }
    if (month) {
        formattedValue += (formattedValue ? '/' : '') + month; // Voeg maand toe
    }
    if (year) {
        formattedValue += (formattedValue ? '/' : '') + year; // Voeg jaar toe
    }

    return formattedValue;
};

export const formatCompanyName = (companyName?: string): string => {
    return companyName ? companyName.toUpperCase() : '';
};


export const formatLabelWithColon = (label: string, value?: string | number | null): string => {
    return value ? `${label} : ${value}` : `${label} : -`;
};

export const formatDateForDateInput = (datetime: Date | string | null | undefined): string | undefined => {
    if (!datetime) {
        return undefined;
    }
    const date = new Date(datetime);
    if (isNaN(date.getTime())) {
        return undefined;
    }
    return format(date, 'yyyy-MM-dd');
};