import apiClient from './client/apiClient'

export const getCountries = () => {
    return apiClient
        .get(`/api/common/countries`).then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};

export const getCities = () => {
    return apiClient
        .get(`/api/common/cities`).then((response) => response.data)
        .catch((error) => {
            throw new Error(`HTTP error! ${error}`);
        });
};