import apiClient from "../../../../../api/client/apiClient";

export const getCoAdmins = (legalPersonId) => {
  return apiClient
    .get(`/api/offices/${legalPersonId}/coadministrators`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const postCoAdmin = (legalPersonId, naturalPersonId) => {
  return apiClient
    .post(`/api/offices/${legalPersonId}/coadministrator`, naturalPersonId)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const deleteCoAdmin = async (deleteCoAdminParams) => {
    try {
        const response = await apiClient.delete(`/api/offices/deletecoadministrator`, {
            data: deleteCoAdminParams
        });
        return response;
    } catch (error) {
        error.message = `HTTP error! ${error.message}`;
        console.error(error.message);
        throw error;
    };
}