import showErrorToast from "../../components/Toast/Toast";
import i18n from "../../i18n";
import { log } from "../../utils/log"

const errorMessages = {
  400: 'errors.Request400',
  401: 'errors.Request401',
  404: 'errors.Request404',
  500: 'errors.Request500',
};

export const apiErrorHandler = (error, showToast = true) => {
  //log.error(`[api] ${error}`); => TO : FIX LATER
  let errorMessage = ''; 
  const status = error.response?.status;
  if (status && errorMessages[status]) {
    errorMessage = i18n.t(errorMessages[status]);
  } else {
    errorMessage = `${error.response.status}: ${error.message}`;
  }
  if(showToast){
    showErrorToast(errorMessage); 
  }
};
