const currentYear = new Date().getFullYear();

export default {
  nl: {
    documentation: {
      title: `ALGEMENE VERGADERING {{currentYear}}`,
      subtitle:"Hieronder vindt u de dagorde en de documenten van de algemene vergadering"
    },
    ChooseOption: "Maak uw keuze",
    registration: {
      send:"verzenden",
      success:"Uw keuze werd verzonden.",
      title: `ALGEMENE VERGADERING ${currentYear}`,
      subtitle:`Bij problemen, gelieve contact op te nemen met <a href=\"mailto:servicedesk@itaa.be\">servicedesk@itaa.be</a>.`,
        confirmed: "U heeft reeds uw deelname bevestigd. Indien u deze wilt wijzigen, gelieve een e-mail te sturen naar <a href=\"mailto:servicedesk@itaa.be\">servicedesk@itaa.be</a>",
        hasStarted:"De algemene vergadering is begonnen, surf naar bovenstaande url om deel te nemen",
        practicalInfo:`<value>Klik &lt;a href="https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf" target="_blank"&gt;hier&lt;/a&gt; voor alle praktische info</value>`,
        registrationExpired:"De registratie datum om in te schrijven voor de algemene vergadering is verstreken.",
        optionMenu:{
          attend:"Ik wens deel te nemen",
          doNotAttend:"Ik wens niet deel te nemen",
          subOption:{
            followRemote: "en op afstand te volgen",
            followOnSite: "en zal ter plaatse aanwezig zijn (er zijn nog ",
            places:" plaatsen vrij)"
          },
          proxy:{
            choice:"Ik ben verhinderd maar wens een volmacht te geven",
            session: {
              buttonText:"Klik hier om u opnieuw aan te melden",
              sessionValid:"U hebt zich minder dan 3 minuten geleden aangemeld op het ITAA portaal. Uw sessie dient niet te worden vernieuwd om uw digitale handtekening te genereren. U heeft nog <b>{{countdown}}</b> om uw volmacht te verzenden. Daarna wordt u gevraagd om opnieuw aan te melden.",
              sessionInvalid:"U hebt zich langer dan 3 minuten geleden aangemeld op het ITAA-portaal. Om zeker te zijn dat u het bent, dient u zich opnieuw aan te melden op het ITAA-portaal met één van de aanmeldmogelijkheden.",
            },
            choose:"Aan wie wilt u uw volmacht geven?",
            chooseConfratorPlaceholder:"Voer naam + voornaam in. Uw keuze verschijnt na enkele seconden",
            warning:"Indien u volmacht verleent kan u zelf niet meer deelnemen aan de algemene vergadering. Indien u later beslist toch deel te nemen dan dient u contact te nemen met <a href=\"mailto:servicedesk@itaa.be\">servicedesk@itaa.be</a>",
            digitalSignature:{
              checkboxText:"Ik ga ermee akkoord dat mijn digitale handtekening wordt gegenereerd. Pas na akkoord kunt u verzenden. U ontvangt een samenvattende e-mail waarin uw digitale handtekening staat."
            }
          },
          NoPersonFound: "Er zijn geen resultaten gevonden voor uw zoekopdracht",
      },
      registeredOnline:{
        lightPortal: process.env.REACT_APP_URL_LIGHTPORTAL
      },
      sendMail: "Een bevestigings-e-mail zal verzonden worden naar u. Mocht u de e-mail niet zien verschijnen in uw inbox, check dan zeker even uw spam. "
    },
    not_possible: {
      message: "Inschrijven voor de algemene vergadering is op dit moment niet mogelijk.",
      cancel: "Sluiten",
    },
    validation:{
      proxyDate:"De uiterlijke datum om een volmacht te verlenen is verstreken",
    },
    urls :{
      infoForAttendingAGM: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20algemene%20informatie.pdf' target='_blank'>Bekijk de algemene informatie voor het bijwonen van de AV</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20informations%20generales.pdf' target='_blank'>FR</a>`,
      agendaCurrentYear: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20dagorde.pdf' target='_blank'>Bekijk de dagorde {{currentYear}}</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20ordre%20du%20jour.pdf' target='_blank'>FR</a>`,
      balanceLastYear: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20balans.pdf' target='_blank'>Bekijk de balans per 31.12.{{lastYear}} – 2 jaar </a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20bilan.pdf' target='_blank'>FR</a>`,
      incomeStatementLastYear: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20resultatenrekening.pdf' target='_blank'>Bekijk de jaarrekening {{lastYear}} (NBB)</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20compte%20de%20resultats.pdf' target='_blank'>FR</a>`,
      comparisonBudgetResults: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20vergelijking%20begroting%20-%20resultatenrekening.pdf' target='_blank'>Bekijk de resultatenrekening {{lastYear}} / {{twoYearsAgo}} en begroting {{lastYear}}</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20comparaison%20budget%20-%20compte%20de%20resultats.pdf' target='_blank'>FR</a>`,
      budgetProposalNewYear: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20voorstel%20van%20begroting%20voor%20het%20nieuwe%20boekjaar.pdf' target='_blank'>Bekijk het voorstel van begroting voor het nieuwe boekjaar</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20proposition%20de%20budget%20pour%20le%20nouvel%20exercice.pdf' target='_blank'>FR</a>`,
      annualReport: `<a href='https://www.itaa.be/nl/jaarverslag/' target='_blank'>Bekijk het activiteitenverslag van het afgelopen jaar</a> | <a href='https://www.itaa.be/fr/rapportannuel/' target='_blank'>FR</a>`,
      commissionersReport: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20verslag%20van%20de%20commissarissen.pdf' target='_blank'>Bekijk het verslag van de commissarissen</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20rapport%20des%20commissaires.pdf' target='_blank'>FR</a>`,
      proxyVoting: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20volmacht%20verlenen.pdf' target='_blank'>Volmacht verlenen: hoe?</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20donner%20une%20procuration.pdf' target='_blank'>FR</a>`,
      proxyLoginChat: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>Proxy login – Stemmen op afstand – Chat</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>FR</a>`,
      votingFromHall: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Stemmen%20vanuit%20de%20zaal.pdf' target='_blank'>Stemmen vanuit de zaal</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20Voter%20en%20presentiel.pdf' target='_blank'>FR</a>`,
      candidacies: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20kandidaten%20commisaris.pdf' target='_blank'>Bekijk de lijst met kandidaturen voor het mandaat van commissaris</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20kandidaten%20commisaris.pdf' target='_blank'>FR</a>`
    }
  },
  fr: {
    documentation: {
      title: `L'ASSEMBLEE GENERALE {{currentYear}}`,
      subtitle:"Vous trouverez ci-dessous l'ordre du jour et les documents de l'assemblée générale."
    },
    ChooseOption: "Faites votre choix",
    registration: {
      send:"envoyer",
      success:"Votre choix a été envoyé.",
      title: `L'ASSEMBLEE GENERALE ${currentYear}`,
      subtitle:`En cas de problème, veuillez contacter <a href=\"mailto:servicedesk@itaa.be\">servicedesk@itaa.be</a>.`,
      confirmed:"Vous avez confirmé votre participation. Si vous souhaitez modifier cela, veuillez envoyer un courriel à <a href=\"mailto:servicedesk@itaa.be\">servicedesk@itaa.be</a>",
      hasStarted:"L'Assemblée Générale a commencé, rendez-vous sur l'URL ci-dessus pour participer.",
      practicalInfo:`<value>Cliquez &lt;a href="https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf" target="_blank"&gt;hier&lt;/a&gt;  pour toutes les informations pratiques</value>`,     
      registrationExpired: "La date d'inscription à l'assemblée générale est passée.",
      optionMenu:{
        attend:"Je souhaite participer",
        doNotAttend:"Je ne souhaite pas participer",
        subOption:{
          followRemote: "et suivre à distance",
          followOnSite: "et serai présent sur place (il reste encore ",
          places:" places disponibles)"
        },
        proxy:{
          choice:"Je ne peux pas être présent mais je souhaite donner une procuration",
          session: {
            buttonText:"Cliquez ici pour vous reconnecter",
            sessionValid:"Vous vous êtes connecté au portail ITAA il y a moins de 3 minutes. Votre session n'a pas besoin d'être renouvelée pour générer votre signature numérique. Vous avez encore <b>{{countdown}}</b> pour envoyer votre procuration. Ensuite, il vous sera demandé de vous reconnecter.",
            sessionInvalid:"Vous vous êtes connecté au portail ITAA il y a plus de 3 minutes. Pour être sûr que c'est bien vous, vous devez vous reconnecter au portail ITAA en utilisant l'une des options de connexion.",
          },
          choose:"À qui souhaitez-vous donner votre procuration?",
          chooseConfratorPlaceholder:"Entrez nom + prénom. Votre choix apparaîtra après quelques secondes",
          warning:"Si vous donnez procuration, vous ne pourrez plus participer à l'Assemblée Générale. Si vous décidez plus tard de participer quand même, vous devez contacter <a href=\"mailto:servicedesk@itaa.be\">servicedesk@itaa.be</a>.",
          digitalSignature:{
            checkboxText:"Je donne mon accord pour que ma signature numérique soit générée. Ce n'est qu'après avoir donné votre accord que vous pourrez envoyer. Vous recevrez un e-mail récapitulatif contenant votre signature numérique."
          }
        },
        NoPersonFound: "Il n'y a aucun résultat trouvé pour votre recherche.",
      },
      registeredOnline:{
        lightPortal: process.env.REACT_APP_URL_LIGHTPORTAL
      },
      sendMail: "Un email de confirmation vous sera envoyé. Si vous ne retrouvez pas l'email dans votre boîte de réception, veuillez vérifier votre courrier indésirable (spam). "
    },
    not_possible: {
      message:"L'inscription à l'assemblée générale n'est actuellement pas possible.",
      cancel: "Fermer",
    },
    validation:{
      proxyDate:"La date limite pour donner une procuration est expirée",
    },
    urls: {
      infoForAttendingAGM: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20informations%20generales.pdf' target='_blank'>Consulter les informations générales sur la participation à l'assemblée générale</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20algemene%20informatie.pdf' target='_blank'>NL</a>",
      agendaCurrentYear: `<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20ordre%20du%20jour.pdf' target='_blank'>Consulter l’ordre du jour {{currentYear}}</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20dagorde.pdf' target='_blank'>NL</a>`,
      balanceLastYear: `<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20bilan.pdf' target='_blank'>Consulter le bilan au 31.12.{{lastYear}} – 2 ans</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20balans.pdf' target='_blank'>NL</a>`,
      incomeStatementLastYear: `<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20compte%20de%20resultats.pdf' target='_blank'>Consulter les comptes annuels {{lastYear}} (BNB)</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20resultatenrekening.pdf' target='_blank'>NL</a>`,
      comparisonBudgetResults: `<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20comparaison%20budget%20-%20compte%20de%20resultats.pdf' target='_blank'>Consulter le compte de résultat {{lastYear}} / {{twoYearsAgo}} et le budget {{lastYear }}</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20vergelijking%20begroting%20-%20resultatenrekening.pdf' target='_blank'>NL</a>`,
      budgetProposalNewYear: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20proposition%20de%20budget%20pour%20le%20nouvel%20exercice.pdf' target='_blank'>Consulter la proposition de budget pour le nouvel exercice</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20voorstel%20van%20begroting%20voor%20het%20nieuwe%20boekjaar.pdf' target='_blank'>NL</a>",
      annualReport: "<a href='https://www.itaa.be/fr/rapportannuel/' target='_blank'>Consulter le rapport d’activités de l’an dernier</a> | <a href='https://www.itaa.be/nl/jaarverslag/' target='_blank'>NL</a>",
      commissionersReport: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20rapport%20des%20commissaires.pdf' target='_blank'>Consulter le rapport des commissaires</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20verslag%20van%20de%20commissarissen.pdf' target='_blank'>NL</a>",
      proxyVoting: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20donner%20une%20procuration.pdf' target='_blank'>Donner une procuration : comment?</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20volmacht%20verlenen.pdf' target='_blank'>NL</a>",
      proxyLoginChat: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>Proxy login – Voter à distance – Chat</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>NL</a>",
      votingFromHall: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20Voter%20en%20presentiel.pdf' target='_blank'>Voter en presentiel</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Stemmen%20vanuit%20de%20zaal.pdf' target='_blank'>NL</a>",
      candidacies: `<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20kandidaten%20commisaris.pdf' target='_blank'>Consulter la liste avec les candidatures pour le mandat de commissaire</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20kandidaten%20commisaris.pdf' target='_blank'>NL</a>`
    }
  },
};
