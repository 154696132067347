import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getMandateLink, getMandateCompanies } from "./http/HttpClientHelper";
import { useSessionContext } from "./../../../../providers/SessionProvider";

const Mandate = ({ show, handleClose, userDetail }) => {
  const { t } = useTranslation();
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOfficeId, setSelectedOfficeId] = useState("");
  const [canOpenMandates, setCanOpenMandates] = useState(false);
  const { hasBOSAIdentity } = useSessionContext();

  useEffect(() => {
    (async () => {
      setLoading(true);
      setCanOpenMandates(hasBOSAIdentity);

      var companies = await getMandateCompanies(userDetail.id);
      setOffices(companies);
      setLoading(false);
    })();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedOfficeId(event.target.value);
  };

  const handleConfirm = () => {
    getMandateLink(selectedOfficeId).then((link) => {
      window.open(link.mandateLink, "_blank");
      handleClose();
    });
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {canOpenMandates ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("mandateModal.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section>
              <p>{t("mandateModal.choseLegalEntityText")}</p>
              <p dangerouslySetInnerHTML={{ __html: t('popup.questions') }}></p>
            </section>
            <section>
              <select
                className="form-control"
                onChange={handleSelectChange}
                value={selectedOfficeId}
              >
                <option value="">{t("mandateModal.selectTitle")}</option>
                {offices.map((office) => (
                  <option key={office.legalPersonId} value={office.legalPersonId}>
                    {office.name}
                  </option>
                ))}
              </select>
            </section>
            <br />
            <p className='warning-text inline' dangerouslySetInnerHTML={{ __html: t("mandateModal.infoText") }}></p>
            <p dangerouslySetInnerHTML={{ __html: t("popup.compatibleBrowserWarning") }}></p>
            <section>
              <label htmlFor="MandateConfirmation">
                <input
                  type="checkbox"
                  id="MandateConfirmation"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
                {t('popup.confirmOnSaveCheck')}
              </label>
            </section>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            <Button
              variant="secondary"
              className="btn-eloket"
              onClick={handleConfirm}
              disabled={!isCheckboxChecked}
            >
              {t("confirmation.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("mandateModal.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section>
              <p>{t("mandateModal.bosaLoginRequired")}</p>
              <p>{t("mandateModal.bosaLoginRequired2")}</p>
            </section>
            <section>
              <ul>
                <li>{t("mandateModal.mandateLoginStep1")}</li>
                <li>{t("mandateModal.mandateLoginStep2")}</li>
                <li>{t("mandateModal.mandateLoginStep3")}</li>
              </ul>
            </section>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Mandate;
