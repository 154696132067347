import apiClient from "../../../../../api/client/apiClient";

export const getPersonalDataDetail = (userId) => {
  return apiClient
    .get(`/api/Profiles/${userId}/personaldatause`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};

export const patchPersonalDataDetail = (userId, detailsToUpdate) => {
  return apiClient
    .patch(`/api/Profiles/${userId}/personaldatause`, detailsToUpdate)
    .catch((error) => {
      throw new Error(`HTTP error! ${error}`);
    });
};
