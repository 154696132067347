import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AutocompleteResults from '../AutoComplete/AutoComplete';
import { postOfficeComposition } from '../http/HttpClientHelper';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();


const Add = ({ show, handleClose, legalPersonId }) => {
    const { t } = useTranslation();
    const [selectedName, setSelectedName] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseSuccess = () => {
        setIsSuccess(false);
        window.location.reload()// Sluit de modal
    };
    const handleNameChange = (event) => {
        if (selectedName !== event.target.value) {
            setSelectedName(event.target.value);
        }
        setIsClickedResult(false);
    };

    const addOfficeComposition = async (event) => {
        setIsConfirmModalVisible(true)
        try {
            setLoading(true)
            await postOfficeComposition(legalPersonId, selectedPerson.naturalPersonId);
            setSelectedPerson(selectedPerson)
            setIsSuccess(true)
        } catch (error) {
            console.error('Something went wrong during update of network details:', error);
        } finally {
            setLoading(false)
            setIsConfirmModalVisible(false)
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsConfirmModalVisible(true);
    };

    const handleConfirmSubmit = async () => {
        setIsConfirmModalVisible(false);
        await addOfficeComposition();
    };

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedPerson(selectedResult);
        setSelectedName(selectedResult.fullName);
        setIsClickedResult(true)
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsClickedResult(true);
        }, 100); 
    };

    return (

        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {isSuccess || isConfirmModalVisible ? (
                <>
                    <Modal show={isSuccess} onHide={handleCloseSuccess}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <small>{t('popup.successUpdate')}</small>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-between">
                            <Button
                                variant="secondary"
                                className="btn-eloket"
                                onClick={handleCloseSuccess}>
                                {t('mandate_application.modal_success_close')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>{t("popup.verifyChange")}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                                {t("confirmation.cancel")}
                            </Button>
                            <Button variant="primary" className="btn-eloket" onClick={handleConfirmSubmit}>
                                {t("confirmation.confirm")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('office_composition.modal_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formNetworkName">
                            <Form.Label>
                                {t('office_composition.modal_description')} <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onBlur={handleBlur}
                                value={selectedName}
                                autoComplete='off'
                                onChange={handleNameChange}
                                required
                            />
                            {selectedName.trim().length >= 3 && !isClickedResult && (
                                <AutocompleteResults
                                    inputValue={selectedName}
                                    handleSelection={handleAutocompleteSelection}
                                    legalPersonId={legalPersonId}
                                />
                            )}
                        </Form.Group>
                        <small dangerouslySetInnerHTML={{ __html: t('office_composition.modal_text') }}></small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="secondary" onClick={handleClose}>
                            {t('popup.cancel')}
                        </Button>
                        <Button
                            className="btn-eloket"
                            variant="secondary"
                            onClick={handleSubmit}
                            disabled={!selectedName.trim()}
                        >
                            {t('office_composition.modal_add')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default Add;