import apiClient from "../../../../../api/client/apiClient";

export const getNetworkDetail = async (legalPersonId) => {
    const response = await apiClient.get(
        `/api/companies/${legalPersonId}/networks`
    );
    return response.data;
};

export const patchNetworkDetail = async (legalPersonId, detailsToAdd) => {
    const response = await apiClient.post(
        `/api/companies/${legalPersonId}/networks`,
        detailsToAdd
    );
    if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
};

export const deleteNetworkDetail = async (networkId) => {
    const response = await apiClient.delete(
        `/api/companies/networks/${networkId}`
    );
    if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
};

export const searchNetworks = async (legalPersonId, search) => {
    const response = await apiClient.get(
        `/api/companies/${legalPersonId}/networks/search?search=${search}`
    );
    if (!response.status === 200) {
        throw new Error(`HTTP fout! Status: ${response.status}`);
    }
    return response.data;
};
