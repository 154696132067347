import apiClient from "../../../../../api/client/apiClient";

export const getMainContact = async (legalPersonId) => {
    const response = await apiClient
      .get(`/api/offices/${legalPersonId}/maincontact`);
    return response.data;
};

export const getMainContactOrCoAdmin = async (legalPersonId, naturalPersonId) => {

    const response = await apiClient
      .get(
        `/api/offices/${legalPersonId}/ismaincontactorcoadmin/${naturalPersonId}`
      );
    return response.data;
};

export const searchMainContact = async (legalPersonId, search) => {
  const url =
    search === ""
      ? `/api/offices/${legalPersonId}/maincontact/search`
      : `/api/offices/${legalPersonId}/maincontact/search?search=${search}`;

    const response = await apiClient
      .get(url);
    return response.data;
};

export const patchMainContact = async (legalPersonId, naturalPersonId) => {
    return await apiClient
      .patch(`/api/offices/${legalPersonId}/maincontact/${naturalPersonId}`);

};
