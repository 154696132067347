import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getActiveGeneralAssembly, getLatestGeneralAssembly } from "./http/HttpClientHelper.tsx";
import { ActiveGeneralAssembly, GeneralAssemblyQueryResult } from '../../../../models/ActiveGeneralAssembly.tsx';
import { DisplayNameOptions } from '../../../../Enums/DisplayNameOptions.tsx';

interface GeneralAssemblyDocumentationProps {
  show?: boolean;
  handleClose?: () => void;
}

const GeneralAssemblyDocumentation: React.FC<GeneralAssemblyDocumentationProps> = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [activeGeneralAssembly, setActiveGeneralAssembly] = useState<ActiveGeneralAssembly | null>(null);
  const [latestGeneralAssemblyOfCurrentYear, setLatestGeneralAssemblyOfCurrentYear] = useState<GeneralAssemblyQueryResult | null>(Object.assign(new GeneralAssemblyQueryResult()));

  useEffect(() => {
    const fetchData = async () => {
      const activeAssembly = await getActiveGeneralAssembly();
      if (activeAssembly) {
        setActiveGeneralAssembly(activeAssembly);
      } else {
        const latestAssembly = await getLatestGeneralAssembly();
        if (latestAssembly) {
          if (latestAssembly?.startDate) {
            const startDate = new Date(latestAssembly?.startDate);
            startDate.setHours(0, 0, 0, 0);
            latestAssembly.registrationClosingDate = startDate;
          }
          setLatestGeneralAssemblyOfCurrentYear(latestAssembly);
        } else {
          setLatestGeneralAssemblyOfCurrentYear(null);
        }
      }
    };
    fetchData();
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getDisplayYear = (yearOption?: DisplayNameOptions) => {
    const dateToday = new Date();
    const currentYear = dateToday.getFullYear();
    if (activeGeneralAssembly !== null) {
      switch (yearOption) {
        case DisplayNameOptions.TwoYearsAgo:
          return currentYear - 2;
        case DisplayNameOptions.LastYear:
          return currentYear - 1;
        case DisplayNameOptions.CurrentYear:
          return currentYear;
      }
    }
    if (latestGeneralAssemblyOfCurrentYear !== null) {
      const endDate = latestGeneralAssemblyOfCurrentYear?.endDate;
      const startDate = endDate ? new Date(endDate).getFullYear() : null;
      if (startDate !== null) {
        switch (yearOption) {
          case DisplayNameOptions.TwoYearsAgo:
            return startDate - 2;
          case DisplayNameOptions.LastYear:
            return startDate - 1;
          case DisplayNameOptions.CurrentYear:
            return startDate;
        }
      }
      return startDate;
    }
  };

  const accordionContent = (
    <div className="accordion-body">
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.infoForAttendingAGM') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.agendaCurrentYear', { currentYear: getDisplayYear(DisplayNameOptions.CurrentYear) }) }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.incomeStatementLastYear', { lastYear: getDisplayYear(DisplayNameOptions.LastYear) }) }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.comparisonBudgetResults', { lastYear: getDisplayYear(DisplayNameOptions.LastYear), twoYearsAgo: getDisplayYear(DisplayNameOptions.TwoYearsAgo) }) }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.balanceLastYear', { lastYear: getDisplayYear(DisplayNameOptions.LastYear) }) }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.budgetProposalNewYear') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.annualReport') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.commissionersReport') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.candidacies') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.proxyVoting') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.proxyLoginChat') }} />
      <div className="generalAssemblyDocumentationUrls" dangerouslySetInnerHTML={{ __html: t('generalAssembly.urls.votingFromHall') }} />
    </div>
  );

  return (
    <div>
      {show ? (
        <Modal show={show} onHide={handleClose} className="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title>{t("generalAssembly.documentation.title", { currentYear: getDisplayYear() })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {accordionContent}
          </Modal.Body>
        </Modal>
      ) : (
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${!isOpen ? 'collapsed' : ''}`}
                type="button"
                onClick={toggleAccordion}
                aria-expanded={isOpen}
              >
                {t("generalAssembly.documentation.subtitle")}
              </button>
            </h2>
            <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}>
              {accordionContent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralAssemblyDocumentation;
