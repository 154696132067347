import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap/esm';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatItaaNumber, formatNationalRegistrationNumber } from '../../../../../utils/formatUtils.tsx';
import { postPartnerCandidates, searchPartnerCandidates } from '../http/HttpClientHelper';
import { getNationalities, getLanguages, getGenders } from '../../../Office/AccessTools/http/HttpClientHelper';

const Add = ({ show, handleClose, legalPersonId }) => {
    const { t } = useTranslation();
    const [loadingAC, setLoadingAC] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [isClickedResult, setIsClickedResult] = useState(false);
    const [addFields, setAddFields] = useState(false);
    const [results, setResults] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState('');
    const [mounted, setMounted] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [genders, setGenders] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedNationality, setSelectedNationality] = useState('');
    const [shares, setShares] = useState(0);
    const [votingRights, setVotingRights] = useState(0);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthdate, setBirthdate] = useState(null);
    const [nationalRegistrationNumber, setNationalRegistrationNumber] = useState('');
    const [warningVisible, setWarningVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            const fetchAutocompleteResults = async () => {
                if (!mounted) {
                    setLoadingAC(true);
                    const response = await searchPartnerCandidates(legalPersonId, selectedName);
                    setResults(response);
                }
                setLoadingAC(false);
            };

            if (selectedName.trim() !== '') {
                fetchAutocompleteResults();
            } else {
                setResults([]);
            }
        }, 200);

        return () => {
            clearTimeout(delaySearch);
            setMounted(false);
        };
    }, [selectedName, legalPersonId, mounted]);

    useEffect(() => {
        getLanguages().then(languages => {
            setLanguages(languages);
        });

        getGenders().then(genders => {
            setGenders(genders);
        });

        getNationalities().then(nationalities => {
            setNationalities(nationalities);

            const belgianNationality = nationalities.find(nat => nat.name === 'Belgian');
            if (belgianNationality) {
                setSelectedNationality(belgianNationality.id);
            }
        });
    }, []);

    const handleNameChange = (event) => {
        setSelectedName(event.target.value);
        setIsClickedResult(false);
    };

    const handleSharesChange = (e) => setShares(e.target.value);
    const handleVotingRightsChange = (e) => setVotingRights(e.target.value);
    const handleChangeFirstName = (e) => setFirstname(e.target.value);
    const handleChangeLastName = (e) => setLastname(e.target.value);
    const handleChangeGender = (e) => setSelectedGender(e.target.value);
    const handleChangeLanguage = (e) => setSelectedLanguage(e.target.value);
    const handleNationalityMouseEnter = (e) => setWarningVisible(true);
    const handleNationalityMouseLeave = (e) => setWarningVisible(false);
    const handleBirthdateChange = (date) => setBirthdate(date);
    const handleNationalRegistrationNumberChange = (e) => {
        const formattedValue = formatNationalRegistrationNumber(e.target.value);
        setNationalRegistrationNumber(formattedValue);
    };

    const handleCloseSuccess = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await postPartnerCandidates(legalPersonId,
            selectedPerson?.naturalPersonId,
            shares,
            votingRights,
            firstname,
            lastname,
            selectedGender,
            selectedLanguage,
            selectedNationality,
            birthdate,
            nationalRegistrationNumber
        );
        setShowSuccessModal(true);
        setLoading(false);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsClickedResult(true);
        }, 100);
    };

    const handleAutocompleteSelection = (selectedResult) => {
        setSelectedPerson(selectedResult);
        setSelectedName(selectedResult.fullName);
    };

    const handleItemClick = async (result) => {
        if (result) {
            await handleAutocompleteSelection(result);
            setResults([]);
            setAddFields(false);
        } else {
            setAddFields(true);
        }
    };

    useEffect(() => {}, [addFields]);

    return (
        <>
            {loading && (
                <>
                    <div className="loader-overlay">
                        <div className="loader"></div>
                    </div>
                </>
            )}
            {showSuccessModal ? (
                <Modal show={showSuccessModal} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('mandate_application.modal_success_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('popup.successUpdate')}</small>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="secondary" className="btn-eloket" onClick={handleCloseSuccess}>
                            {t('mandate_application.modal_success_close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('directorAndPartner.add')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>{t('directorAndPartner.addDescription')}</small>
                        <br /><br />
                        <Form>
                            <Form.Group controlId="formNetworkName">
                                <Form.Label>
                                    {t('access_tools.modal_input_description')} <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={selectedName}
                                    onChange={handleNameChange}
                                    onBlur={handleBlur}
                                    autoComplete='off'
                                    required
                                />
                                {selectedName.trim().length >= 3 && !isClickedResult && (
                                    <ListGroup>
                                        {loadingAC && (
                                            <ListGroup.Item className="text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </ListGroup.Item>
                                        )}
                                        {!loadingAC && (
                                            <ListGroup.Item key={"00000000-0000-0000-0000-000000000000"} onMouseDown={() => handleItemClick(null)}>
                                                {t('directorAndPartner.newContact')}
                                            </ListGroup.Item>
                                        )}
                                        {!loadingAC && results.map((result) => (
                                            <ListGroup.Item key={result.naturalPersonId} onMouseDown={() => handleItemClick(result)}>
                                                {result.fullName}, #{formatItaaNumber(result.itaaNumber)}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                                {addFields && (
                                    <div>
                                        <hr />
                                        <h4>{t('directorAndPartner.newContact')}</h4>
                                        <Row>
                                            <Col>
                                                <Form.Label>{t('itaadata.firstName')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={firstname}
                                                    onChange={handleChangeFirstName}
                                                    autoComplete='off'
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>{t('itaadata.lastName')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={lastname}
                                                    onChange={handleChangeLastName}
                                                    autoComplete='off'
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>{t('itaadata.gender')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={selectedGender}
                                                    onChange={handleChangeGender}
                                                    autoComplete='off'
                                                    required
                                                >
                                                    <option value="" disabled>{t(`itaadata.genders.Select`)}</option>
                                                    {genders?.map(gender => (
                                                        <option key={gender.id} value={gender.id}>
                                                            {t(`itaadata.genders.${gender.name}`)}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Label>{t('itaadata.language')}</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={selectedLanguage}
                                                    onChange={handleChangeLanguage}
                                                    autoComplete='off'
                                                    required
                                                >
                                                    <option value="" disabled>{t(`itaadata.languages.Select`)}</option>
                                                    {languages?.map(language => (
                                                        <option key={language.id} value={language.id}>
                                                            {t(`itaadata.languages.${language.name}`)}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>{t('itaadata.nationality')}</Form.Label>
                                                <div onMouseEnter={handleNationalityMouseEnter} onMouseLeave={handleNationalityMouseLeave}>
                                                    <Form.Control
                                                        as="select"
                                                        value={selectedNationality}
                                                        autoComplete='off'
                                                        required
                                                        disabled={true}
                                                    >
                                                        {nationalities.map(nationality => (
                                                            <option key={nationality.id} value={nationality.id}>
                                                                {t(`itaadata.nationalities.${nationality.name}`)}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Label>{t('itaadata.bithDate')}</Form.Label>
                                                <DatePicker
                                                    selected={birthdate}
                                                    onChange={handleBirthdateChange}
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    autoComplete='off'
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        {warningVisible && (
                                            <Row>
                                                <Col>
                                                    <div className='alert alert-danger mb-0 mt-2'>
                                                        <span> {t('directorAndPartner.newContactNationalityWarning')}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col>
                                                <Form.Label>{t('itaadata.nationalRegistrationNumber')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={nationalRegistrationNumber}
                                                    onChange={handleNationalRegistrationNumberChange}
                                                    autoComplete='off'
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <div>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <Form.Label>{t('directorAndPartner.shares')}</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={shares}
                                                onChange={handleSharesChange}
                                                autoComplete='off'
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>{t('directorAndPartner.votingRights')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={votingRights}
                                                onChange={handleVotingRightsChange}
                                                autoComplete='off'
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="secondary" onClick={handleClose}>
                            {t('popup.cancel')}
                        </Button>
                        <Button
                            className="btn-eloket"
                            variant="secondary"
                            onClick={handleSubmit}
                        >
                            {t('directorAndPartner.add')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default Add;
