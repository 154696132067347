import { GeneralAssemblyRegistrationType, GeneralAssemblyWillAttend } from "../Enums/GeneralAssemblyRegistrationType";

export class ActiveGeneralAssembly {
  kid: string;
  name: string;
  name_fr: string;
  physicalSeats: number;
  startDate: Date;
  endDate: Date;
  registrationClosingDate: Date;
  proxyVoteClosingDate: Date;
  placesLeft: number;
}

export class GeneralAssemblyRegistrationResponse {
  willParticipate: Boolean;
  registrationType?: GeneralAssemblyRegistrationType;
  willBePhysicallyPresent?: Boolean;
  confirmationDate: Number;
  proxyId: string;
}

export interface GetGeneralAssemblyProxyMembersQueryResult {
  naturalPersonId: string;
  fullName: string;
  city?: string;
  itaaNumber: string;
}
export class ProxyResult {
  ProxyNaturalPersonId: number;
  ProxyForNaturalPersonId?: number;
  OnlineRegistrationUsername?: string;
  OnlineRegistrationPassword?: string;
  DateProcuration?: string;
}

export class GeneralAssemblyParticipant {
  id: number;
  name: string;
  participantId: number;
  generalAssemblyId?: number;
  willAttend?: GeneralAssemblyWillAttend;
  registrationType?: GeneralAssemblyRegistrationType;
  willBePhysicallyPresent: boolean;
  participationConfirmedOn?: string;
  onlineRegistrationUsername?: string;
  onlineRegistrationPassword?: string;
  hasPassedProxyVote: boolean;
  proxy1?: ProxyResult;
  proxy2?: ProxyResult;
}

export interface ParticipantsCountQuery {
  generalAssemblyId: string;
  willAttend?: GeneralAssemblyWillAttend;
  willAttendPhysically?: boolean;
}

export class GeneralAssemblyRegistrationOptionMenuModel {
  naturalPersonId: string;
  willBePhysicallyPresent?: Boolean;
  ProxyNaturalPersonId: string;
  HasAgreedToTerms?: Boolean;
}

export class GeneralAssemblyQueryResult {
  kid: string;
  name: string;
  name_fr: string;
  physicalSeats: number;
  startDate?: Date;
  endDate?: Date;
  registrationClosingDate: Date;
  proxyVoteClosingDate: Date;
  placesLeft: number;
}
